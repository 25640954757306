import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { AppDispatch, RootState, Notification } from 'interfaces';

import { SIDE_PANEL_ID } from 'constant';

import ModalConfirm from 'components/ui/ConfirmModal';
import Notifications from 'components/ui/Notifications';
import DetailsPanel from 'components/DetailsPanel';
import Layout from 'components/Layout';
import PortfolioManagerComponent from 'components/PortfolioManager';

import { getPortfolios, deletePortfolio, resetRefresh } from './portfolioManagerSlice';

const PortfolioManager = (): JSX.Element => {
  const history = useHistory();

  const dispatch = useDispatch<AppDispatch>();

  const [selectedRow, setSelectedRow] = useState({});
  const [deleteId, setDeleteId] = useState<number | null>(null);
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);
  const [sidebarChangedCounter, setSidebarChangedCounter] = useState(0);

  const notificationMsg = useSelector((state: RootState) => state.portfolioManager.notification);
  const refreshList = useSelector((state: RootState) => state.portfolioManager.refreshList);

  useEffect(() => {
    // @ts-ignore
    dispatch(getPortfolios());
    dispatch(resetRefresh());
  }, [dispatch, refreshList]);

  const portfolios = useSelector((state: RootState) => state.portfolioManager.portfolios);

  const [notification, setNotification] = useState<Notification>(notificationMsg);

  return (
    <Layout
      sidePanelId={SIDE_PANEL_ID}
      withSidePanel
      sidePanelElement={() => (
        <DetailsPanel
          type="portfolio"
          data={{
            ...selectedRow
          }}
          withHideButton
          onButtonClick={() => {
            setSidebarChangedCounter(sidebarChangedCounter + 1);
          }}
        />
      )}
      sidebarChangedCounter={sidebarChangedCounter}
      withPageButtons={false}
    >
      <PortfolioManagerComponent
        portfolios={portfolios}
        onDelete={(id: number) => {
          setShowDeleteConfirmModal(true);
          setDeleteId(id);
        }}
        onEdit={(id: number) => {
          history?.push(`/edit-portfolio/${id}`);
        }}
        onView={(id: number) => {
          history?.push(`/portfolio-view/${id}`);
        }}
        setSelectedRow={setSelectedRow}
      />
      {
        showDeleteConfirmModal ? (
          <ModalConfirm
            title="Confirm delete"
            open={showDeleteConfirmModal}
            onClose={() => setShowDeleteConfirmModal(false)}
            onConfirm={() => {
              setShowDeleteConfirmModal(false);
              // @ts-ignore
              dispatch(deletePortfolio({ portfolioId: deleteId }));

              setDeleteId(null);
              setSelectedRow({});
            }}
            onCancel={() => setShowDeleteConfirmModal(false)}
          >
            <p>Are you sure, you want to delete this portfolio?</p>
            <p>You will not be able to recover it.</p>
          </ModalConfirm>
        ) : null
      }
      {
        notification.message && notification.open ? (
          <Notifications
            notification={notification}
            setNotification={setNotification}
          />
        ) : null
      }
    </Layout>
  );
};

export default PortfolioManager;
