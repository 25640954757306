import React, { useLayoutEffect } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useLocation
} from 'react-router-dom';

import { DASHBOARD_ANALYTICS_DATA_SET_TYPES } from 'constant';

import { useAccessToken } from 'auth/useAccessToken';

import ProtectedRoute from 'auth/ProtectedRoute';
import Register from 'auth/Register';
import Logout from 'auth/Logout';
import Login from 'auth/Login';

import DashboardMain from 'features/dashboardMain';
import DashboardAnalytics from 'features/dashboardAnalytics';
import CreatePortfolio from 'features/createPortfolio';
import PortfolioView from 'features/portfolioView';
import CreateScenario from 'features/createScenario';
import ScenarioView from 'features/scenarioView';
import ScenarioManager from 'features/scenarioManager';
import ScenarioExplorer from 'features/scenarioExplorer';
import PortfolioManager from 'features/portfolioManager';
import JobView from 'features/jobView';
import NewJobManager from 'features/jobManager';

const RouterWrapper = (props: any) => {
  const location = useLocation();

  useLayoutEffect(() => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }, [location.pathname]);

  return props.children;
};

const RoutesConfig = (): JSX.Element => {
  const accessToken = useAccessToken();

  return (
    <Router>
      <RouterWrapper>
        {
          accessToken ? (
            <Switch>
              <ProtectedRoute path="/" exact>
                <DashboardMain />
              </ProtectedRoute>
              <ProtectedRoute exact path="/dashboard-analytics-summary">
                <DashboardAnalytics dataSet={DASHBOARD_ANALYTICS_DATA_SET_TYPES.summary} />
              </ProtectedRoute>
              <ProtectedRoute exact path="/dashboard-analytics-assets-attribute">
                <DashboardAnalytics dataSet={DASHBOARD_ANALYTICS_DATA_SET_TYPES.assetAttribute} />
              </ProtectedRoute>
              <ProtectedRoute exact path="/dashboard-analytics-assets-stats">
                <DashboardAnalytics dataSet={DASHBOARD_ANALYTICS_DATA_SET_TYPES.assetStats} />
              </ProtectedRoute>
              <ProtectedRoute exact path="/create-scenario">
                <CreateScenario />
              </ProtectedRoute>
              <ProtectedRoute exact path="/edit-scenario/:scenarioId">
                <CreateScenario />
              </ProtectedRoute>
              <ProtectedRoute exact path="/scenario-explorer">
                <ScenarioExplorer />
              </ProtectedRoute>
              <ProtectedRoute exact path="/scenario-view/:scenarioId">
                <ScenarioView />
              </ProtectedRoute>
              <ProtectedRoute exact path="/scenario-manager">
                <ScenarioManager />
              </ProtectedRoute>
              <ProtectedRoute exact path="/portfolio-view/:portfolioId">
                <PortfolioView />
              </ProtectedRoute>
              <ProtectedRoute exact path="/create-portfolio">
                <CreatePortfolio />
              </ProtectedRoute>
              <ProtectedRoute exact path="/edit-portfolio/:portfolioId">
                <CreatePortfolio />
              </ProtectedRoute>
              <ProtectedRoute exact path="/portfolio-manager">
                <PortfolioManager />
              </ProtectedRoute>
              <ProtectedRoute exact path="/job-view/:calcId">
                <JobView />
              </ProtectedRoute>
              <ProtectedRoute exact path="/job-manager">
                <NewJobManager />
              </ProtectedRoute>
              <ProtectedRoute path="/logout">
                <Logout />
              </ProtectedRoute>
            </Switch>
          ) : (
            <Switch>
              <Route path="/" exact component={Login} />
              <Route path="/register" exact component={Register} />
            </Switch>
          )
        }
      </RouterWrapper>
    </Router>
  );
};

export default RoutesConfig;
