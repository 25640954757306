import React from 'react';
import { FormikProps } from 'formik';

import { IPortfolioDetailsForm, Asset } from 'interfaces';

import { FILTERS_NAMES } from 'constant';

import FormHeading from 'components/ui/FormHeading';
import DroppableInner from 'components/ui/Box';

import FormColumn from 'components/ui/FormColumn';
import FormColumns from 'components/ui/FormColumns';
import TextField from 'components/ui/TextField';
import Autocomplete from 'components/ui/Autocomplete';
import DatePicker from 'components/ui/DatePicker';

import { Option } from 'components/ui/Autocomplete/Autocomlete';

export interface PortfolioDetailsFormProps {
  currencyDictionary: any[];
  initialValues: IPortfolioDetailsForm;
  formikProps: FormikProps<any>;
}

const PortfolioDetailsForm = (props: PortfolioDetailsFormProps): JSX.Element => {
  const currencyOptions: Option[] = props.currencyDictionary.map((asset: Asset) => ({
    value: asset[FILTERS_NAMES.priceCurrency],
    label: asset[FILTERS_NAMES.priceCurrency]
  }));

  return (
    <React.Fragment>
      <FormHeading withBorder={false}>Portfolio Details</FormHeading>
      <DroppableInner>
        <FormColumns>
          <FormColumn even={false}>
            <TextField
              withExternalLabel
              required
              id="name"
              name="name"
              disabled={!!props.initialValues.id}
              onChange={(event) => {
                props.formikProps.setFieldValue('name', event.target.value);
              }}
              label="Portfolio name"
              value={props.formikProps.values && props.formikProps.values.name}
              error={
                Boolean(props.formikProps.touched.name)
                && Boolean(props.formikProps.errors.name)
              }
              helperText={
                props.formikProps.touched.name ? props.formikProps.errors.name : null
              }
              onBlur={props.formikProps.handleBlur}
            />
            <FormColumns>
              <FormColumn>
                <Autocomplete
                  required
                  withExternalLabel
                  withMarginBottom={false}
                  id="currency"
                  name="currency"
                  placeholder="Choose an option"
                  label="Currency"
                  options={currencyOptions}
                  handleChange={(selectedOption: Option | null) => {
                    props.formikProps.setFieldValue('currency', selectedOption?.value);
                  }}
                  labelInfo="Info about currency"
                  value={props.formikProps.values && props.formikProps.values.currency}
                  error={
                    Boolean(props.formikProps.touched.currency)
                    && Boolean(props.formikProps.errors.currency)
                  }
                  helperText={
                    props.formikProps.touched.currency ? props.formikProps.errors.currency : null
                  }
                  onBlur={props.formikProps.handleBlur}
                />
              </FormColumn>
              <FormColumn>
                <DatePicker
                  required
                  disabled={!!props.initialValues.id}
                  withExternalLabel
                  withMarginBottom={false}
                  id="referenceDate"
                  name="referenceDate"
                  label="Portfolio date"
                  labelInfo="Info about date"
                  placeholder="mm/yyyy"
                  onChange={(value: string) => {
                    props.formikProps.setFieldValue('referenceDate', value);
                  }}
                  setFieldTouched={props.formikProps.setFieldTouched}
                  value={props.formikProps.values && props.formikProps.values.referenceDate}
                  error={
                    Boolean(props.formikProps.touched.referenceDate)
                    && Boolean(props.formikProps.errors.referenceDate)
                  }
                  helperText={props.formikProps.touched.referenceDate ? props.formikProps.errors.referenceDate : null}
                  onBlur={props.formikProps.handleBlur}
                />
              </FormColumn>
            </FormColumns>
          </FormColumn>
          <FormColumn even={false}>
            <TextField
              multiline
              minRows={9}
              withExternalLabel
              withMarginBottom={false}
              required
              id="description"
              name="description"
              onChange={(event) => {
                props.formikProps.setFieldValue('description', event.target.value);
              }}
              label="Portfolio description"
              value={props.formikProps.values && props.formikProps.values.description}
              error={
                Boolean(props.formikProps.touched.description)
                && Boolean(props.formikProps.errors.description)
              }
              helperText={
                props.formikProps.touched.description ? props.formikProps.errors.description : null
              }
              onBlur={props.formikProps.handleBlur}
              maxLength={1024}
              showMaxLengthInfo
            />
          </FormColumn>
        </FormColumns>
      </DroppableInner>
    </React.Fragment>
  );
};

export default PortfolioDetailsForm;
