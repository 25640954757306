import styled from '@emotion/styled';

const Wrapper = styled.div`
  transition: ${(props) => props.theme.layout.transition};

  .js-plotly-plot .plotly .main-svg {
    margin-bottom: 20px;
    padding: 25px 25px 25px 15px;
  }
  .hoverlayer {
    padding: 10px !important;
  }
`;

export {
  Wrapper
};
