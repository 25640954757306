import { DateTime } from 'luxon';

import {
  DAY_MONTH_YEAR_DATE_FORMAT,
  MONTH_ABBREV_YEAR_DATE_FORMAT,
  MONTH_YEAR_DATE_FORMAT
} from 'constant';

export const formatDate = (dateString: string | null | undefined, format = DAY_MONTH_YEAR_DATE_FORMAT): string | null | undefined => {
  if (!dateString) {
    return dateString;
  }

  const date = new Date(dateString as string);
  let month = `${date.getMonth() + 1}`;
  let day = `${date.getDate()}`;
  const year = date.getFullYear();

  if (month.length < 2) {
    month = `0${month}`;
  }
  if (day.length < 2) {
    day = `0${day}`;
  }

  switch (format) {
    case DAY_MONTH_YEAR_DATE_FORMAT: {
      return [day, month, year].join('/');
    }
    case MONTH_YEAR_DATE_FORMAT: {
      return [month, year].join('/');
    }
    case MONTH_ABBREV_YEAR_DATE_FORMAT: {
      return DateTime.fromISO(dateString).toFormat(format);
    }
    default: {
      return [day, month, year].join('/');
    }
  }
};
