import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { fetchAssets } from 'api';

import { Assets } from 'interfaces';

import { fetchAssetsCurves } from 'api/endpoints/assets';

const initialState: Assets = {
  assetsPayloads: [],
  assetsCurves: [],
  loading: false
};

export const getAssets = createAsyncThunk(
  'assets/getAssets',
  async () => fetchAssets()
);

export const getAssetsCurves = createAsyncThunk(
  'assets/getAssetsCurves',
  async () => fetchAssetsCurves()
);

const assets = createSlice({
  name: 'assets',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAssets.pending, (state) => ({
        ...state,
        loading: true
      }))
      .addCase(getAssets.fulfilled, (state, action) => ({
        ...state,
        assetsPayloads: action.payload,
        loading: false
      }))
      .addCase(getAssetsCurves.pending, (state) => ({
        ...state,
        loading: true
      }))
      .addCase(getAssetsCurves.fulfilled, (state, action) => ({
        ...state,
        assetsCurves: action.payload,
        loading: false
      }));
  }
});

export default assets.reducer;
