import { FORECAST_SELECT_TYPES_IDS } from 'constant';

export const getLabel = (staticForecastType: any) => {
  switch (staticForecastType) {
    case FORECAST_SELECT_TYPES_IDS.meanReturn: {
      return 'Mean Return Inputs';
    }
    case FORECAST_SELECT_TYPES_IDS.meanReturnRange: {
      return 'Mean Return Range Inputs';
    }
    case FORECAST_SELECT_TYPES_IDS.volatility: {
      return 'Volatility Inputs';
    }
    case FORECAST_SELECT_TYPES_IDS.volatilityRange: {
      return 'Volatility Range Inputs';
    }
    default: return '';
  }
};
