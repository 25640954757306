import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Link } from 'react-router-dom';

import { MAIN_HEADER_HEIGHT, MAIN_NAV_WIDE_WIDTH, MAIN_NAV_WIDTH } from 'constant';

import Container from 'components/ui/Container';
import Box from 'components/ui/Box';
import Icons from 'components/ui/Icons';

interface BoxProps {
  menuOpen?: boolean | undefined;
}

const StyledBox = styled(Box)<BoxProps>`
  position: fixed;
  right: 0;
  left: calc(${MAIN_NAV_WIDTH}px - 1px);
  z-index: 5;
  height: ${MAIN_HEADER_HEIGHT}px;
  padding: 0;
  border-width: 0 0 1px 1px;
  transition: ${(props) => props.theme.layout.transition};

  ${(props) => props.menuOpen && css`
    left: calc(${MAIN_NAV_WIDE_WIDTH}px - 1px);
  `};
`;

const StyledContainer = styled(Container)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
`;

const Title = styled.h1`
  font-size: 24px;
  color: ${(props) => props.theme.color.secondary};
  text-transform: lowercase;

  &::first-letter {
    text-transform: uppercase;
  }
`;

const UserWrapper = styled.div`
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.color.secondary};
`;

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 16px;
`;

// @ts-ignore
const StyledIcons = styled(Icons)`
  order: 2;
  margin-bottom: -5px;
`;

export {
  StyledBox,
  StyledContainer,
  Title,
  UserWrapper,
  StyledLink,
  StyledIcons
};
