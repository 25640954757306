import { percentRenderer } from 'utils/tableFormatters';

export const assetAttributeColumnDefs = [
  {
    field: 'assetWeight',
    headerName: 'Asset weight',
    flex: 1,
    minWidth: 160,
    maxWidth: 200,
    cellRenderer: percentRenderer
  },
  {
    field: 'meanAnnualized',
    headerName: 'Mean annualized',
    flex: 1,
    minWidth: 160,
    maxWidth: 200,
    cellRenderer: percentRenderer
  },
  {
    field: 'volAnnualized',
    headerName: 'Volatility annualized',
    flex: 1,
    minWidth: 200,
    maxWidth: 220,
    cellRenderer: percentRenderer
  },
  {
    field: 'vaR95',
    headerName: 'VAR95%',
    flex: 1,
    minWidth: 1230,
    maxWidth: 200,
    cellRenderer: percentRenderer
  },
  {
    field: 'vaR99',
    headerName: 'VAR99%',
    flex: 1,
    minWidth: 130,
    maxWidth: 200,
    cellRenderer: percentRenderer
  },
  {
    field: 'cVaR95',
    headerName: 'CVAR95%',
    flex: 1,
    minWidth: 130,
    maxWidth: 200,
    cellRenderer: percentRenderer
  },
  {
    field: 'cVaR99',
    headerName: 'CVAR99%',
    flex: 1,
    minWidth: 130,
    maxWidth: 200,
    cellRenderer: percentRenderer
  }
];
