import React from 'react';

import {
  Wrapper,
  StyledInfoIconBox
} from './ExternalLabel.styles';

export interface ExternalLabelProps {
  children: React.ReactNode;
  variant?: 'primary' | 'secondary' | undefined;
  className?: string;
  noFullWidth?: boolean | undefined;
  htmlFor?: string;
  uppercase?: boolean;
  labelInfo?: string;
}
const defaultProps = {
  variant: 'primary',
  className: '',
  noFullWidth: false,
  htmlFor: null,
  uppercase: false,
  labelInfo: null
};

const ExternalLabel = (props: ExternalLabelProps): JSX.Element => (
  <Wrapper
    variant={props.variant}
    className={props.className}
    noFullWidth={props.noFullWidth}
    htmlFor={props.htmlFor}
    uppercase={props.uppercase}
  >
    {props.children}
    {
      props.labelInfo ? (
        <StyledInfoIconBox title={props.labelInfo} size={16} />
      ) : null
    }
  </Wrapper>
);

ExternalLabel.defaultProps = defaultProps;

export default ExternalLabel;
