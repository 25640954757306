import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { navigation, INavigation, MenuItemOpen } from 'config/navigation';

import { MAIN_NAVIGATION_ID, MAIN_NAVIGATION_OPEN_KEY, NAVIGATION_ITEM_OPEN_KEY } from 'constant';

import logoSimple from 'assets/logo/logo-simple.svg';
import fullLogo from 'assets/logo/full-logo.svg';

import Scrollbar from 'components/ui/Scrollbar';

import {
  StyledBox,
  LogoWrapper,
  Logo,
  Nav,
  NavList,
  NavItem,
  NavLink,
  MenuIcon,
  SubMenu,
  SubMenuItem,
  NavSubLink,
  MenuHeader,
  MenuFooter,
  ArrowButton,
  StyledIcons
} from './Navigation.styles';

export interface NavigationProps {
  menuOpen: boolean;
  setMenuOpen: (newValue: boolean) => void;
}

const Navigation = (props: NavigationProps): JSX.Element => {
  const menuItemOpenInit = JSON.parse(sessionStorage.getItem(NAVIGATION_ITEM_OPEN_KEY) as string) || {
    dashboard: false,
    scenario: false,
    portfolio: false,
    rules: false,
    settings: false
  };

  const [menuItemOpen, setMenuItemOpen] = useState<MenuItemOpen>(menuItemOpenInit);

  const currentRoutingLink = window.location.pathname.split('/');

  const checkIfActive = (menuItem: INavigation, currentRoutingLink: string[]) => {
    const subMenuIsActive = menuItem.subNavigation
      .filter((el: any) => el.link.replace('/', '') === currentRoutingLink[1]);

    return subMenuIsActive.length > 0;
  };

  const arrows = (menuItem: INavigation) => menuItemOpen[menuItem.id] ? (
    <StyledIcons iconType="chevron-up" size={7} />
  ) : (
    <StyledIcons iconType="chevron-down" size={7} />
  );

  const subMenu = (
    menuItem: INavigation,
    isActive: boolean,
    isSubMenuClosed?: boolean
  ) => menuItem.subNavigation.length && !isSubMenuClosed ? (
    <SubMenu menuOpen={props.menuOpen}>
      {
        !props.menuOpen ? (
          <SubMenuItem key={`${menuItem.id}-sub-header`} isActive={isActive}>
            <MenuHeader>
              {menuItem.name}
            </MenuHeader>
          </SubMenuItem>
        ) : null
      }
      {
        menuItem.subNavigation.map((subMenuItem) => {
          const isActive = subMenuItem.link.replace('/', '') === currentRoutingLink[1];

          return !subMenuItem.hidden ? (
            <SubMenuItem key={subMenuItem.id} isActive={isActive}>
              <NavSubLink to={subMenuItem.link}>
                {subMenuItem.name}
              </NavSubLink>
            </SubMenuItem>
          ) : null;
        })
      }
    </SubMenu>
  ) : null;

  return (
    <StyledBox id={MAIN_NAVIGATION_ID} menuOpen={props.menuOpen}>
      <LogoWrapper menuOpen={props.menuOpen}>
        <Link to="/">
          <Logo src={props.menuOpen ? fullLogo : logoSimple} alt="Sapiat" />
        </Link>
      </LogoWrapper>
      <Nav menuOpen={props.menuOpen}>
        <Scrollbar noPadding noScrollBar={!props.menuOpen}>
          <NavList>
            {
              navigation.map((menuItem) => {
                const isActive = checkIfActive(menuItem, currentRoutingLink);

                return (
                  <NavItem
                    key={menuItem.id}
                    isActive={isActive}
                    menuOpen={props.menuOpen}
                    isDisabled={!!menuItem.disabled}
                  >
                    <NavLink
                      isDisabled={!!menuItem.disabled}
                      onClick={(event) => {
                        event.stopPropagation();

                        const changedMenuItemOpen = {
                          ...menuItemOpen,
                          [menuItem.id]: menuItemOpen[menuItem.id] ? !menuItemOpen[menuItem.id] : true
                        };
                        setMenuItemOpen(changedMenuItemOpen);
                        sessionStorage.setItem(NAVIGATION_ITEM_OPEN_KEY, JSON.stringify(changedMenuItemOpen));
                      }}
                    >
                      <MenuIcon src={menuItem.icon} alt={menuItem.name} />
                      {
                        props.menuOpen ? (
                          <MenuHeader menuOpen={props.menuOpen}>
                            {menuItem.name}
                            {
                              menuItem.subNavigation.length ? arrows(menuItem) : null
                            }
                          </MenuHeader>
                        ) : null
                      }
                    </NavLink>
                    {
                      props.menuOpen
                        ? subMenu(menuItem, isActive, !menuItemOpen[menuItem.id])
                        : subMenu(menuItem, isActive)
                    }
                  </NavItem>
                );
              })
            }
          </NavList>
        </Scrollbar>
      </Nav>
      <MenuFooter menuOpen={props.menuOpen}>
        {
          props.menuOpen ? (
            <ArrowButton
              menuOpen
              onClick={() => {
                props.setMenuOpen(false);
                sessionStorage.setItem(MAIN_NAVIGATION_OPEN_KEY, JSON.stringify(false));

                setTimeout(() => {
                  window.dispatchEvent(new Event('resize'));
                }, 600);
              }}
            />
          ) : (
            <ArrowButton
              onClick={() => {
                props.setMenuOpen(true);
                sessionStorage.setItem(MAIN_NAVIGATION_OPEN_KEY, JSON.stringify(true));

                setTimeout(() => {
                  window.dispatchEvent(new Event('resize'));
                }, 600);
              }}
            />
          )
        }
      </MenuFooter>
    </StyledBox>
  );
};

export default Navigation;
