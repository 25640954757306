import styled from '@emotion/styled';

import Icons from 'components/ui/Icons';

const Wrapper = styled.div`
  margin-top: 25px;
`;

const DeleteButton = styled.button`
  margin-left: 5px;
  transition: all .2s;
`;

// @ts-ignore
const StyledIcons = styled(Icons)`
  display: inline-block;
  color: #dfdfdf;
  cursor: pointer;

  &:hover {
    color: #393939;
  }
`;

const TableEditOverlay = styled.span`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5;
  width: 100%;
  height: 100%;
`;

export {
  Wrapper,
  DeleteButton,
  StyledIcons,
  TableEditOverlay
};
