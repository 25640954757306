import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { AuthNotificationProps } from 'auth/AuthNotification';

const Wrapper = styled.div<AuthNotificationProps>`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
  padding: 10px 20px;

  ${(props) => props.severity === 'success' && css`
    background: #CEFDD9;
    color: ${props.theme.color.secondary};
  `};

  ${(props) => props.severity === 'error' && css`
    background: #f4c1c3;
    color: #DA1E28;
  `};
`;

export {
  Wrapper
};
