import React from 'react';

import Chart from 'components/Chart';

import {
  StyledBox,
  StyledHeading
} from './DefaultChart.styles';

export interface DefaultChartProps {
  chartData: any;
  className?: string;
}
const defaultProps = {
  className: ''
};

const DefaultChart = (props: DefaultChartProps): JSX.Element => (
  <StyledBox className={props.className}>
    <StyledHeading>SSA Committee Default</StyledHeading>
    <Chart chartData={props.chartData} normalHeight />
  </StyledBox>
);

DefaultChart.defaultProps = defaultProps;

export default React.memo(DefaultChart);
