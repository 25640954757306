import { AssetCurve, Asset, Components } from 'interfaces';

import { COMPONENTS_MONTH_PREFIX } from 'constant';

import { getAssetValue } from 'utils/scenario/getAssetValue';

export const getComponentsFromDataTables = (
  assetsPayloads: Asset[],
  assetsCurves: AssetCurve[],
  dataTable: Components[]
): Components[] => {
  const components: Components[] = [];
  dataTable.forEach((el: any) => {
    const asset = assetsPayloads.filter(
      (asset: Asset) => asset.name === el.assetName
    )[0];
    Object.entries(el).forEach(([key, value]) => {
      if (key[0] === COMPONENTS_MONTH_PREFIX) {
        const labelId = Number(key.substring(1));

        const componentData = {
          componentType: el.componentType,
          forecastDateType: 0,
          metricType: el.metricType,
          portfolioSerialID: 0,
          shiftType: 0,
          singleIndexID: asset.id,
          targetDate: labelId,
          value: Number(value) / 100
        };

        if (value && (
          Number(value) !== getAssetValue(assetsCurves, el.metricType, labelId, asset.id)
          || (Number(value) !== getAssetValue(assetsCurves, el.metricType, labelId, asset.id) && value === '0')
        )) {
          components.push(componentData);
        }
      }
    });
  });

  return components;
};
