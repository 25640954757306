import React from 'react';
import { Link, LinkProps } from 'react-router-dom';

import { IconButtonProps, PropTypes } from '@material-ui/core';

import { BUTTON_ALLOW_COMPONENTS_TYPES } from 'constant';

import {
  StyledIconButton
} from './IconButton.styles';

export type Size = 'small' | 'medium' | 'large' | undefined;
export type Variant = 'contained' | 'outlined' | 'text' | undefined;
export type Component = BUTTON_ALLOW_COMPONENTS_TYPES.button
| BUTTON_ALLOW_COMPONENTS_TYPES.link
| BUTTON_ALLOW_COMPONENTS_TYPES.anchor
| BUTTON_ALLOW_COMPONENTS_TYPES.other;

export interface IIconButtonProps extends Omit<IconButtonProps, 'size'> {
  color?: PropTypes.Color;
  component?: Component;
  componentFunction?: any;
  variant?: Variant;
  size?: Size;
  ref?: any;
}
const defaultProps = {
  component: BUTTON_ALLOW_COMPONENTS_TYPES.button as Component,
  componentFunction: null,
  size: 'large' as Size,
  variant: 'contained' as Variant,
  color: 'primary' as PropTypes.Color,
  ref: null
};

const IconButton = React.forwardRef((props: IIconButtonProps, ref): JSX.Element => {
  const { ref: propsRef, ...rest } = props;

  const getComponent = (componentFunction: any): any => {
    switch (props.component) {
      case BUTTON_ALLOW_COMPONENTS_TYPES.anchor: {
      // component name use by material-ui
        return BUTTON_ALLOW_COMPONENTS_TYPES.materialAnchor;
      }
      case BUTTON_ALLOW_COMPONENTS_TYPES.link: {
        return React.forwardRef((linkProps: LinkProps, ref: React.ForwardedRef<HTMLAnchorElement>) => (
          <Link ref={ref} {...linkProps} />
        ));
      }
      case BUTTON_ALLOW_COMPONENTS_TYPES.other: {
        return React.forwardRef((otherProps, ref: React.ForwardedRef<any>) => (
          componentFunction({ ref, ...otherProps })
        ));
      }

      default:
        // component name use by material-ui
        return BUTTON_ALLOW_COMPONENTS_TYPES.button;
    }
  };

  return (
    <StyledIconButton
      {...rest}
      component={getComponent(props.componentFunction)}
      ref={ref}
    />
  );
});

IconButton.defaultProps = defaultProps;

export default IconButton;
