import React, {
  useState,
  useEffect,
  useCallback,
  useContext
} from 'react';

import Swiper from 'swiper';

import { Job as IJob } from 'interfaces';
import { MAIN_CONTAINER_ID } from 'constant';

import { JobManagerDataContext } from 'context/jobManagerDataContext';

import CreateJobTeaser from 'components/CreateJobTeaser';

import Job from 'features/job';

import {
  Wrapper,
  SliderWrapper,
  SliderViewport,
  PrevButton,
  NextButton,
  StyledSwiper,
  StyledSwiperSlide
} from './JobManagerJobsSlider.styles';

export interface JobManagerJobsSliderProps {
  jobs: IJob[];
  onCreateNewCard?: () => void;
  deleteCard?: (id: number) => void;
}

const SWIPER_SPACE_BETWEEN = 8;
const EMPTY_JOB_CARD_INDEX = 1;

const JobManagerJobsSlider = (props: JobManagerJobsSliderProps): JSX.Element => {
  const {
    setEditedCardPortfolios,
    setEditedCardScenarios,
    setPortfolios,
    defaultPortfolios,
    setScenarios,
    defaultScenarios,
    setEditedCardJobName
  } = useContext(JobManagerDataContext);

  const [selectedJob, setSelectedJob] = useState<number>(1);

  const layoutElement = document.querySelector(`#${MAIN_CONTAINER_ID}`);
  const [layoutWidth, setLayoutWidth] = useState<number>(0);

  const [swiperRef, setSwiperRef] = useState<Swiper>();
  const [swiperTransformValue, setSwiperTransformValue] = useState<number>(0);

  const [prevBtnDisabled] = useState(false);
  const [nextBtnDisabled] = useState(false);

  const resetEditedCard = () => {
    setEditedCardPortfolios([]);
    setEditedCardScenarios([]);
    setPortfolios(defaultPortfolios);
    setScenarios(defaultScenarios);
    setEditedCardJobName('');
  };

  const scrollPrev = () => {
    if (!swiperRef) return;

    if (selectedJob > 1) {
      setSelectedJob(selectedJob - 1);

      const oneElement = (layoutWidth - (layoutWidth / 2)) / 2;
      setSwiperTransformValue(swiperTransformValue + oneElement);
    }

    swiperRef?.slidePrev();

    resetEditedCard();
  };

  const scrollNext = () => {
    if (!swiperRef) return;

    if (selectedJob <= props.jobs.length) {
      setSelectedJob(selectedJob + 1);

      const oneElement = (layoutWidth - (layoutWidth / 2)) / 2;
      setSwiperTransformValue(swiperTransformValue - oneElement);
    }

    swiperRef?.slideNext();

    resetEditedCard();
  };

  const getLayoutWidth = useCallback(() => {
    setLayoutWidth(0);

    const wrapperComputedStyle = layoutElement ? window.getComputedStyle(layoutElement, null) : null;

    const width = layoutElement ? layoutElement?.clientWidth : 0;

    const padding = parseFloat(wrapperComputedStyle?.paddingLeft as string) + parseFloat(wrapperComputedStyle?.paddingRight as string);

    setLayoutWidth(width - padding);
  }, [layoutElement]);

  useEffect(() => {
    getLayoutWidth();
  }, [getLayoutWidth]);

  const useHandleResize = useCallback(() => {
    getLayoutWidth();
  }, [getLayoutWidth]);

  useEffect(() => {
    getLayoutWidth();
    window.addEventListener('resize', useHandleResize);

    return () => window.removeEventListener('resize', useHandleResize);
  }, [getLayoutWidth, useHandleResize, swiperRef?.activeIndex]);

  return (
    <Wrapper>
      <SliderWrapper layoutWidth={layoutWidth}>
        <SliderViewport>
          <StyledSwiper
            spaceBetween={SWIPER_SPACE_BETWEEN}
            slidesPerView="auto"
            onSwiper={setSwiperRef}
            transformValue={swiperTransformValue}
          >
            <StyledSwiperSlide
              layoutWidth={layoutWidth}
            >
              <CreateJobTeaser onCreateNewCard={props.onCreateNewCard} />
            </StyledSwiperSlide>
            <StyledSwiperSlide
              editMode={selectedJob === EMPTY_JOB_CARD_INDEX}
              layoutWidth={layoutWidth}
            >
              <Job
                calcId={0}
                editMode={selectedJob === EMPTY_JOB_CARD_INDEX}
                newCard
              />
            </StyledSwiperSlide>
            {
              props.jobs.map((job: IJob, index: number) => (
                <StyledSwiperSlide
                  key={job.id}
                  editMode={selectedJob === index + 2}
                  layoutWidth={layoutWidth}
                >
                  <Job
                    calcId={job.id}
                    editMode={selectedJob === index + 2}
                    deleteCard={props.deleteCard}
                  />
                </StyledSwiperSlide>
              ))
            }
          </StyledSwiper>
        </SliderViewport>
      </SliderWrapper>
      {
        !prevBtnDisabled ? (
          <PrevButton
            navDirection="left"
            onClick={scrollPrev}
            disabled={prevBtnDisabled}
          />
        ) : null
      }
      {
        !nextBtnDisabled ? (
          <NextButton
            navDirection="right"
            onClick={scrollNext}
            disabled={nextBtnDisabled}
          />
        ) : null
      }
    </Wrapper>
  );
};

export default JobManagerJobsSlider;
