import styled from '@emotion/styled';
import { css } from '@emotion/react';

import DroppableInner from 'components/ui/Box';

interface StyledBpxProps {
  noPadding: boolean | undefined;
}

const StyledBox = styled(DroppableInner)<StyledBpxProps>`
  padding: 22px;

  ${(props) => props.noPadding && css`
    padding: 0;
  `};
`;

export {
  StyledBox
};
