import { AssetCurve } from 'interfaces';

import { COMPONENTS_TYPE_IDS, RANGE_TYPE_LABELS } from 'constant';

import { createMonthData, getMetricsLabel } from 'utils/scenario';

const MAX_MIN_PAIR_MAX_CAPACITY = 2;
const MAX_MIN_PAIR_ONE_ELEMENT_INDEX = 0;

export const reorganizeComponents = (components: any[]): any[] => components.reduce((acc, current) => {
  const array = [...acc];
  let arrayIndex = -1;

  array.forEach((el, index) => {
    const insideIndex = el.findIndex((x: any) => x.assetId === current.assetId);
    if (insideIndex !== -1) {
      arrayIndex = index;
    }
  });

  if (arrayIndex !== -1) {
    array[arrayIndex].push(current);
  } else {
    array.push([current]);
  }

  return array;
}, []);

export const getUniqueComponentsWithMinMaxPair = (components: any[], assetsCurves: AssetCurve[]): any[] => {
  const splitComponents = reorganizeComponents(components);
  const newComponents: any[] = [];

  splitComponents.forEach((el: any, index) => {
    if (el.length < MAX_MIN_PAIR_MAX_CAPACITY) {
      if (el.filter((item: any) => item.componentType === COMPONENTS_TYPE_IDS.point).length) {
        newComponents.push(...el);
      }
      if (el.filter((item: any) => item.componentType === COMPONENTS_TYPE_IDS.max).length) {
        newComponents.push(...el);
        newComponents.push({
          id: newComponents.length + 1,
          minMaxLabel: RANGE_TYPE_LABELS.min,
          componentType: COMPONENTS_TYPE_IDS.min,
          metricType: el[MAX_MIN_PAIR_ONE_ELEMENT_INDEX].metricType,
          metricTypeLabel: getMetricsLabel(el[MAX_MIN_PAIR_ONE_ELEMENT_INDEX].metricType),
          assetId: el[MAX_MIN_PAIR_ONE_ELEMENT_INDEX].assetId,
          assetName: el[MAX_MIN_PAIR_ONE_ELEMENT_INDEX].assetName,
          ...createMonthData(
            assetsCurves,
            null,
            null,
            el[MAX_MIN_PAIR_ONE_ELEMENT_INDEX].assetId,
            el[MAX_MIN_PAIR_ONE_ELEMENT_INDEX].metricType,
            true
          )
        });
      }
      if (el.filter((item: any) => item.componentType === COMPONENTS_TYPE_IDS.min).length) {
        newComponents.push({
          id: newComponents.length + 1,
          minMaxLabel: RANGE_TYPE_LABELS.max,
          componentType: COMPONENTS_TYPE_IDS.max,
          metricType: el[MAX_MIN_PAIR_ONE_ELEMENT_INDEX].metricType,
          metricTypeLabel: getMetricsLabel(el[MAX_MIN_PAIR_ONE_ELEMENT_INDEX].metricType),
          assetId: el[MAX_MIN_PAIR_ONE_ELEMENT_INDEX].assetId,
          assetName: el[MAX_MIN_PAIR_ONE_ELEMENT_INDEX].assetName,
          ...createMonthData(
            assetsCurves,
            null,
            null,
            el[MAX_MIN_PAIR_ONE_ELEMENT_INDEX].assetId,
            el[MAX_MIN_PAIR_ONE_ELEMENT_INDEX].metricType,
            true
          )
        });
        newComponents.push(...el);
      }
    } else {
      newComponents.push(...el);
    }
  });

  return newComponents;
};
