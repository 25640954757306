import styled from '@emotion/styled/macro';

import { TableCell, TableRow } from '@mui/material';

import Icons from 'components/ui/Icons';

const StyledTableCell = styled(TableCell)`
  padding: 13px 10px;
  border-bottom: 0;

  &:nth-of-type(1) {
    width: 445px;
    max-width: 445px;
  }
  &:nth-of-type(5) {
    width: 280px;
    max-width: 280px;
  }
  &:nth-last-of-type(2) {
    width: 120px;
    padding-right: 0;
  }
  &:first-of-type {
    padding-left: 0;
  }
  // Delete button
  &:last-of-type {
    position: absolute;
    top: 50%;
    right: -30px;
    transform: translateY(-50%);
    width: 25px;
    padding: 0;
    opacity: 0;
    transition: all .1s ease-in-out;
  }
`;

const StyledTableRow = styled(TableRow)`
  position: relative;

  &:last-of-type {
    ${StyledTableCell} {
      padding-bottom: 0;
    }
  }
  &:hover {
    ${StyledTableCell}:last-of-type {
      opacity: 1;
    }
  }
`;

// @ts-ignore
const StyledIcons = styled(Icons)`
  display: inline-block;
  color: #9c9c9c;

  &:hover {
    color: #393939;
  }
`;

export {
  StyledTableCell,
  StyledTableRow,
  StyledIcons
};
