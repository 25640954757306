import styled from '@emotion/styled';
import { css } from '@emotion/react';

import Box from 'components/ui/Box';
import ExternalLabel from 'components/ui/ExternalLabel';
import SliderNavButton from 'components/ui/SliderNavButton';

import { HIDDEN_SIDEBAR_MARGIN, MAIN_HEADER_HEIGHT } from 'constant';

interface BoxProps {
  id: string | undefined;
  hidePanel: boolean | undefined;
}

interface ClampProps {
  clamp?: number | undefined;
}

const StyledBox = styled(Box)<BoxProps>`
  position: fixed;
  top: ${MAIN_HEADER_HEIGHT}px;
  right: 0;
  bottom: 0;
  z-index: 3;
  width: 335px;
  padding-top: 30px;
  padding-right: 10px;
  border-width: 0 0 0 1px;
  transition: ${(props) => props.theme.layout.transition};

  @media (min-width: 1600px) {
    width: 435px;

    ${(props) => props.hidePanel && css`
      right: calc(-435px + ${HIDDEN_SIDEBAR_MARGIN}px);
    `};
  }

  ${(props) => props.hidePanel && css`
    right: calc(-335px + ${HIDDEN_SIDEBAR_MARGIN}px);
  `};
`;

const DataWrapper = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`;

const DataItem = styled.li`
  & + & {
    margin-top: 20px;
  }
`;

const Text = styled.span<ClampProps>`
  display: -webkit-box;
  overflow: hidden;
  max-width: 100%;
  -webkit-line-clamp: ${(props) => props.clamp ? props.clamp : 2};
  -webkit-box-orient: vertical;
  font-size: 20px;
  font-weight: 300;

  @media (min-width: 1300px) and (min-height: 900px) {
    font-size: 26px;
  }
`;

// @ts-ignore
const StyledExternalLabel = styled(ExternalLabel)`
  font-size: 16px;
  font-weight: 300;
  color: #9c9c9c;
`;

const StyledSliderNavButton = styled(SliderNavButton)`
  position: absolute;
  top: 50%;
  left: -16px;
  transform: translateY(-50%);
  padding-top: 2.5px;
`;

export {
  StyledBox,
  DataWrapper,
  DataItem,
  Text,
  StyledExternalLabel,
  StyledSliderNavButton
};
