import React, { useState, useEffect } from 'react';
import { FormikProps } from 'formik';
import _cloneDeep from 'lodash/cloneDeep';

import {
  AssetForForm,
  AssetsFilters,
  AssetsFiltersWithSelectedOption
} from 'interfaces';

import { FILTERS_NAMES } from 'constant';

import { getDefaultAssetsDictionariesFilters, updateFiltersBySelectedOptions } from 'utils/assetsUtils';

import TextField from 'components/ui/TextField';
import Autocomplete, { Option } from 'components/ui/Autocomplete/Autocomlete';

import {
  StyledTableCell,
  StyledTableRow,
  StyledIcons
} from './PortfolioAssetsFormTableRow.styles';

export interface PortfolioAssetsFormTableRowProps {
  index: number;
  assetsDictionaries: AssetsFilters;
  formikProps: FormikProps<any>;
  emptyRow: any
}

const PortfolioAssetsFormTableRow = (props: PortfolioAssetsFormTableRowProps): JSX.Element => {
  const [assetsDictionariesFilters, setAssetsDictionariesFilters] = useState<AssetsFiltersWithSelectedOption>(
    getDefaultAssetsDictionariesFilters(props.assetsDictionaries)
  );

  useEffect(() => {
    setAssetsDictionariesFilters(
      getDefaultAssetsDictionariesFilters(props.assetsDictionaries)
    );
  }, [props.assetsDictionaries]);

  const deleteRow = (formikProps: FormikProps<{ holdings: AssetForForm[] }>, index: number) => {
    const newHoldings = _cloneDeep(formikProps.values.holdings);

    newHoldings.splice(index, 1);

    if (formikProps.values.holdings.length === 1) {
      newHoldings.push(props.emptyRow);
    }

    formikProps.setFieldValue('holdings', newHoldings, false);
  };

  const showErrorMessage = (formikProps: any, index: number, field: string) => {
    const fieldIsTouched = formikProps.touched.holdings
      && formikProps.touched.holdings[index]
      && formikProps.touched.holdings[index][field];
    const fieldHasErrors = formikProps.errors.holdings
      && formikProps.errors.holdings[index]
      && formikProps.errors.holdings[index][field];

    return Boolean(fieldIsTouched) && Boolean(fieldHasErrors);
  };
  const getErrorMessage = (formikProps: any, index: number, field: string) => showErrorMessage(formikProps, index, field)
    ? formikProps.errors.holdings[index][field]
    : null;

  return (
    <StyledTableRow key={props.index}>
      <StyledTableCell scope="row">
        <TextField
          required
          size="medium"
          withMarginBottom={false}
          id={`holdings[${props.index}].description`}
          name={`holdings[${props.index}].description`}
          onChange={(event) => {
            props.formikProps.setFieldValue(`holdings[${props.index}].description`, event.target.value);
          }}
          label="Write your asset name here"
          value={props.formikProps.values && props.formikProps.values.holdings[props.index]?.description}
          error={showErrorMessage(props.formikProps, props.index, 'description')}
          helperText={getErrorMessage(props.formikProps, props.index, 'description')}
          onBlur={props.formikProps.handleBlur}
        />
      </StyledTableCell>
      <StyledTableCell>
        <Autocomplete
          required
          size="medium"
          withMarginBottom={false}
          id={`holdings[${props.index}].priceCurrency`}
          name={`holdings[${props.index}].priceCurrency`}
          handleChange={(selectedOption: Option | null) => {
            updateFiltersBySelectedOptions(
              selectedOption,
              'priceCurrency',
              assetsDictionariesFilters,
              setAssetsDictionariesFilters,
              props.assetsDictionaries
            );
            props.formikProps.setFieldValue(`holdings[${props.index}].priceCurrency`, selectedOption?.value);
          }}
          label="Currency"
          placeholder="Currency"
          options={
            assetsDictionariesFilters.priceCurrency.assets.map((asset: any) => ({
              value: asset[FILTERS_NAMES.priceCurrency],
              label: asset[FILTERS_NAMES.priceCurrency]
            }))
          }
          value={props.formikProps.values && props.formikProps.values.holdings[props.index]?.priceCurrency}
          error={showErrorMessage(props.formikProps, props.index, 'priceCurrency')}
          helperText={getErrorMessage(props.formikProps, props.index, 'priceCurrency')}
          onBlur={props.formikProps.handleBlur}
        />
      </StyledTableCell>
      <StyledTableCell>
        <Autocomplete
          required
          size="medium"
          withMarginBottom={false}
          id={`holdings[${props.index}].region`}
          name={`holdings[${props.index}].region`}
          handleChange={(selectedOption: Option | null) => {
            updateFiltersBySelectedOptions(
              selectedOption,
              'region',
              assetsDictionariesFilters,
              setAssetsDictionariesFilters,
              props.assetsDictionaries
            );
            props.formikProps.setFieldValue(`holdings[${props.index}].region`, selectedOption?.value);
          }}
          label="Region"
          placeholder="Choose an option"
          options={
            assetsDictionariesFilters.region.assets.map((asset: any) => ({
              value: asset[FILTERS_NAMES.region],
              label: asset[FILTERS_NAMES.region]
            }))
          }
          value={props.formikProps.values && props.formikProps.values.holdings[props.index]?.region}
          error={showErrorMessage(props.formikProps, props.index, 'region')}
          helperText={getErrorMessage(props.formikProps, props.index, 'region')}
          onBlur={props.formikProps.handleBlur}
        />
      </StyledTableCell>
      <StyledTableCell>
        <Autocomplete
          required
          size="medium"
          withMarginBottom={false}
          id={`holdings[${props.index}].assetClass`}
          name={`holdings[${props.index}].assetClass`}
          handleChange={(selectedOption: Option | null) => {
            updateFiltersBySelectedOptions(
              selectedOption,
              'assetClass',
              assetsDictionariesFilters,
              setAssetsDictionariesFilters,
              props.assetsDictionaries
            );
            props.formikProps.setFieldValue(`holdings[${props.index}].assetClass`, selectedOption?.value);
          }}
          label="Class"
          placeholder="Choose an option"
          options={
            assetsDictionariesFilters.assetClass.assets.map((asset: any) => ({
              value: asset[FILTERS_NAMES.assetClass],
              label: asset[FILTERS_NAMES.assetClass]
            }))
          }
          value={props.formikProps.values && props.formikProps.values.holdings[props.index]?.assetClass}
          error={showErrorMessage(props.formikProps, props.index, 'assetClass')}
          helperText={getErrorMessage(props.formikProps, props.index, 'assetClass')}
          onBlur={props.formikProps.handleBlur}
        />
      </StyledTableCell>
      <StyledTableCell>
        <Autocomplete
          required
          size="medium"
          withMarginBottom={false}
          id={`holdings[${props.index}].asset`}
          name={`holdings[${props.index}].asset`}
          handleChange={(selectedOption: Option | null) => {
            updateFiltersBySelectedOptions(
              selectedOption,
              'name',
              assetsDictionariesFilters,
              setAssetsDictionariesFilters,
              props.assetsDictionaries
            );
            props.formikProps.setFieldValue(`holdings[${props.index}].asset`, selectedOption?.value);
          }}
          label="Asset"
          placeholder="Choose an option"
          options={
            assetsDictionariesFilters.name.assets.map((asset: any) => ({
              value: asset[FILTERS_NAMES.name],
              label: asset[FILTERS_NAMES.name]
            }))
          }
          value={props.formikProps.values && props.formikProps.values.holdings[props.index]?.asset}
          error={showErrorMessage(props.formikProps, props.index, 'asset')}
          helperText={getErrorMessage(props.formikProps, props.index, 'asset')}
          onBlur={props.formikProps.handleBlur}
        />
      </StyledTableCell>
      <StyledTableCell>
        <TextField
          required
          size="medium"
          withMarginBottom={false}
          id={`holdings[${props.index}].quantity`}
          name={`holdings[${props.index}].quantity`}
          onChange={(event) => {
            props.formikProps.setFieldValue(`holdings[${props.index}].quantity`, event.target.value);
          }}
          label="Weight"
          value={
            props.formikProps.values
            && props.formikProps.values.holdings?.[props.index]?.quantity
            && props.formikProps.values.holdings[props.index]?.quantity
          }
          error={showErrorMessage(props.formikProps, props.index, 'quantity')}
          helperText={getErrorMessage(props.formikProps, props.index, 'quantity')}
          onBlur={(event) => {
            props.formikProps.setFieldValue(`holdings[${props.index}].quantity`, Number(event.target.value).toFixed(2));
            props.formikProps.handleBlur(event);
          }}
        />
      </StyledTableCell>
      <StyledTableCell>
        <button
          type="button"
          onClick={(event: any) => {
            event.preventDefault();

            deleteRow(props.formikProps, props.index);
          }}
        >
          <StyledIcons iconType="closed-filled" size={14} />
        </button>
      </StyledTableCell>
    </StyledTableRow>
  );
};

export default PortfolioAssetsFormTableRow;
