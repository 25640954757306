import styled from '@emotion/styled';

import Typography from 'components/ui/Typography';

const ScenarioForecastInputsWrapper = styled.div`
  margin-top: 25px;
`;

// @ts-ignore
const ErrorMessage = styled(Typography)`
  align-self: center;
`;

export {
  ScenarioForecastInputsWrapper,
  ErrorMessage
};
