import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';

import {
  Asset,
  AssetForForm,
  Holdings,
  PortfolioDataForm
} from 'interfaces';

import { fetchPortfolio, sendPortfolio, updatePortfolio } from 'api';

import { randomCodeByLength } from 'utils/common';

const initialState: PortfolioDataForm = {
  notification: {
    open: false,
    message: null,
    type: undefined
  },
  portfolioDetails: {
    name: null,
    description: null,
    currency: 'USD',
    usageType: 1, // @TODO: hardcoded
    holdingsType: 1, // @TODO: hardcoded
    referenceDate: new Date().toISOString(),
    holdings: [{
      description: null,
      region: null,
      priceCurrency: null,
      assetClass: null,
      asset: null,
      quantity: null
    }],
    tagID: randomCodeByLength(uuidv4(), 8)
  },
  loading: false
};

export const addOrUpdatePortfolio = createAsyncThunk(
  'createPortfolio/addOrUpdatePortfolio',
  // @ts-ignore
  async ({ portfolioId, portfolioData, history }, { rejectWithValue, getState }: any) => {
    const holdings: Holdings[] = [];
    const { assetsPayloads } = getState().assets;

    portfolioData.holdings.forEach((el: any) => {
      const holdingAsset = assetsPayloads.filter(
        (asset: Asset) => asset.name === el.asset
      );

      const assetId = holdingAsset[0].id;

      holdings.push({
        quantity: parseFloat(el.quantity) / 100,
        hedgeRatio: 0,
        assetID: assetId,
        description: el.description
      });
    });

    return !portfolioId
      ? sendPortfolio({
        ...portfolioData,
        holdings
      })
        .then(() => {
          history?.push('/portfolio-manager');
        })
        .catch((error: any) => {
          rejectWithValue(error);
        })
      : updatePortfolio(portfolioId, {
        ...portfolioData,
        holdings
      })
        .catch((error: any) => {
          rejectWithValue(error);
        });
  }
);

export const getPortfolio = createAsyncThunk(
  'createPortfolio/getPortfolio',
  // @ts-ignore
  async ({ portfolioId }, { rejectWithValue, getState }: any) => fetchPortfolio(portfolioId)
    .then((response: any) => {
      const { assetsPayloads } = getState().assets;

      return {
        assetsPayloads,
        portfolio: response
      };
    })
    .catch(() => {
      rejectWithValue('Failed to load portfolio');
    })
);

const createPortfolio = createSlice({
  name: 'createPortfolio',
  initialState,
  reducers: {
    resetState (state) {
      return {
        ...state,
        notification: initialState.notification,
        portfolioDetails: initialState.portfolioDetails
      };
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(addOrUpdatePortfolio.fulfilled, (state) => ({
        ...state,
        notification: {
          open: true,
          message: 'Successfully created portfolio',
          type: 'success'
        }
      }))
      .addCase(addOrUpdatePortfolio.rejected, (state) => ({
        ...state,
        notification: {
          open: true,
          message: 'Failed to create portfolio',
          type: 'error'
        }
      }))
      .addCase(getPortfolio.pending, (state) => ({
        ...state,
        loading: true
      }))
      .addCase(getPortfolio.fulfilled, (state, action) => {
        const holdings: AssetForForm[] = [];
        const { portfolio, assetsPayloads }: any = action.payload;

        portfolio.holdings.forEach((el: Holdings) => {
          const holdingAsset = assetsPayloads.filter(
            (asset: Asset) => asset.id === el.assetID
          )[0];

          holdings.push({
            quantity: Number(el.quantity * 100).toFixed(2),
            description: el.description,
            region: holdingAsset?.region,
            priceCurrency: holdingAsset?.priceCurrency,
            assetClass: holdingAsset?.assetClass,
            asset: holdingAsset?.name
          });
        });

        return {
          ...state,
          portfolioDetails: {
            ...portfolio,
            holdings
          },
          loading: false
        };
      })
      .addCase(getPortfolio.rejected, (state) => ({
        ...state,
        notification: {
          open: true,
          message: 'Failed to create portfolio',
          type: 'error'
        },
        loading: false
      }));
  }
});

export const { resetState } = createPortfolio.actions;
export default createPortfolio.reducer;
