import { Asset, DashboardDataSet, ObjectName, PortfolioDataObject } from 'interfaces';
import { DASHBOARD_ANALYTICS_DATA_SET_TYPES } from 'constant';

export const getData = (
  portfolioData: PortfolioDataObject,
  portfolioId: string,
  scenarioId: string,
  period: string,
  dataSet: DashboardDataSet,
  attribute?: string,
  objectName?: ObjectName,
  assetsPayloads?: Asset[]
) => {
  const scenarioData = portfolioData[portfolioId].scenarioData[scenarioId];
  const common = {
    portfolioName: portfolioData[portfolioId].portfolioName,
    timePeriodDate: scenarioData.timePeriodData[period]?.timePeriodDate,
    ...scenarioData
  };

  switch (dataSet) {
    case DASHBOARD_ANALYTICS_DATA_SET_TYPES.summary:
    default:
      return {
        ...common,
        ...scenarioData?.timePeriodData[period]
      };

    case DASHBOARD_ANALYTICS_DATA_SET_TYPES.assetAttribute:
    case DASHBOARD_ANALYTICS_DATA_SET_TYPES.assetStats: {
      const asset = assetsPayloads?.filter((asset: Asset) => asset.id === attribute)[0];

      return {
        ...common,
        assetName: asset?.name || attribute,
        ...scenarioData?.timePeriodData[period]?.[objectName as ObjectName]?.[attribute as string]
      };
    }
  }
};
