import { createSlice } from '@reduxjs/toolkit';

import { ScenarioExplorerData } from 'interfaces';

const initialState: ScenarioExplorerData = {
  refreshList: false,
  scenarios: []
};

const scenarioExplorer = createSlice({
  name: 'scenarioExplorer',
  initialState,
  reducers: {}
});

export default scenarioExplorer.reducer;
