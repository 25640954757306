const appBaseUrl: string = process.env.REACT_APP_HOST || '';
const gateway: string = process.env.REACT_APP_API_SERVER || '';
const authAudience: string = process.env.REACT_APP_AUTH0_AUDIENCE || '';
const authClientId: string = process.env.REACT_APP_AUTH0_CLIENT_ID || '';
const authDomain: string = process.env.REACT_APP_AUTH0_DOMAIN || '';

export {
  appBaseUrl,
  gateway,
  authAudience,
  authClientId,
  authDomain
};
