import React, { useState, useEffect, useCallback } from 'react';

import { SIDE_BAR_MENU_ID } from 'constant';

import Container from 'components/ui/Container';

import {
  StyledBox,
  Wrapper
} from './PageButtonsWrapper.styles';

export interface PageButtonsWrapperProps {
  children: React.ReactNode;
}

const PageButtonsWrapper = (props: PageButtonsWrapperProps): JSX.Element => {
  const [margin, setMargin] = useState(0);

  const sideBarElement = document.getElementById(SIDE_BAR_MENU_ID);

  const checkMargin = useCallback(() => {
    setMargin(sideBarElement?.getBoundingClientRect().width || 0);
  }, [sideBarElement]);

  const useHandleScroll = useCallback(() => {
    checkMargin();
  }, [checkMargin]);

  useEffect(() => {
    checkMargin();
    window.addEventListener('resize', useHandleScroll);
    return () => window.removeEventListener('resize', useHandleScroll);
  }, [checkMargin, useHandleScroll]);

  return (
    <StyledBox margin={margin}>
      <Container>
        <Wrapper>
          {props.children}
        </Wrapper>
      </Container>
    </StyledBox>
  );
};

export default PageButtonsWrapper;
