import styled from '@emotion/styled';

import { PAGE_BUTTONS_WRAPPER_HEIGHT } from 'constant';

import Box from 'components/ui/Box';

const StyledBox = styled(Box)`
  position: fixed;
  right: ${(props) => props.theme.layout.padding}px;
  bottom: calc(${PAGE_BUTTONS_WRAPPER_HEIGHT}px + 20px);
  z-index: 4;
  max-width: 400px;
  border: 0;
  box-shadow: 0 5px 7px -5px rgba(0 0 0 / .2);
`;

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: right;
  gap: 10px;
  margin-top: 20px;
`;

const BgLayer = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 3;
  background-color: rgba(0 0 0 / .2);
`;

export {
  StyledBox,
  ButtonsWrapper,
  BgLayer
};
