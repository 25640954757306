import React, { useState } from 'react';

import {
  FormGroup,
  Checkbox,
  Grid,
  Typography
} from '@mui/material';
import { KeyboardArrowDown } from '@mui/icons-material';

import { SCENARIO_EXPLORER_FILTERS_ID, SCENARIO_TYPES_OPTIONS } from 'constant';

import DateChart from 'components/ui/DateChart';
import RangeSlider from 'components/ui/RangeSlider';
import RatingBar from 'components/ui/RatingBar';

import ExternalLabel from 'components/ui/ExternalLabel';
import Autocomplete from 'components/ui/Autocomplete';
import Scrollbar from 'components/ui/Scrollbar';

import {
  StyledBox,
  FilterList,
  FilterItem,
  StyledButton,
  StyledNativeSelect,
  StyledFormControlLabel,
  DateWrapper
} from './ScenarioExplorerFilters.styles';

export interface ScenarioExplorerFiltersProps {
  menuOpen?: boolean;
  filterAuthorData?: any;
}
const defaultProps = {
  filterAuthorData: []
};

const ScenarioExplorerFilters = (props: ScenarioExplorerFiltersProps): JSX.Element => {
  const [showDataScenarioType, setShowDataScenarioType] = useState(3);
  const [showDataAssetClass, setShowDataAssetClass] = useState(4);
  const [selectBox, setSelectBox] = useState(1);
  const [authorSearch, setAuthorSearch] = useState(null);
  const [dateFilter, setDateFilter] = useState<number[]>([20, 37]);

  const handleChange = (arg: any | null) => {
    setAuthorSearch(arg?.value);
  };

  const scenarioType = SCENARIO_TYPES_OPTIONS;

  const AssetsClass = [
    {
      label: 'Alternative'
    },
    {
      label: 'Bonds'
    },
    {
      label: 'Cash'
    },
    {
      label: 'Commodities'
    },
    {
      label: 'Crypto'
    },
    {
      label: 'Equity'
    },
    {
      label: 'FX'
    },
    {
      label: 'Inflation'
    },
    {
      label: 'Infrastructure'
    },
    {
      label: 'Private Equity'
    },
    {
      label: 'Real Estate'
    }
  ];

  const allDataScenarioType = scenarioType.length;
  const allDataAssetClass = AssetsClass.length;

  return (
    <StyledBox id={SCENARIO_EXPLORER_FILTERS_ID} menuOpen={props.menuOpen}>
      <Scrollbar big>
        <FilterList>
          <FilterItem>
            <ExternalLabel>User Rating</ExternalLabel>
            <RatingBar />
          </FilterItem>
          <FilterItem>
            <Grid container>
              <Grid xs={6} item>
                <ExternalLabel>Date Horizon</ExternalLabel>
              </Grid>
              <Grid xs={6} item>
                <StyledNativeSelect
                  id="select"
                  disableUnderline
                  fullWidth
                  IconComponent={KeyboardArrowDown}
                >
                  <option value="10">Count Three</option>
                  <option value="20">Twenty</option>
                </StyledNativeSelect>
              </Grid>
            </Grid>
            <DateChart values={dateFilter} />
            <RangeSlider dateFilter={setDateFilter} />
            <DateWrapper>
              <Typography>
                {
                  // eslint-disable-next-line no-nested-ternary
                  dateFilter[0] > 0 && dateFilter[0] < 15
                    ? '2021-09-01'
                    // eslint-disable-next-line no-nested-ternary
                    : dateFilter[0] < 30
                      ? '2021-08-12'
                      : dateFilter[0] < 40
                        ? '2022-08-12'
                        : '2022-09-12'
                }
              </Typography>
              <Typography>
                {
                  // eslint-disable-next-line no-nested-ternary
                  dateFilter[1] > 0 && dateFilter[1] < 15
                    ? '2021-09-01'
                    // eslint-disable-next-line no-nested-ternary
                    : dateFilter[1] < 30
                      ? '2021-08-12'
                      : dateFilter[1] < 40
                        ? '2022-08-12'
                        : '2022-09-12'
                }
              </Typography>
            </DateWrapper>
          </FilterItem>
          <FilterItem>
            <ExternalLabel>Scenario Type</ExternalLabel>
            {
              scenarioType.slice(0, showDataScenarioType).map((item, index) => (
                <StyledFormControlLabel
                  key={index}
                  control={(<Checkbox size="small" />)}
                  label={item.label}
                />
              ))
            }
            <Typography sx={{ color: '#A87791' }}>
              {scenarioType.length !== showDataScenarioType && (
                // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
                <span
                  style={{ cursor: 'pointer' }}
                  onClick={() => setShowDataScenarioType(allDataScenarioType)}
                >
                  + {scenarioType.length - 3} more
                </span>
              )}
            </Typography>
          </FilterItem>
          <FilterItem>
            <ExternalLabel>Author</ExternalLabel>
            <Autocomplete
              id="authorFilter"
              name="authorFilter"
              placeholder="Choose an option"
              value={authorSearch}
              options={props.filterAuthorData.map((obj:any) => ({
                value: obj.Author,
                label: obj.Author
              }))}
              handleChange={(arg: any | null) => handleChange(arg)}
            />
          </FilterItem>
          <FilterItem>
            <ExternalLabel>Asset Class</ExternalLabel>
            <FormGroup>
              {AssetsClass.slice(0, showDataAssetClass).map((item: any, index: number) => (
                <StyledFormControlLabel
                  key={index}
                  control={<Checkbox size="small" />}
                  label={item.label}
                />
              ))}
            </FormGroup>
            <Typography sx={{ color: '#A87791' }}>
              {AssetsClass.length !== showDataAssetClass && (
                // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
                <span
                  style={{ cursor: 'pointer' }}
                  onClick={() => setShowDataAssetClass(allDataAssetClass)}
                >
                  + {AssetsClass.length - 4} more
                </span>
              )}
            </Typography>
          </FilterItem>
          <FilterItem>
            <ExternalLabel>Subscription Type</ExternalLabel>
          </FilterItem>
          {
            ['Admin', 'Admin / Super Admin', 'Super Admin', 'Creator']
              .map((item, index) => {
                const selected = index === selectBox;

                return (
                  <StyledButton
                    size="small"
                    variant={selected ? 'contained' : 'outlined'}
                    color={selected ? 'primary' : 'default'}
                    key={index}
                    onClick={() => setSelectBox(index)}
                    fullWidth
                  >
                    Needs approval by {item}
                  </StyledButton>
                );
              })
          }
        </FilterList>
      </Scrollbar>
    </StyledBox>
  );
};

ScenarioExplorerFilters.defaultProps = defaultProps;

export default ScenarioExplorerFilters;
