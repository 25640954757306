import React, { useState, useEffect } from 'react';

import Plotly from 'plotly.js-basic-dist';

import createPlotlyComponent from 'react-plotly.js/factory';

import { ChartData } from 'interfaces';

import { prepareData, getDataFromSimdata } from './utils';

import {
  Wrapper
} from './Chart.styles';

const Plot = createPlotlyComponent(Plotly);

export interface ChartProps {
  chartData: ChartData;
  simData?: any;
  onChartClick?: (data: any) => void;
  normalHeight?: boolean;
}
const defaultProps = {
  simData: null,
  normalHeight: false
};

const Chart = (props: ChartProps): JSX.Element | null => {
  const [plot, setPlot] = useState<ChartData | null>(null);

  useEffect(() => {
    setPlot(prepareData(props.chartData, props.normalHeight));
  }, [props.chartData, props.normalHeight]);

  return (
    <Wrapper>
      {
        props.chartData?.data ? (
          <Plot
            divId="plotlyChart"
            style={{ width: '100%', height: '100%' }}
            useResizeHandler
            data={plot?.data}
            layout={plot?.layout}
            config={plot?.config}
            onHover={(data: any) => {
              if (!data) {
                return;
              }

              const clickedFragmentData = data?.points[0];

              const newData = {
                name: clickedFragmentData?.data?.name,
                return: Number(clickedFragmentData.x * 100).toFixed(2),
                density: clickedFragmentData?.customdata?.length
                  ? Number(clickedFragmentData?.customdata?.[0]).toFixed(2)
                  : null
              };

              const newDataSimdata = props.simData ? getDataFromSimdata(props.simData, clickedFragmentData) : {};

              props.onChartClick?.({
                ...newData,
                ...newDataSimdata
              });
            }}
          />
        ) : null
      }
    </Wrapper>
  );
};

Chart.defaultProps = defaultProps;

export default Chart;
