import React from 'react';

import { JobName } from 'interfaces';

import Heading from 'components/ui/Heading';
import JobViewDetails from 'components/JobViewDetails';
import JobViewTabs from 'components/JobViewTabs';

import {
  Wrapper,
  TabsContainer,
  DetailsContainer
} from './JobView.styles';

export interface JobViewProps {
  jobName: JobName;
  portfolioList: any[];
  scenarioList: any[];
}

const JobView = (props: JobViewProps): JSX.Element => (
  <React.Fragment>
    <Heading>{props.jobName}</Heading>
    <Wrapper>
      <TabsContainer>
        <JobViewTabs
          scenariosList={props.scenarioList}
          portfolioList={props.portfolioList}
        />
      </TabsContainer>
      <DetailsContainer>
        <JobViewDetails
          scenariosListLength={props.scenarioList?.length}
          portfolioListLength={props.portfolioList?.length}
        />
      </DetailsContainer>
    </Wrapper>
  </React.Fragment>
);

export default JobView;
