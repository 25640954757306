import React from 'react';

import Tooltip from 'components/ui/Tooltip';
import Icons from 'components/ui/Icons';

export interface InfoIconBoxProps {
  title: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal;
  className?: string;
  size?: number;
}
const defaultProps = {
  className: '',
  size: 20
};

const InfoIconBox = (props: InfoIconBoxProps): JSX.Element => (
  <Tooltip className={props.className} title={props.title}>
    <span>
      <Icons iconType="info" aria-label="info" size={props.size} />
    </span>
  </Tooltip>
);

InfoIconBox.defaultProps = defaultProps;

export default InfoIconBox;
