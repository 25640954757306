import styled from '@emotion/styled/macro';
import { css } from '@emotion/react';

import { Link } from 'react-router-dom';

import {
  MAIN_HEADER_HEIGHT,
  MAIN_NAV_WIDTH,
  PAGE_BUTTONS_WRAPPER_HEIGHT,
  MAIN_NAV_WIDE_WIDTH
} from 'constant';

import Box from 'components/ui/Box';
import Icons from 'components/ui/Icons';

import expandArrows from 'assets/menu/expand-arrows.svg';
import minimizedArrows from 'assets/menu/minimized-arrows.svg';

interface MenuOpenProps {
  menuOpen?: boolean | undefined;
}
interface MenuItem {
  isActive?: boolean | undefined;
  isDisabled?: boolean | undefined;
}

const StyledBox = styled(Box)<MenuOpenProps>`
  position: fixed;
  left: 0;
  z-index: 4;
  width: ${MAIN_NAV_WIDTH}px;
  height: 100%;
  padding: 0;
  border-width: 0 1px 0 0;
  transition: ${(props) => props.theme.layout.transition};

  ${(props) => props.menuOpen && css`
    width: ${MAIN_NAV_WIDE_WIDTH}px;
  `};
`;

const LogoWrapper = styled.div<MenuOpenProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${MAIN_HEADER_HEIGHT}px;
  padding: 0 13px;
  border-bottom: 1px solid transparent;

  ${(props) => props.menuOpen && css`
    justify-content: left;
    border-color: #e3e3e3;
  `};
`;

const Logo = styled.img`
  display: block;
`;

const Nav = styled.nav<MenuOpenProps>`
  padding-top: 5px;

  ${(props) => props.menuOpen && css`
    height: calc(100% - ${PAGE_BUTTONS_WRAPPER_HEIGHT}px - ${MAIN_HEADER_HEIGHT}px);
    margin-bottom: ${PAGE_BUTTONS_WRAPPER_HEIGHT}px;
    padding: 5px 0;
  `};
`;

const NavSubLink = styled(Link)`
  position: relative;
  display: flex;
  align-items: center;
  gap: 20px;
  min-height: 34px;
  padding: 0 13px;
  border-left: 2px solid transparent;
  font-size: 14px;

  &:hover {
    background: #f2f2f2;
  }
`;

const SubMenuItem = styled.li<MenuItem>`
  position: relative;

  &:not(:last-of-type) {
    margin-bottom: 5px;
  }

  &::before {
    content: '';
    position: absolute;
    top: -13px;
    left: -17px;
    display: block;
    width: 25px;
    height: 100%;
    border: solid #d9d9d9;
    border-width: 0 0 1px 1px;
    border-radius: 0 0 0 17px;
  }

  ${(props) => props.isActive && css`
    && {
      ${NavSubLink} {
        background: #f2f2f2;
        border-left: 2px solid;
        color: ${props.theme.colorText.primary};
      }
    }
  `};
`;

const SubMenu = styled.ul<MenuOpenProps>`
  position: absolute;
  top: 0;
  left: 100%;
  display: none;
  width: 240px;
  min-width: 179px;
  transition: ${(props) => props.theme.layout.transition};
  list-style: none;

  ${(props) => props.menuOpen && css`
    position: static;
    display: block;
    width: auto;
    margin: 10px 15px 0 45px;
  `};

  ${(props) => !props.menuOpen && css`
    background: ${props.theme.colorMono.white};
    box-shadow: 0 12px 20px rgba(0, 0, 0, 0.15), 10px 0 20px rgba(0, 0, 0, 0.15);

    && {
      ${SubMenuItem} {
        min-height: 38px;
        margin-bottom: 0;
      }
    }
    ${SubMenuItem}::before {
      content: normal;
    }
    ${NavSubLink} {
      color: #808080;
      min-height: 38px;
    }
  `};
`;

const NavList = styled.ul`
  margin: 20px 0;
  list-style: none;
`;

const NavLink = styled.button<MenuItem>`
  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;
  gap: 20px;
  min-height: 50px;
  padding: 0 13px;
  cursor: pointer;

  ${(props) => props.isDisabled && css`
    &:hover {
      background: #f2f2f2;
    }
  `};
`;

const NavItem = styled.li<MenuItem & MenuOpenProps>`
  position: relative;
  padding-bottom: 22px;

  &::before {
    content: '';
    position: absolute;
    top: 50px;
    left: 28px;
    display: block;
    width: 0;
    height: calc(100% - 115px);
    border-left: 1px solid #d9d9d9;
  }

  ${(props) => !props.menuOpen && !props.isDisabled && css`
    &:hover {
      ${NavLink} {
        background: #f2f2f2;
      }
    }
  `};

  ${(props) => !props.menuOpen && css`
    ${NavLink} {
      border-left: 2px solid transparent;
    }
  `};
  ${(props) => !props.menuOpen && props.isActive && css`
    ${NavLink} {
      border-left: 2px solid;
      background: #f2f2f2;
    }
  `};
  ${(props) => !props.menuOpen && css`
    &::before {
      content: normal;
    }
  `};

  ${(props) => props.isDisabled && css`
    ${NavLink} {
      opacity: 0.5;
      cursor: not-allowed;
      pointer-events: none;
    }
  `};

  &:hover {
    ${SubMenu} {
      display: block;
    }
  }
`;

const MenuIcon = styled.img`
  display: block;
`;

const MenuHeader = styled.div<MenuOpenProps>`
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 18px;

  ${(props) => !props.menuOpen && css`
    min-height: 50px;
    padding: 10px 13px;
    border-bottom: 1px solid #e3e3e3;
  `};
`;

const MenuFooter = styled.div<MenuOpenProps>`
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${PAGE_BUTTONS_WRAPPER_HEIGHT}px;
  border-top: 1px solid #e3e3e3; // @TODO: theme
  background: ${(props) => props.theme.colorMono.white};

  ${(props) => props.menuOpen && css`
    justify-content: flex-end;
  `};
`;

const ArrowButton = styled.button<MenuOpenProps>`
  padding: 28px;
  cursor: pointer;

  &::before {
    content: '';
    display: block;
    width: 18px;
    height: 18px;
    background: url(${expandArrows}) no-repeat;
    background-size: contain;
  }
  ${(props) => props.menuOpen && css`
    &::before {
      background-image: url(${minimizedArrows});
    }
  `};
`;

// @ts-ignore
const StyledIcons = styled(Icons)`
  margin-top: 3px;
`;

export {
  StyledBox,
  LogoWrapper,
  Logo,
  Nav,
  NavList,
  NavItem,
  NavLink,
  MenuIcon,
  SubMenu,
  SubMenuItem,
  NavSubLink,
  MenuHeader,
  MenuFooter,
  ArrowButton,
  StyledIcons
};
