const color = {
  primary: '#A87791',
  primaryHover: '#8A5B74',
  primaryFocus: '#D4ADC2',
  primaryActive: '#b992a6',

  secondary: '#014547',
  secondaryHover: '#003738',
  secondaryFocus: '#4E7D7F',
  secondaryActive: '#346a6c',

  tertiary: '',
  tertiaryFocus: '',
  tertiaryActive: '',

  error: '#da1e28',

  gray: '#f4f4f4'
};

const colorMono = {
  white: '#ffffff',
  black: '#000000'
};

const colorGray = {
  black88: 'rgba(0, 0, 0, .88)',
  black56: 'rgba(0, 0, 0, .56)',
  black42: 'rgba(0, 0, 0, .42)',
  black24: 'rgba(0, 0, 0, .24)',
  black12: 'rgba(0, 0, 0, .12)',
  black06: 'rgba(0, 0, 0, .06)',
  black03: 'rgba(0, 0, 0, .03)',

  white88: 'rgba(255, 255, 255, .88)',
  white56: 'rgba(255, 255, 255, .56)',
  white42: 'rgba(255, 255, 255, .42)',
  white24: 'rgba(255, 255, 255, .24)',
  white12: 'rgba(255, 255, 255, .12)',
  white06: 'rgba(255, 255, 255, .06)'
};

const colorText = {
  primary: '#161616',
  secondary: '#525252'
};

const colorIcon = {
  primary: '#9C9C9C',
  secondary: colorGray.black56
};

export const colors = {
  color,
  colorGray,
  colorMono,
  colorText,
  colorIcon
};

