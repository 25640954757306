import React from 'react';

import { ColDef, ColGroupDef } from 'ag-grid-enterprise';

import { ScenarioForecast } from 'interfaces';

import { COMPONENTS_TYPE_IDS } from 'constant';

import { textRenderer } from 'utils/tableFormatters';

import ScenarioForecastInputsTable from 'components/ScenarioForecastInputsTable';

import {
  AssetsWrapper
} from './ScenarioViewAssetsTable.styles';

export interface ScenarioViewAssetsTableProps {
  scenarioForecast: ScenarioForecast;
}

const ScenarioViewAssetsTable = (props: ScenarioViewAssetsTableProps): JSX.Element => {
  const timeColumnDefault = {
    minWidth: 90,
    maxWidth: 530,
    cellClass: 'center-row-container'
  };

  const columnDefs: Array<ColGroupDef | ColDef> = [
    {
      field: 'assetName',
      headerName: 'Asset Class',
      flex: 1,
      minWidth: 310,
      maxWidth: 610,
      editable: false,
      pinned: 'left',
      suppressMovable: true,
      cellRenderer: textRenderer,
      rowSpan: (params) => params.data.componentType === COMPONENTS_TYPE_IDS.max ? 2 : 1,
      cellClass: 'asset-class',
      cellClassRules: {
        'cell-span': (params: any) => params.data.componentType === COMPONENTS_TYPE_IDS.max
      }
    },
    {
      field: 'metricTypeLabel',
      headerName: '',
      minWidth: 90,
      maxWidth: 90,
      editable: false,
      pinned: 'left',
      suppressMovable: true,
      cellClass: 'metric-type-label',
      rowSpan: (params) => params.data.componentType === COMPONENTS_TYPE_IDS.max ? 2 : 1,
      colSpan: (params) => params.data.componentType === COMPONENTS_TYPE_IDS.point ? 2 : 1,
      cellClassRules: {
        'cell-span': (params: any) => params.data.componentType === COMPONENTS_TYPE_IDS.max
      }
    },
    {
      field: 'minMaxLabel',
      headerName: '',
      minWidth: 70,
      maxWidth: 70,
      editable: false,
      pinned: 'left',
      suppressMovable: true,
      cellClass: 'min-max-label'
    },
    {
      ...timeColumnDefault,
      field: 'M3',
      headerName: '3M'
    },
    {
      ...timeColumnDefault,
      field: 'M6',
      headerName: '6M'
    },
    {
      ...timeColumnDefault,
      field: 'M12',
      headerName: '12M'
    },
    {
      ...timeColumnDefault,
      field: 'M18',
      headerName: '18M'
    },
    {
      ...timeColumnDefault,
      field: 'M24',
      headerName: '2Y'
    },
    {
      ...timeColumnDefault,
      field: 'M36',
      headerName: '3Y'
    },
    {
      ...timeColumnDefault,
      field: 'M48',
      headerName: '4Y'
    },
    {
      ...timeColumnDefault,
      field: 'M60',
      headerName: '5Y'
    },
    {
      ...timeColumnDefault,
      field: 'M120',
      headerName: '10Y'
    }
  ];

  return (
    <AssetsWrapper>
      {
        props.scenarioForecast.components.length ? (
          <ScenarioForecastInputsTable
            rowData={props.scenarioForecast.components}
            columnDefs={columnDefs}
            withoutColors
            rowClassRules={{
              'row-span': (params: any) => params.data.componentType === COMPONENTS_TYPE_IDS.max
            }}
          />
        ) : 'No components'
      }
    </AssetsWrapper>
  );
};

export default ScenarioViewAssetsTable;
