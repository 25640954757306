import styled from '@emotion/styled';

const Wrapper = styled.div`
  display: flex;
  gap: 30px;
  width: 100%;
  margin-top: 25px;
`;

const TabsContainer = styled.div`
  flex: 1 1 calc(58% - 15px);
`;

const DetailsContainer = styled.div`
  flex: 1 1 calc(42% - 15px);
`;

export {
  Wrapper,
  TabsContainer,
  DetailsContainer
};
