import React from 'react';

import createIcon from 'assets/create-icon.svg';
import pointingArrow from 'assets/arrow.svg';

import Icons from 'components/ui/Icons';

import {
  StyledBox,
  CreateWrapper,
  CreateJobImage,
  ArrowImage,
  StyledIconButton
} from './CreateJobTeaser.styles';

export interface CreateJobTeaserProps {
  className?: string;
  onCreateNewCard?: () => void;
}
const defaultProps = {
  className: '',
  onCreateNewCard: () => {}
};

const CreateJobTeaser = (props: CreateJobTeaserProps): JSX.Element => (
  <StyledBox className={props.className}>
    <CreateWrapper>
      <CreateJobImage src={createIcon} alt="" />
      <p>Create a new Job</p>
      <ArrowImage src={pointingArrow} alt="" />
    </CreateWrapper>
    <StyledIconButton size="medium" onClick={props.onCreateNewCard}>
      <Icons iconType="plus" size={9} />
    </StyledIconButton>
  </StyledBox>
);

CreateJobTeaser.defaultProps = defaultProps;

export default CreateJobTeaser;
