import React from 'react';
import { Form, Formik, FormikProps } from 'formik';

import { Asset } from 'interfaces';

import {
  FILTERS_NAMES,
  JOB_SETTINGS_FORECAST_HORIZON_OPTIONS,
  JOB_SETTINGS_SAMPLING_INTERVAL_OPTIONS
} from 'constant';

import { jobSettingsValidationSchema } from 'utils/validationScemas';

import { Option } from 'components/ui/Autocomplete';
import Typography from 'components/ui/Typography';
import Button from 'components/ui/Button';

import {
  StyledBox,
  ButtonsWrapper,
  BgLayer,
  StyledTextField,
  StyledSelect,
  StyledAutocomplete
} from './JobSettings.styles';

export interface JobSettingsProps {
  initialValues: any;
  setShowSettings: (data: any) => void;
  currencyDictionary: any[];
  onSubmit: (values: any) => void;
}

const JobSettings = (props: JobSettingsProps): JSX.Element => {
  const currencyOptions: Option[] = props.currencyDictionary.map((asset: Asset) => ({
    value: asset[FILTERS_NAMES.priceCurrency],
    label: asset[FILTERS_NAMES.priceCurrency]
  }));

  return (
    <React.Fragment>
      <BgLayer />
      <StyledBox>
        <Typography withMarginBottom>
          Job setting
        </Typography>
        <Formik
          enableReinitialize
          validationSchema={jobSettingsValidationSchema}
          initialValues={props.initialValues}
          onSubmit={(values) => {
            props.onSubmit(values);
          }}
        >
          {(formikProps: FormikProps<any>) => (
            <Form onSubmit={formikProps.handleSubmit} noValidate>
              <StyledTextField
                required
                withExternalLabel
                labelPosition="left"
                labelVariant="secondary"
                size="medium"
                id="numberOfSamples"
                name="numberOfSamples"
                onChange={(event) => {
                  formikProps.setFieldValue('numberOfSamples', event.target.value);
                }}
                label="Number of samples"
                value={formikProps.values && formikProps.values.numberOfSamples}
                error={
                  Boolean(formikProps.touched.numberOfSamples)
                  && Boolean(formikProps.errors.numberOfSamples)
                }
                helperText={
                  formikProps.touched.numberOfSamples ? formikProps.errors.numberOfSamples : null
                }
                onBlur={formikProps.handleBlur}
              />
              <StyledTextField
                required
                disabled
                withExternalLabel
                labelPosition="left"
                labelVariant="secondary"
                size="medium"
                id="modelHorizon"
                name="modelHorizon"
                onChange={(event) => {
                  formikProps.setFieldValue('modelHorizon', event.target.value);
                }}
                label="Model horizon"
                value={formikProps.values && formikProps.values.modelHorizon}
                error={
                  Boolean(formikProps.touched.modelHorizon)
                  && Boolean(formikProps.errors.modelHorizon)
                }
                helperText={
                  formikProps.touched.modelHorizon ? formikProps.errors.modelHorizon : null
                }
                onBlur={formikProps.handleBlur}
              />
              <StyledSelect
                required
                withExternalLabel
                labelPosition="left"
                labelVariant="secondary"
                size="medium"
                id="samplingInterval"
                name="samplingInterval"
                onChange={(value: any) => {
                  formikProps.setFieldValue('samplingInterval', value);
                }}
                label="Sampling interval"
                options={JOB_SETTINGS_SAMPLING_INTERVAL_OPTIONS}
                selected={formikProps.values && formikProps.values.samplingInterval}
                error={
                  Boolean(formikProps.touched.samplingInterval)
                  && Boolean(formikProps.errors.samplingInterval)
                }
                helperText={
                  formikProps.touched.samplingInterval ? formikProps.errors.samplingInterval : null
                }
                onBlur={formikProps.handleBlur}
              />
              <StyledSelect
                required
                withExternalLabel
                labelPosition="left"
                labelVariant="secondary"
                size="medium"
                id="forecastHorizon"
                name="forecastHorizon"
                onChange={(value: any) => {
                  formikProps.setFieldValue('forecastHorizon', value);
                }}
                label="Forecast horizon"
                options={JOB_SETTINGS_FORECAST_HORIZON_OPTIONS}
                selected={formikProps.values && formikProps.values.forecastHorizon}
                error={
                  Boolean(formikProps.touched.forecastHorizon)
                  && Boolean(formikProps.errors.forecastHorizon)
                }
                helperText={
                  formikProps.touched.forecastHorizon ? formikProps.errors.forecastHorizon : null
                }
                onBlur={formikProps.handleBlur}
              />
              <StyledAutocomplete
                required
                withExternalLabel
                labelPosition="left"
                labelVariant="secondary"
                size="medium"
                id="reportingCurrency"
                name="reportingCurrency"
                label="Reporting currency"
                options={currencyOptions}
                handleChange={(selectedOption: Option | null) => {
                  formikProps.setFieldValue('reportingCurrency', selectedOption?.value);
                }}
                value={formikProps.values && formikProps.values.reportingCurrency}
                error={
                  Boolean(formikProps.touched.reportingCurrency)
                  && Boolean(formikProps.errors.reportingCurrency)
                }
                helperText={
                  formikProps.touched.reportingCurrency ? formikProps.errors.reportingCurrency : null
                }
                onBlur={formikProps.handleBlur}
              />
              <ButtonsWrapper>
                <Button
                  variant="outlined"
                  color="default"
                  onClick={() => props.setShowSettings(false)}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  color="primary"
                  disabled={!formikProps.isValid}
                >
                  Save
                </Button>
              </ButtonsWrapper>
            </Form>
          )}
        </Formik>
      </StyledBox>
    </React.Fragment>
  );
};

export default JobSettings;
