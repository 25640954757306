import React from 'react';
import styled from '@emotion/styled';

import { Chip } from '@material-ui/core';

const StyledChip = styled(({
  ...props
}) => (
  <Chip {...props} />
))`
  border: 1px solid ${(props) => props.theme.colorText.primary};
  background: ${(props) => props.theme.colorMono.white};
  color: ${(props) => props.theme.colorText.primary};

  .MuiChip-deleteIcon {
    display: flex;
    align-items: center;
    width: auto;
    margin: 2px 12px 0 0;
    font-size: 14px;
    color: ${(props) => props.theme.colorText.primary};

    &:hover {
      color: rgba(0 0 0 / .4);
    }
  }
`;

export {
  StyledChip
};
