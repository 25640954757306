import React, { useState } from 'react';

import { SIDE_PANEL_ID } from 'constant';

import Scrollbar from 'components/ui/Scrollbar';

import {
  StyledBox,
  DataWrapper,
  DataItem,
  Text,
  StyledExternalLabel,
  StyledSliderNavButton
} from './DetailsPanel.styles';

export interface DetailsPanelProps {
  data: any;
  type: 'dashboard' | 'scenario' | 'portfolio' | 'job',
  withHideButton?: boolean;
  onButtonClick?: (data?: any) => void;
}
const defaultProps = {
  withHideButton: false
};

const DetailsPanel = (props: DetailsPanelProps): JSX.Element => {
  const hidePanelInit = JSON.parse(sessionStorage.getItem('hideSidePanel') as string) || {
    dashboard: false,
    scenario: false,
    portfolio: false,
    job: false
  };

  const [hidePanel, setHidePanel] = useState(hidePanelInit);

  return (
    <StyledBox id={SIDE_PANEL_ID} hidePanel={hidePanel[props.type]}>
      <Scrollbar>
        {
          props.type === 'dashboard' ? (
            <DataWrapper>
              <DataItem>
                <StyledExternalLabel uppercase>Name</StyledExternalLabel>
                <Text>{props.data?.name}</Text>
              </DataItem>
              <DataItem>
                <StyledExternalLabel uppercase>Return</StyledExternalLabel>
                <Text>{props.data?.return ? `${props.data?.return}%` : null}</Text>
              </DataItem>
              {
                props.data?.density ? (
                  <DataItem>
                    <StyledExternalLabel uppercase>Density</StyledExternalLabel>
                    <Text>{props.data?.density}</Text>
                  </DataItem>
                ) : null
              }
              {
                props.data?.cVaR95 ? (
                  <DataItem>
                    <StyledExternalLabel uppercase labelInfo="Conditional value-at-risk 95%">CVaR95%</StyledExternalLabel>
                    <Text>{props.data?.cVaR95}%</Text>
                  </DataItem>
                ) : null
              }
              {
                props.data?.cVaR99 ? (
                  <DataItem>
                    <StyledExternalLabel uppercase labelInfo="Conditional value-at-risk 99%">CVaR99%</StyledExternalLabel>
                    <Text>{props.data?.cVaR99}%</Text>
                  </DataItem>
                ) : null
              }
              {
                props.data?.mean ? (
                  <DataItem>
                    <StyledExternalLabel uppercase>Mean</StyledExternalLabel>
                    <Text>{props.data?.mean}%</Text>
                  </DataItem>
                ) : null
              }
              {
                props.data?.volAnnualized ? (
                  <DataItem>
                    <StyledExternalLabel uppercase>Volatility</StyledExternalLabel>
                    <Text>{props.data?.volAnnualized}%</Text>
                  </DataItem>
                ) : null
              }
              {
                props.data?.timePeriodDate ? (
                  <DataItem>
                    <StyledExternalLabel uppercase>Date</StyledExternalLabel>
                    <Text>{props.data?.timePeriodDate}</Text>
                  </DataItem>
                ) : null
              }
            </DataWrapper>
          ) : null
        }
        {
          props.type === 'scenario' ? (
            <DataWrapper>
              <DataItem>
                <StyledExternalLabel uppercase>Subscription</StyledExternalLabel>
                <Text>{props.data?.subscription}</Text>
              </DataItem>
              <DataItem>
                <StyledExternalLabel uppercase>Type</StyledExternalLabel>
                <Text>{props.data?.type}</Text>
              </DataItem>
              <DataItem>
                <StyledExternalLabel uppercase>Name</StyledExternalLabel>
                <Text>{props.data?.name}</Text>
              </DataItem>
              <DataItem>
                <StyledExternalLabel uppercase>Description</StyledExternalLabel>
                <Text>{props.data?.description}</Text>
              </DataItem>
              <DataItem>
                <StyledExternalLabel uppercase>Author</StyledExternalLabel>
                <Text>{props.data?.author}</Text>
              </DataItem>
              {
                props.data?.tags ? (
                  <DataItem>
                    <StyledExternalLabel uppercase>Tags</StyledExternalLabel>
                    <Text>{props.data?.tags}</Text>
                  </DataItem>
                ) : null
              }
            </DataWrapper>
          ) : null
        }
        {
          props.type === 'portfolio' ? (
            <DataWrapper>
              <DataItem>
                <StyledExternalLabel uppercase>Name</StyledExternalLabel>
                <Text>{props.data?.name}</Text>
              </DataItem>
              <DataItem>
                <StyledExternalLabel uppercase>Date</StyledExternalLabel>
                <Text>{props.data?.referenceDate}</Text>
              </DataItem>
              <DataItem>
                <StyledExternalLabel uppercase>Description</StyledExternalLabel>
                <Text>{props.data?.description}</Text>
              </DataItem>
              <DataItem>
                <StyledExternalLabel uppercase>Currency</StyledExternalLabel>
                <Text>{props.data?.currency}</Text>
              </DataItem>
            </DataWrapper>
          ) : null
        }
        {
          props.type === 'job' ? (
            <div>TODO</div>
          ) : null
        }
      </Scrollbar>
      {
        props.withHideButton ? (
          <StyledSliderNavButton
            navDirection={hidePanel[props.type] ? 'left' : 'right'}
            onClick={() => {
              setHidePanel({
                ...hidePanel,
                [props.type]: !hidePanel[props.type]
              });
              sessionStorage.setItem('hideSidePanel', JSON.stringify({
                ...hidePanel,
                [props.type]: !hidePanel[props.type]
              }));

              props.onButtonClick?.();

              setTimeout(() => {
                window.dispatchEvent(new Event('resize'));
              }, 600);
            }}
          />
        ) : null
      }
    </StyledBox>
  );
};

DetailsPanel.defaultProps = defaultProps;

export default DetailsPanel;
