import React, { useMemo, useRef } from 'react';

import { DashboardDataSet } from 'interfaces';

import { DASHBOARD_ANALYTICS_DATA_SET_TYPES } from 'constant';

import ManagerTable from 'components/ui/ManagerTable';

import { getColumnDefs } from './utils';

export interface DashboardAnalyticsTableProps {
  dataSet?: DashboardDataSet;
  analyticsRows?: any[];
  additionalValueToHeightSubtraction?: number | null;
}
const defaultProps = {
  dataSet: DASHBOARD_ANALYTICS_DATA_SET_TYPES.summary,
  analyticsRows: [],
  additionalValueToHeightSubtraction: null
};

const DashboardAnalyticsTable = (props: DashboardAnalyticsTableProps): JSX.Element => {
  const gridRef = useRef();

  const columnDefs = getColumnDefs(props.dataSet);

  const defaultColDef = useMemo(() => ({
    resizable: true,
    editable: false,
    sortable: true,
    flex: 1,
    minWidth: 350,
    defaultWidth: 480,
    suppressMovable: false,
    enableRowGroup: true,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    checkbox: false,
    menuTabs: ['filterMenuTab', 'generalMenuTab', 'columnsMenuTab']
  }), []);

  return (
    <ManagerTable
      gridRef={gridRef}
      defaultColDef={defaultColDef}
      rowData={props.analyticsRows}
      columnDefs={columnDefs}
      additionalValueToHeightSubtraction={props.additionalValueToHeightSubtraction}
      showHeaderButtons={false}
    />
  );
};

DashboardAnalyticsTable.defaultProps = defaultProps;

export default DashboardAnalyticsTable;
