import api from 'config/api';

export const fetchPortfolios = () => (
  api.get('/api/portfolios')
);

export const fetchPortfolio = (id: number) => (
  api.get(`/api/portfolios/${id}`)
);

export const sendPortfolio = (data: any) => (
  api.post('/api/portfolios', data)
);

export const updatePortfolio = (id: number, data: any) => (
  api.put(`/api/portfolios/${id}`, data)
);

export const removePortfolio = (id: number) => (
  api.delete(`/api/portfolios/${id}`)
);
