import React, { useMemo } from 'react';
import { AgGridReact } from 'ag-grid-react';

import {
  TableWrapper
} from './ScenarioForecastInputsTable.styles';

export interface ScenarioForecastInputsTableProps {
  rowData: any;
  columnDefs: any;
  gridRef?: any;
  withActions?: boolean | undefined;
  withoutColors?: boolean | undefined;
  editable?: boolean | undefined;
  rowClassRules?: any;
  onCellEditingStarted?: (event: any) => void;
  onCellEditingStopped?: (event: any) => void;
}
const defaultProps = {
  gridRef: null,
  withActions: false,
  withoutColors: false,
  editable: false,
  rowClassRules: null
};

const ScenarioForecastInputsTable = (props: ScenarioForecastInputsTableProps): JSX.Element => {
  const defaultColDef = useMemo(() => ({
    resizable: false,
    editable: props.editable,
    sortable: false,
    flex: 1,
    defaultWidth: 480,
    suppressMovable: true
  }), [props.editable]);

  return (
    <TableWrapper
      withoutColors={props.withoutColors}
      withActions={props.withActions}
      editable={props.editable}
    >
      <AgGridReact
        className="ag-theme-alpine"
        ref={props.gridRef}
        suppressRowClickSelection
        animateRows
        rowData={props.rowData}
        defaultColDef={defaultColDef}
        columnDefs={props.columnDefs}
        rowClassRules={props.rowClassRules}
        rowHeight={48}
        overlayNoRowsTemplate="<span> </span>"
        loadingCellRenderer="...Loading"
        onCellEditingStarted={props.onCellEditingStarted}
        onCellEditingStopped={props.onCellEditingStopped}
      />
    </TableWrapper>
  );
};

ScenarioForecastInputsTable.defaultProps = defaultProps;

export default ScenarioForecastInputsTable;
