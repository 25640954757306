import React, { useState } from 'react';

import { PortfolioDetails } from 'interfaces';

import Button from 'components/ui/Button';
import Modal from 'components/ui/Modal';

import PageButtonsWrapper from 'components/PageButtonsWrapper';
import ReviewPortfolioModal from 'components/ReviewPortfolioModal';

export interface ReviewPortfolioProps {
  portfolio: PortfolioDetails;
  submitPortfolio: () => void;
  reviewButtonDisabled: boolean;
}

const ReviewPortfolio = (props: ReviewPortfolioProps): JSX.Element => {
  const [showModal, setShowModal] = useState(false);

  return (
    <React.Fragment>
      {
        showModal ? (
          <Modal
            title="Review Portfolio"
            open={showModal}
            onClose={() => setShowModal(false)}
            footerElement={(
              <React.Fragment>
                <Button
                  variant="outlined"
                  color="default"
                  onClick={() => setShowModal(false)}
                >
                  Go Back
                </Button>
                <Button
                  type="submit"
                  color="secondary"
                  onClick={() => {
                    setShowModal(false);
                    props.submitPortfolio();
                  }}
                >
                  Looks good, save it
                </Button>
              </React.Fragment>
            )}
          >
            <ReviewPortfolioModal portfolio={props.portfolio} />
          </Modal>
        ) : null
      }
      <PageButtonsWrapper>
        <Button
          color="secondary"
          onClick={() => setShowModal(true)}
          disabled={props.reviewButtonDisabled}
        >
          Review Portfolio
        </Button>
      </PageButtonsWrapper>
    </React.Fragment>
  );
};

export default ReviewPortfolio;
