import React from 'react';

import Container from 'components/ui/Container';
import Icons from 'components/ui/Icons';
import Heading from 'components/ui/Heading';

import {
  Wrapper,
  LogoWrapper,
  ContentWrapper,
  ContentBottomWrapper
} from './AuthPage.styles';

interface AuthPageProps {
  title: string;
  children: React.ReactNode;
  footer?: React.ReactNode;
  contentWithBorder?: boolean;
  footerWithBorder?: boolean;
}
const defaultProps = {
  footer: null,
  footerWithBorder: false,
  contentWithBorder: false
};

const AuthPage = (props: AuthPageProps): JSX.Element => (
  <Container small>
    <Wrapper>
      <LogoWrapper>
        <Icons iconType="logo" size={50} />
        <Heading as="h1" variant="default">{props.title}</Heading>
      </LogoWrapper>
      <ContentWrapper contentWithBorder={props.contentWithBorder}>
        {props.children}
      </ContentWrapper>
      {
        props.footer ? (
          <ContentBottomWrapper footerWithBorder={props.footerWithBorder}>
            {props.footer}
          </ContentBottomWrapper>
        ) : null
      }

    </Wrapper>
  </Container>
);

AuthPage.defaultProps = defaultProps;

export default AuthPage;
