import { Asset, AssetCurve, ForecastTableData, MetricType } from 'interfaces';

import { COMPONENTS_TYPE_IDS, RANGE_TYPE_LABELS, METRIC_TYPES_IDS } from 'constant';

import { createMonthData } from './createMonthData';

export const forecastSetRangeTableData = (
  assetsPayloads: Asset[],
  assetsCurves: AssetCurve[],
  selectedOptions: Asset,
  tableDataLength: number,
  metricType: MetricType | null = null
): ForecastTableData[] => {
  const asset = assetsPayloads.filter((asset: Asset) => asset.name === selectedOptions.name)[0];

  return ([
    {
      id: tableDataLength + 1,
      minMaxLabel: RANGE_TYPE_LABELS.max,
      componentType: COMPONENTS_TYPE_IDS.max,
      metricType: metricType || METRIC_TYPES_IDS.price,
      assetName: selectedOptions?.name,
      ...createMonthData(assetsCurves, null, null, asset.id, metricType, false)
    },
    {
      id: tableDataLength + 2,
      minMaxLabel: RANGE_TYPE_LABELS.min,
      componentType: COMPONENTS_TYPE_IDS.min,
      metricType: metricType || METRIC_TYPES_IDS.price,
      assetName: selectedOptions?.name,
      ...createMonthData(assetsCurves, null, null, asset.id, metricType, false)
    }
  ]);
};
