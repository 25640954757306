import React from 'react';

import {
  Wrapper
} from './Container.styles';

export type ContainerProps = {
  children: React.ReactNode;
  small?: boolean;
  className?: string;
  id?: string | null | undefined;
};
const defaultProps = {
  small: false,
  className: '',
  id: null
};

const Container = (props: ContainerProps): JSX.Element => (
  <Wrapper
    id={props.id as string}
    className={props.className}
    small={props.small}
  >
    {props.children}
  </Wrapper>
);

Container.defaultProps = defaultProps;

export default Container;
