import React, { useState, useRef } from 'react';

import { ClickAwayListener, Grow } from '@mui/material';

import Icons from 'components/ui/Icons';
import IconButton from 'components/ui/IconButton';
import Switch from 'components/ui/Switch';

import {
  HeaderButtons,
  StyledBox,
  StyledPopper,
  StyledMenuList,
  StyledMenuItem
} from './ManagerTableHeaderButtons.styles';

export interface ManagerTableHeaderButtonsProps {
  fullColumnDefs?: any;
  setFullColumnDefs?: (newValue: any) => void;
  gridRef?: any;
}
const defaultProps = {
  fullColumnDefs: [],
  gridRef: null
};

const ManagerTableHeaderButtons = (props: ManagerTableHeaderButtonsProps): JSX.Element => {
  const [open, setOpen] = useState<{ [kay: string]: boolean }>({
    settings: false,
    columnsVisibility: false
  });

  const columnsVisibilityRef = useRef<HTMLButtonElement>(null);

  const handleToggle = (name: string) => {
    setOpen((prevOpen) => ({
      ...prevOpen,
      [name]: !prevOpen[name]
    }));
  };

  const handleClose = (event: Event | React.SyntheticEvent, name: string) => {
    if (columnsVisibilityRef.current && columnsVisibilityRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen((prevOpen) => ({
      ...prevOpen,
      [name]: false
    }));
  };

  const handleListKeyDown = (event: React.KeyboardEvent, name: string) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen((prevOpen) => ({
        ...prevOpen,
        [name]: false
      }));
    } else if (event.key === 'Escape') {
      setOpen((prevOpen) => ({
        ...prevOpen,
        [name]: false
      }));
    }
  };

  const handleOptionsChange = (item: any, index: number) => {
    const newFullColumnDefs = [...props.fullColumnDefs];
    newFullColumnDefs[index] = {
      ...item,
      hide: item.hide !== undefined ? !item.hide : true
    };

    props.setFullColumnDefs?.(newFullColumnDefs);
    props.gridRef.current.api.setColumnDefs(newFullColumnDefs);
  };

  return (
    <HeaderButtons>
      <IconButton
        title="Add column"
        color="default"
        variant="text"
        size="small"
        disabled
      >
        <Icons iconType="plus" size={10} />
      </IconButton>
      <IconButton
        title="Settings"
        color="default"
        variant="text"
        size="small"
        disabled
      >
        <Icons iconType="settings" size={14} />
      </IconButton>
      <IconButton
        ref={columnsVisibilityRef}
        title="More options"
        color="default"
        variant="text"
        size="small"
        onClick={() => handleToggle('columnsVisibility')}
      >
        <Icons iconType="more-option" size={12} />
      </IconButton>
      <StyledPopper
        open={open.columnsVisibility}
        anchorEl={columnsVisibilityRef.current}
        placement="bottom-end"
        transition
        disablePortal
        role={undefined}
        onResize={undefined}
        onResizeCapture={undefined}
        nonce={undefined}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: placement === 'bottom-start' ? 'left top' : 'left bottom' }}
          >
            <StyledBox>
              <ClickAwayListener onClickAway={(event) => handleClose(event, 'columnsVisibility')}>
                <StyledMenuList
                  autoFocusItem={open.columnsVisibility}
                  id="composition-menu"
                  aria-labelledby="composition-button"
                  onKeyDown={(event) => handleListKeyDown(event, 'columnsVisibility')}
                >
                  {
                    props.fullColumnDefs?.map((item: any, index: number) => (
                      <StyledMenuItem key={item.field}>
                        <Switch
                          id={`column_${item.field}`}
                          name={`column_${item.field}`}
                          label={item.headerName}
                          checked={!item.hide}
                          disabled={item.field === 'name'}
                          onChange={() => handleOptionsChange(item, index)}
                        />
                      </StyledMenuItem>
                    ))
                  }
                </StyledMenuList>
              </ClickAwayListener>
            </StyledBox>
          </Grow>
        )}
      </StyledPopper>
    </HeaderButtons>
  );
};

ManagerTableHeaderButtons.defaultProps = defaultProps;

export default ManagerTableHeaderButtons;
