import React, { useState, useEffect, useCallback } from 'react';

import { Option } from 'components/ui/Autocomplete';

import {
  Wrapper,
  JobAutocompleteWrapper,
  StyledJobSelect
} from './DashboardFiltersForm.styles';

export interface DashboardFiltersFormProps {
  chooseJob: (selectedOption: Option | null) => void;
  jobs: any[]; // @TODO add interfaces
  selectedJobId: number | null;
}

const DashboardFiltersForm = (props: DashboardFiltersFormProps): JSX.Element => {
  const [options, setOptions] = useState<Option[]>([]);

  const getOptions = useCallback(() => props.jobs.map((job) => ({
    value: job.jobData?.id?.toString(),
    label: `${job.jobData?.id}. ${job.calcSettingsData?.name || ''}`
  })) || [], [props.jobs]);

  const getSelectedValue = useCallback(
    (id: number | null) => options.filter((el) => el.value === id?.toString())?.[0],
    [options]
  );

  const [selectedOption, setSelectedOption] = useState(getSelectedValue(props.selectedJobId));

  useEffect(() => {
    setOptions(getOptions());
  }, [props.jobs, getOptions]);

  useEffect(() => {
    setSelectedOption(getSelectedValue(props.selectedJobId));
  }, [props.selectedJobId, options, getSelectedValue]);

  // @TODO: add optionsSorted
  return (
    <Wrapper>
      <JobAutocompleteWrapper>
        <StyledJobSelect
          id="job"
          name="job"
          options={options}
          selected={selectedOption}
          onChange={props.chooseJob}
          withMarginBottom={false}
          noFullWidth
        />
      </JobAutocompleteWrapper>
    </Wrapper>
  );
};

export default DashboardFiltersForm;
