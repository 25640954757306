import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import {
  Asset,
  AssetCurve,
  Components,
  IScenarioDetails,
  RootState,
  ScenarioData
} from 'interfaces';

import {
  COMPONENTS_TYPE_IDS,
  METRIC_TYPES_IDS
} from 'constant';

import {
  sendForecasts,
  updateForecasts,
  fetchScenario,
  sendScenario,
  updateScenario,
  fetchForecasts
} from 'api';

import {
  createMonthData,
  getAssetName,
  getUniqueComponents,
  getUniqueComponentsWithMinMaxPair,
  changeNumbersToStrings,
  getTableByComponentType,
  getMinMaxLabel, getComponentsFromDataTables
} from 'utils/scenario';

const initialState: ScenarioData = {
  notification: {
    open: false,
    message: null,
    type: undefined
  },
  forecastSerialID: null,
  scenarioDetails: {
    tagID: '',
    name: null,
    description: null,
    scenarioType: null,
    scenarioDate: new Date().toISOString(),
    tags: [],
    isValid: false
  },
  returnTableData: [],
  returnRangeTableData: [],
  volatilityTableData: [],
  volatilityRangeTableData: [],
  initialValues: {
    id: null,
    name: null,
    description: null,
    scenarioType: null,
    tags: [],
    scenarioDate: new Date().toISOString(),
    addTags: null
  },
  loading: false
};

export const addOrUpdateScenario = createAsyncThunk<any, {
  scenarioId: number | null, forecastSerialID: number | null, scenarioDetails: IScenarioDetails, returnTableData: any, history: any
}, { state: RootState }>(
  'createScenario/addOrUpdateScenario',
  async ({
    scenarioId, forecastSerialID, scenarioDetails, returnTableData, history
  }, { rejectWithValue, getState }) => {
    const { assetsPayloads, assetsCurves } = getState().assets;

    const components: any[] = getComponentsFromDataTables(assetsPayloads, assetsCurves, returnTableData);

    const dataToSend: any = {
      tagID: scenarioDetails.tagID,
      forecasterID: 0,
      name: scenarioDetails.name,
      components
    };

    if (!forecastSerialID) {
      dataToSend.forecastDate = scenarioDetails.scenarioDate;
    }

    const successfullyAddOrUpdate = (result: any) => {
      const scenarioData = {
        name: scenarioDetails.name,
        description: scenarioDetails.description,
        scenarioDate: scenarioDetails.scenarioDate,
        themeType: Number(scenarioDetails.scenarioType),
        visibility: 1,
        tagID: scenarioDetails.tagID,
        permissions: [{ permittedClientSerialID: 0, permissions: 0 }],
        components: [{
          forecastSerialID: forecastSerialID || result,
          forecastWeight: 1
        }],
        hashTags: scenarioDetails.tags
      };

      return !scenarioId
        ? sendScenario(scenarioData)
          .then((payload: any) => payload)
          .then(() => {
            history?.push('/scenario-manager');
          })
          .catch(() => rejectWithValue('Failed to create scenario'))
        : updateScenario(scenarioId, scenarioData)
          .then((payload: any) => payload)
          .then(() => {
            history?.push('/scenario-manager');
          })
          .catch(() => rejectWithValue('Failed to update scenario'));
    };

    return !scenarioId
      ? sendForecasts(dataToSend)
        .then((results: any) => successfullyAddOrUpdate(results))
        .catch(() => rejectWithValue('Failed to create forecast'))
      : updateForecasts(forecastSerialID, dataToSend)
        .then((results: any) => successfullyAddOrUpdate(results))
        .catch(() => rejectWithValue('Failed to update forecast'));
  }
);

export const getScenario = createAsyncThunk<{
  assetsPayloads: Asset[], assetsCurves: AssetCurve[], scenario: any, forecast: any
}, { scenarioId: number }, { state: RootState }>(
  'createScenario/getScenario',
  async ({ scenarioId }: any, { getState }: any) => fetchScenario(scenarioId)
    .then((scenarioResponse: any) => {
      const forecastId = scenarioResponse.components[0].forecastSerialID;

      return fetchForecasts(forecastId)
        .then((forecastResponse: any) => {
          const { assetsPayloads, assetsCurves } = getState().assets;

          return {
            assetsPayloads,
            assetsCurves,
            scenario: scenarioResponse,
            forecast: forecastResponse
          };
        });
    })
);

const createScenario = createSlice({
  name: 'createScenario',
  initialState,
  reducers: {
    resetState (state) {
      return {
        ...state,
        notification: initialState.notification,
        forecastSerialID: initialState.forecastSerialID,
        scenarioDetails: initialState.scenarioDetails,
        returnTableData: initialState.returnTableData,
        returnRangeTableData: initialState.returnRangeTableData,
        volatilityTableData: initialState.volatilityTableData,
        volatilityRangeTableData: initialState.volatilityRangeTableData,
        initialValues: initialState.initialValues
      };
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(addOrUpdateScenario.fulfilled, (state, action) => ({
        ...state,
        notification: {
          open: true,
          message: 'Successfully created scenario',
          type: 'success'
        }
      }))
      .addCase(addOrUpdateScenario.rejected, (state, action) => ({
        ...state,
        notification: {
          open: true,
          message: action.payload as string,
          type: 'error'
        }
      }))
      .addCase(getScenario.pending, (state, action) => ({
        ...state,
        loading: true
      }))
      .addCase(getScenario.fulfilled, (state, action) => {
        const { assetsPayloads, assetsCurves, scenario, forecast } = action.payload;

        const components: any[] = [];

        forecast?.components.forEach((el: Components, index: number) => {
          components.push({
            id: index + 1,
            minMaxLabel: getMinMaxLabel(el.componentType),
            componentType: el.componentType,
            metricType: el.metricType,
            assetId: el.singleIndexID,
            assetName: getAssetName(assetsPayloads, el.singleIndexID),
            ...createMonthData(assetsCurves, el.targetDate, el.value, el.singleIndexID, el.metricType, true)
          });
        });

        const uniqueComponents: any[] = getUniqueComponents(assetsCurves, components, true);

        const uniqueComponentsWithMaxMinPair: any[] = getUniqueComponentsWithMinMaxPair(
          uniqueComponents,
          assetsCurves
        );

        const uniqueComponentsAsStrings = changeNumbersToStrings(uniqueComponentsWithMaxMinPair);

        return {
          ...state,
          forecastSerialID: scenario.components[0].forecastSerialID,
          initialValues: {
            id: scenario.id,
            name: scenario.name,
            description: scenario.description,
            scenarioDate: scenario.scenarioDate,
            scenarioType: scenario.themeType.toString(),
            addedTags: null,
            tags: scenario.hashTags.map((el: any) => el.tag)
          },
          scenarioDetails: {
            id: scenario.id,
            name: scenario.name,
            description: scenario.description,
            tagID: scenario.tagID,
            scenarioDate: scenario.scenarioDate,
            scenarioType: scenario.themeType.toString(),
            tags: scenario.hashTags,
            isValid: true
          },
          returnTableData: getTableByComponentType(
            uniqueComponentsAsStrings,
            [COMPONENTS_TYPE_IDS.point],
            METRIC_TYPES_IDS.price
          ),
          returnRangeTableData: getTableByComponentType(
            uniqueComponentsAsStrings,
            [COMPONENTS_TYPE_IDS.min, COMPONENTS_TYPE_IDS.max],
            METRIC_TYPES_IDS.price
          ),
          volatilityTableData: getTableByComponentType(
            uniqueComponentsAsStrings,
            [COMPONENTS_TYPE_IDS.point],
            METRIC_TYPES_IDS.volatility
          ),
          volatilityRangeTableData: getTableByComponentType(
            uniqueComponentsAsStrings,
            [COMPONENTS_TYPE_IDS.min, COMPONENTS_TYPE_IDS.max],
            METRIC_TYPES_IDS.volatility
          ),
          loading: false
        };
      })
      .addCase(getScenario.rejected, (state, action) => ({
        ...state,
        loading: false
      }));
  }
});

export const { resetState } = createScenario.actions;
export default createScenario.reducer;
