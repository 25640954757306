import React from 'react';

import { PortfolioDetails } from 'interfaces';

import { formatDate } from 'utils/formatDate';

import ExternalLabel from 'components/ui/ExternalLabel';
import Tile from 'components/ui/Tile';
import TileWrapper from 'components/ui/TileWrapper';
import Typography from 'components/ui/Typography';

export interface PortfolioViewDetailsProps {
  portfolioDetails: PortfolioDetails;
}

const PortfolioViewDetails = (props: PortfolioViewDetailsProps): JSX.Element => (
  <TileWrapper>
    <Tile size="medium" noStyle>
      <TileWrapper>
        <Tile size="none" fullWidth>
          <ExternalLabel>Scenario Name</ExternalLabel>
          <Typography color="secondary" size="superBig">
            {props.portfolioDetails.name}
          </Typography>
        </Tile>
        <Tile size="none">
          <ExternalLabel>Portfolio Creation Date</ExternalLabel>
          <Typography color="secondary" size="big">
            {formatDate(props.portfolioDetails.creationDate)}
          </Typography>
        </Tile>
        <Tile size="none">
          <ExternalLabel>Portfolio Date</ExternalLabel>
          <Typography color="secondary" size="big">
            {formatDate(props.portfolioDetails.referenceDate)}
          </Typography>
        </Tile>
        <Tile size="none">
          <ExternalLabel>Currency</ExternalLabel>
          <Typography color="secondary" size="big">
            {props.portfolioDetails.currency || '-'}
          </Typography>
        </Tile>
        <Tile size="none">
          <ExternalLabel>Geography</ExternalLabel>
          <Typography color="secondary" size="big">
            Global
          </Typography>
        </Tile>
      </TileWrapper>
    </Tile>
    <Tile>
      <ExternalLabel>Portfolio description</ExternalLabel>
      <Typography color="secondary" size="big">
        {props.portfolioDetails.description || '-'}
      </Typography>
    </Tile>
  </TileWrapper>
);

export default PortfolioViewDetails;
