import React, { useState, useEffect } from 'react';
import { Form, Formik, FormikProps } from 'formik';
import _isEmpty from 'lodash/isEmpty';
import { Close } from '@mui/icons-material';

import { IScenarioDetails, ScenarioDetailsForm } from 'interfaces';
import { SCENARIO_TYPES_OPTIONS, KEY_CODE_ENTER } from 'constant';
import { scenarioDetailsValidationSchema } from 'utils/validationScemas';

import FormHeading from 'components/ui/FormHeading';
import DroppableInner from 'components/ui/Box';
import FormColumns from 'components/ui/FormColumns';
import FormColumn from 'components/ui/FormColumn';

import TextField from 'components/ui/TextField';
import DatePicker from 'components/ui/DatePicker';
import Autocomplete, { Option } from 'components/ui/Autocomplete';
import Chip from 'components/ui/Chip';

import {
  ChipsWrapper
} from './ScenarioDetails.styles';

export interface ScenarioDetailsProps {
  scenarioDetails: IScenarioDetails;
  setScenarioDetails: (data: any) => void;
  initialValues: ScenarioDetailsForm;
}

const ScenarioDetails = (props: ScenarioDetailsProps): JSX.Element => {
  const [tags, setTags] = useState<string[]>([]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updateScenarioDetails = (fieldName: string, value: any, isValid?: boolean) => {
    props.setScenarioDetails({
      ...props.scenarioDetails,
      [fieldName]: value,
      isValid
    });
  };

  const handleChange = (fieldName: string, value: any, formikProps: FormikProps<ScenarioDetailsForm>, shouldValidate = true) => {
    formikProps.setFieldValue(fieldName, value, shouldValidate);

    updateScenarioDetails(fieldName, value, _isEmpty(formikProps.errors));
  };

  const handleBlur = (event: any, formikProps: FormikProps<ScenarioDetailsForm>) => {
    formikProps.handleBlur(event);
    props.setScenarioDetails({
      ...props.scenarioDetails,
      isValid: _isEmpty(formikProps.errors)
    });
  };

  const updateTags = (inputValue: string, formikProps: FormikProps<ScenarioDetailsForm> | null) => {
    const newTags = inputValue.split(',').map((el) => el.trim());

    const uniqueTags = [...tags, ...newTags].reduce((acc: string[], tag: string) => {
      const array = [...acc];
      if (array.indexOf(tag) === -1) {
        array.push(tag);
      }
      return array;
    }, []);

    setTags(uniqueTags);

    const scenarioDetailsTags = uniqueTags.map((el: string) => ({ tag: el }));

    updateScenarioDetails(
      'tags',
      scenarioDetailsTags,
      formikProps ? _isEmpty(formikProps.errors) : props.scenarioDetails.isValid
    );
  };

  const handleTagDelete = (tag: any, formikProps: FormikProps<ScenarioDetailsForm>) => {
    const newTags = tags.filter((el: string) => el !== tag);

    const scenarioDetailsTags = newTags.map((el: string) => ({ tag: el }));

    setTags(newTags);
    updateScenarioDetails('tags', scenarioDetailsTags, _isEmpty(formikProps.errors));
  };

  useEffect(() => {
    if (props.initialValues.tags?.length) {
      updateTags(props.initialValues.tags?.join(','), null);
    } else {
      setTags(props.initialValues.tags as string[]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.initialValues]);

  return (
    <React.Fragment>
      <FormHeading withBorder={false}>
        Scenario Details
      </FormHeading>
      <DroppableInner>
        <Formik
          enableReinitialize
          validationSchema={scenarioDetailsValidationSchema}
          initialValues={props.initialValues}
          onSubmit={(values, { resetForm }) => {
            resetForm();
          }}
        >
          {(formikProps: FormikProps<ScenarioDetailsForm>) => (
            <Form onSubmit={formikProps.handleSubmit} noValidate>
              <FormColumns>
                <FormColumn even={false}>
                  <TextField
                    withExternalLabel
                    required
                    disabled={!!props.initialValues.id}
                    id="name"
                    name="name"
                    onChange={(event) => {
                      handleChange('name', event.target.value, formikProps);
                    }}
                    label="Scenario name"
                    placeholder="Scenario No. 1"
                    value={formikProps.values && formikProps.values.name}
                    error={
                      Boolean(formikProps.touched.name)
                      && Boolean(formikProps.errors.name)
                    }
                    helperText={
                      formikProps.touched.name ? formikProps.errors.name : null
                    }
                    onBlur={(event) => handleBlur(event, formikProps)}
                  />
                  <FormColumns>
                    <FormColumn>
                      <Autocomplete
                        required
                        withExternalLabel
                        id="scenarioType"
                        name="scenarioType"
                        placeholder="Choose an option"
                        label="Scenario type"
                        optionsSorted={false}
                        options={SCENARIO_TYPES_OPTIONS}
                        handleChange={(selectedOption: Option | null) => {
                          handleChange('scenarioType', selectedOption?.value, formikProps);
                        }}
                        value={formikProps.values && formikProps.values.scenarioType}
                        error={
                          Boolean(formikProps.touched.scenarioType)
                          && Boolean(formikProps.errors.scenarioType)
                        }
                        helperText={
                          formikProps.touched.scenarioType ? formikProps.errors.scenarioType : null
                        }
                        onBlur={(event: any) => handleBlur(event, formikProps)}
                      />
                    </FormColumn>
                    <FormColumn>
                      <DatePicker
                        required
                        disabled={!!props.initialValues.id}
                        withExternalLabel
                        id="scenarioDate"
                        name="scenarioDate"
                        label="Scenario date"
                        placeholder="mm/yyyy"
                        onChange={(value: string) => {
                          handleChange('scenarioDate', value, formikProps);
                        }}
                        setFieldTouched={formikProps.setFieldTouched}
                        value={formikProps.values && formikProps.values.scenarioDate}
                        error={
                          Boolean(formikProps.touched.scenarioDate)
                          && Boolean(formikProps.errors.scenarioDate)
                        }
                        helperText={formikProps.touched.scenarioDate ? formikProps.errors.scenarioDate : null}
                        onBlur={(event: any) => handleBlur(event, formikProps)}
                      />
                    </FormColumn>
                  </FormColumns>
                  <TextField
                    withExternalLabel
                    required
                    withMarginBottom={false}
                    id="addTags"
                    name="addTags"
                    onChange={(event) => {
                      handleChange('addTags', event.target.value, formikProps);
                    }}
                    onKeyDown={(event: any) => {
                      event.stopPropagation();

                      if (event.code === KEY_CODE_ENTER) {
                        handleChange('addTags', '', formikProps);
                        updateTags(event.target.value, formikProps);
                      }
                    }}
                    label="Tags"
                    placeholder="Add tags with enter"
                    value={formikProps.values && formikProps.values.addTags}
                    error={
                      Boolean(formikProps.touched.addTags)
                      && Boolean(formikProps.errors.addTags)
                    }
                    helperText={
                      formikProps.touched.addTags ? formikProps.errors.addTags : null
                    }
                    onBlur={(event: any) => handleBlur(event, formikProps)}
                  />
                  {
                    tags.length ? (
                      <ChipsWrapper>
                        {
                          tags.map((tag: string) => (
                            <Chip
                              key={tag}
                              label={tag}
                              onDelete={(event) => {
                                event.stopPropagation();
                                handleTagDelete(tag, formikProps);
                              }}
                              clickable={false}
                              deleteIcon={(<Close />)}
                            />
                          ))
                        }
                      </ChipsWrapper>
                    ) : null
                  }
                </FormColumn>
                <FormColumn even={false}>
                  <TextField
                    multiline
                    minRows={12}
                    withExternalLabel
                    withMarginBottom={false}
                    required
                    id="description"
                    name="description"
                    onChange={(event) => {
                      handleChange('description', event.target.value, formikProps);
                    }}
                    label="Scenario description"
                    value={formikProps.values && formikProps.values.description}
                    error={
                      Boolean(formikProps.touched.description)
                      && Boolean(formikProps.errors.description)
                    }
                    helperText={
                      formikProps.touched.description ? formikProps.errors.description : null
                    }
                    onBlur={(event: any) => handleBlur(event, formikProps)}
                    maxLength={1024}
                    showMaxLengthInfo
                  />
                </FormColumn>
              </FormColumns>
            </Form>
          )}
        </Formik>
      </DroppableInner>
    </React.Fragment>
  );
};

export default ScenarioDetails;
