import React from 'react';

import { useTheme } from '@emotion/react';

import Typography from '@mui/material/Typography';
import Slider from '@mui/material/Slider';

import {
  Wrapper
} from './RatingBar.styles';

const RatingBar = (): JSX.Element => {
  const theme = useTheme();

  const valueLabelFormat = (value: number) => {
    const units = [''];

    let unitIndex = 0;
    let scaledValue = value;

    while (scaledValue >= 1024 && unitIndex < units.length - 1) {
      unitIndex += 1;
      scaledValue /= 1024;
    }

    return `${scaledValue} ${units[unitIndex]}`;
  };

  const calculateValue = (value: number) => value;

  const [value, setValue] = React.useState<number>(4);

  const handleChange = (event: Event, newValue: number | number[]) => {
    if (typeof newValue === 'number') {
      setValue(newValue);
    }
  };

  return (
    <Wrapper>
      <Typography
        id="non-linear-slider"
        sx={{
          padding: '2px 9px',
          margin: '0 12px 10px 0',
          backgroundColor: theme.color.secondary,
          color: 'white',
          borderRadius: '7px',
          fontSize: '14px'
        }}
      >
        {valueLabelFormat(calculateValue(value))}
      </Typography>{' '}
      <Slider
        value={value}
        min={1}
        step={1}
        size="small"
        max={5}
        scale={calculateValue}
        getAriaValueText={valueLabelFormat}
        valueLabelFormat={valueLabelFormat}
        onChange={handleChange}
        valueLabelDisplay="auto"
        aria-labelledby="non-linear-slider"
        sx={{ marginTop: '3px', marginRight: '2px' }}
      />
    </Wrapper>
  );
};

export default RatingBar;
