import React, {
  useCallback, useEffect, useRef, useState
} from 'react';

import { IHeaderParams } from 'ag-grid-community';

import { ENTER_KAY_NAME, MANAGER_TABLE_HEADER_SORTING_STATES } from 'constant';

import Icons from 'components/ui/Icons';

import {
  HeaderWrapper,
  TableOptionsWrapper,
  TableOptionsBox,
  TableOptionsButton
} from './ManagerTableCustomHeader.styles';

export interface CustomHeaderParams extends IHeaderParams {
  className?: string;
}
const defaultProps = {
  className: ''
};

const ManagerTableCustomHeader = (props: CustomHeaderParams) => {
  const [ascSort, setAscSort] = useState(MANAGER_TABLE_HEADER_SORTING_STATES.inactive);
  const [descSort, setDescSort] = useState(MANAGER_TABLE_HEADER_SORTING_STATES.inactive);
  const [noSort, setNoSort] = useState(MANAGER_TABLE_HEADER_SORTING_STATES.inactive);
  const [isEditMode, setIsEditMode] = useState(false);
  const [headerName, setHeaderName] = useState(props.displayName);

  const refButton = useRef(null);

  const onMenuClicked = () => {
    props.showColumnMenu(refButton.current!);
  };

  const onSortChanged = useCallback(() => {
    setAscSort(props.column.isSortAscending()
      ? MANAGER_TABLE_HEADER_SORTING_STATES.active
      : MANAGER_TABLE_HEADER_SORTING_STATES.inactive);
    setDescSort(props.column.isSortDescending()
      ? MANAGER_TABLE_HEADER_SORTING_STATES.active
      : MANAGER_TABLE_HEADER_SORTING_STATES.inactive);
    setNoSort(!props.column.isSortAscending() && !props.column.isSortDescending()
      ? MANAGER_TABLE_HEADER_SORTING_STATES.active
      : MANAGER_TABLE_HEADER_SORTING_STATES.inactive
    );
  }, [props.column]);

  const onSortRequested = (order: 'asc' | 'desc' | null, event: any) => {
    props.setSort(order, event.shiftKey);
  };

  useEffect(() => {
    props.column.addEventListener('sortChanged', onSortChanged);
    onSortChanged();
  }, [onSortChanged, props.column]);

  let menu = null;
  if (props.enableMenu) {
    menu = (
      <TableOptionsBox>
        <TableOptionsButton
          ref={refButton}
          className="custom-header-menu-button"
          onClick={() => onMenuClicked()}
        >
          <Icons iconType="more-option" size={14} />
        </TableOptionsButton>
      </TableOptionsBox>
    );
  }

  const handleKeyPress = (event: any) => {
    if (event.key === ENTER_KAY_NAME) {
      setIsEditMode(false);

      const colDef = props.column.getColDef();
      colDef.headerName = headerName;

      props.api.refreshHeader();
    }
  };

  let sort = null;
  if (props.enableSorting) {
    sort = (
      <TableOptionsBox>
        {
          ascSort === MANAGER_TABLE_HEADER_SORTING_STATES.inactive && descSort === MANAGER_TABLE_HEADER_SORTING_STATES.inactive ? (
            <TableOptionsButton
              onClick={(event) => onSortRequested('asc', event)}
              onTouchEnd={(event) => onSortRequested('asc', event)}
              className={`custom-sort-down-label ${ascSort}`}
            >
              <Icons iconType="chevron-down" size={7} />
            </TableOptionsButton>
          ) : null
        }
        {
          descSort === MANAGER_TABLE_HEADER_SORTING_STATES.inactive && ascSort === MANAGER_TABLE_HEADER_SORTING_STATES.active ? (
            <TableOptionsButton
              onClick={(event) => onSortRequested('desc', event)}
              onTouchEnd={(event) => onSortRequested('desc', event)}
              className={`custom-sort-up-label ${descSort}`}
            >
              <Icons iconType="chevron-up" size={7} />
            </TableOptionsButton>
          ) : null
        }
        {
          descSort !== MANAGER_TABLE_HEADER_SORTING_STATES.inactive ? (
            <TableOptionsButton
              onClick={(event) => onSortRequested(null, event)}
              onTouchEnd={(event) => onSortRequested(null, event)}
              className={`custom-sort-down-label ${noSort}`}
            >
              <Icons iconType="chevron-down" size={7} />
            </TableOptionsButton>
          ) : null
        }
      </TableOptionsBox>
    );
  }

  return (
    <HeaderWrapper className={props.className}>
      {
        isEditMode ? (
          <input
            type="text"
            value={headerName}
            onChange={(event) => setHeaderName(event.target.value)}
            onKeyUp={(event) => handleKeyPress(event)}
          />
        ) : null
      }
      {
        !isEditMode ? (
          <div
            className="custom-header-label"
            onDoubleClick={() => setIsEditMode(!isEditMode)}
          >
            {props.displayName}
          </div>
        ) : null
      }
      <TableOptionsWrapper className="table-header-options">
        {sort}
        {menu}
      </TableOptionsWrapper>
    </HeaderWrapper>
  );
};

ManagerTableCustomHeader.defaultProps = defaultProps;

export default ManagerTableCustomHeader;
