import React from 'react';

import {
  Wrapper
} from './FormColumn.styles';

export interface FormColumnProps {
  children?: React.ReactNode;
  className?: string;
  even?: boolean;
}
const defaultProps = {
  className: '',
  even: true
};

const FormColumn = (props: FormColumnProps): JSX.Element => (
  <Wrapper even={props.even} className={props.className}>
    {props.children}
  </Wrapper>
);

FormColumn.defaultProps = defaultProps;

export default FormColumn;
