import styled from '@emotion/styled';
import { css } from '@emotion/react';

interface WrapperProps {
  small: boolean | undefined;
}

const Wrapper = styled.div<WrapperProps>`
  //max-width: 1600px;
  margin: 0 auto;
  padding: 0 ${(props) => props.theme.layout.padding}px;

  ${(props) => props.small && css`
    max-width: calc(356px + (${props.theme.layout.padding}px * 2));
  `};
`;

export {
  Wrapper
};
