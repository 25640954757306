import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Formik, FormikProps } from 'formik';
import _isEmpty from 'lodash/isEmpty';

import {
  AppDispatch,
  RootState,
  AssetForForm,
  AssetsFilters,
  PortfolioDetails,
  Notification
} from 'interfaces';

import { FILTERS_NAMES } from 'constant';

import { findUnique } from 'utils/findUnique';
import { portfolioValidationSchema } from 'utils/validationScemas';

import Loader from 'components/ui/Loader';
import Notifications from 'components/ui/Notifications';

import Layout from 'components/Layout';
import PortfolioDetailsForm from 'components/PortfolioDetailsForm';
import PortfolioAssetsForm from 'components/PortfolioAssetsForm';
import ReviewPortfolio from 'components/ReviewPortfolio';

import { getAssets } from 'features/assets/assetsSlice';
import { addOrUpdatePortfolio, getPortfolio, resetState } from './createPortfolioSlice';

const CreatePortfolio = (): JSX.Element => {
  const history = useHistory();
  const { portfolioId }: any = useParams();

  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    dispatch(resetState());
  }, [dispatch, portfolioId]);

  useEffect(() => {
    dispatch(getAssets());
  }, [dispatch]);

  const assetsPayloads = useSelector((state: RootState) => state.assets.assetsPayloads);

  useEffect(() => {
    if (portfolioId && assetsPayloads.length) {
      // @ts-ignore
      dispatch(getPortfolio({ portfolioId }));
    }
  }, [dispatch, portfolioId, assetsPayloads]);

  const portfolioDetails = useSelector((state: RootState) => state.createPortfolio.portfolioDetails);
  const notificationMsg = useSelector((state: RootState) => state.createPortfolio.notification);

  const loading = useSelector((state: RootState) => state.createPortfolio.loading);
  const assetsLoading = useSelector((state: RootState) => state.assets.loading);

  const [notification, setNotification] = React.useState<Notification>(notificationMsg);
  const [assetsDictionaries, setAssetsDictionaries] = useState<AssetsFilters>({
    [FILTERS_NAMES.name]: [],
    [FILTERS_NAMES.assetClass]: [],
    [FILTERS_NAMES.region]: [],
    [FILTERS_NAMES.priceCurrency]: []
  });

  useEffect(() => {
    setAssetsDictionaries({
      [FILTERS_NAMES.name]: assetsPayloads,
      [FILTERS_NAMES.assetClass]: findUnique(assetsPayloads, FILTERS_NAMES.assetClass),
      [FILTERS_NAMES.region]: findUnique(assetsPayloads, FILTERS_NAMES.region),
      [FILTERS_NAMES.priceCurrency]: findUnique(
        assetsPayloads,
        FILTERS_NAMES.priceCurrency
      )
    });
  }, [assetsPayloads]);

  useEffect(() => {
    setNotification(notificationMsg);
  }, [notificationMsg]);

  const validateAssetsWeight = (formikProps: any) => {
    const weights = formikProps.values?.holdings.map((el: AssetForForm) => Number(el.quantity));
    const sum = weights.reduce((a: number, b: number) => a + b, 0);

    return sum === 100;
  };

  return (
    <Layout>
      <Formik
        enableReinitialize
        validationSchema={portfolioValidationSchema}
        initialValues={portfolioDetails}
        onSubmit={(values) => {
          // @ts-ignore
          dispatch(addOrUpdatePortfolio({ portfolioId, portfolioData: values, history }));
        }}
      >
        {(formikProps: FormikProps<PortfolioDetails & { holdings: AssetForForm[] }>) => (
          <Form onSubmit={formikProps.handleSubmit} noValidate>
            <PortfolioDetailsForm
              currencyDictionary={assetsDictionaries.priceCurrency || []}
              initialValues={portfolioDetails}
              formikProps={formikProps}
            />
            <PortfolioAssetsForm
              assetsDictionaries={assetsDictionaries}
              initialValues={portfolioDetails}
              formikProps={formikProps}
            />
            <ReviewPortfolio
              reviewButtonDisabled={!_isEmpty(formikProps.errors) || !validateAssetsWeight(formikProps)}
              submitPortfolio={() => {
                formikProps.submitForm();
              }}
              portfolio={formikProps.values}
            />
          </Form>
        )}
      </Formik>
      {
        loading || assetsLoading ? (
          <Loader loaderType="absolute" semiCovered />
        ) : null
      }
      {
        notification.message && notification.open ? (
          <Notifications
            notification={notification}
            setNotification={setNotification}
          />
        ) : null
      }
    </Layout>
  );
};

export default CreatePortfolio;
