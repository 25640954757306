import React from 'react';

import { Tooltip as MuiTooltip, TooltipProps } from '@mui/material';

const Tooltip = (props: TooltipProps): JSX.Element => (
  <MuiTooltip
    arrow
    placement="top"
    {...props}
  >
    {props.children}
  </MuiTooltip>
);

export default Tooltip;
