import React from 'react';

import { SwitchProps } from '@mui/material';

import {
  StyledSwitch,
  StyledFormControlLabel,
  TypographyStyled
} from './Switch.styles';

export interface ISwitchProps extends SwitchProps {
  className?: string | undefined;
  label?: string | null | undefined;
  labelPlacement?: 'end' | 'start' | 'top' | 'bottom';
}
const defaultProps = {
  className: '',
  label: null,
  labelPlacement: 'end'
};

const Switch = (props: ISwitchProps) => props.label ? (
  <StyledFormControlLabel
    className={props.className}
    control={(
      <StyledSwitch
        {...props}
      />
    )}
    label={(
      <TypographyStyled labelPlacement={props.labelPlacement}>
        {props.label}
      </TypographyStyled>
    )}
    labelPlacement={props.labelPlacement}
  />
) : (
  <StyledSwitch
    {...props}
  />
);

Switch.defaultProps = defaultProps;

export default Switch;

