import styled from '@emotion/styled';

import { hexToRgbMixin } from 'styles/mixins';

import Button from 'components/ui/Button';

const UnSubscribeWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`;

const IconWrapper = styled.div`
  display: flex;
  flex-shrink: 0;
  align-items: center;
  margin-right: 16px;
  padding: 12px 10px 7px;
  background: ${(props) => hexToRgbMixin(props.theme.color.secondary, 0.1)};
  color: ${(props) => props.theme.color.secondary};
`;

// @ts-ignore
const StyledButton = styled(Button)`
  margin-left: auto;
`;

const TagsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  max-width: 137px;

  @media (min-width: 1100px) {
    max-width: 155px;
  }

  @media (min-width: 1500px) {
    max-width: 252px;
  }
`;

export {
  UnSubscribeWrapper,
  IconWrapper,
  StyledButton,
  TagsWrapper
};
