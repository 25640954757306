import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { FormColumnProps } from 'components/ui/FormColumn';

const Wrapper = styled.div<FormColumnProps>`
  display: flex;
  flex: 1 1 calc(50% - (25px / 2));
  flex-direction: column;

  & + & {
    margin-left: 25px;
  }

  ${(props) => !props.even && css`
    &:nth-of-type(1) {
      flex-basis: calc(45% - (25px / 2));
    }
    &:nth-of-type(2) {
      flex-basis: calc(55% - (25px / 2));
    }
  `};
`;

export {
  Wrapper
};
