import * as React from 'react';

import { Stack, Snackbar } from '@mui/material';

import { Notification } from 'interfaces';

import {
  StyledAlert
} from './Notifications.styles';

const AUTO_HIDE_DURATION = 6000;
const ELEVATION = 6;

interface NotificationsProps {
  notification: Notification;
  setNotification: React.Dispatch<Notification>;
}

const Notifications = (props: NotificationsProps): JSX.Element => {
  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    props.setNotification({
      ...props.notification,
      open: false
    });
  };

  return (
    <Stack>
      <Snackbar
        open={props.notification.open}
        autoHideDuration={AUTO_HIDE_DURATION}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <StyledAlert
          onClose={handleClose}
          severity={props.notification.type}
          elevation={ELEVATION}
          variant="filled"
        >
          {props.notification.message}
        </StyledAlert>
      </Snackbar>
    </Stack>
  );
};

export default Notifications;
