import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AppDispatch, RootState, DashboardDataSet } from 'interfaces';

import { DASHBOARD_ANALYTICS_DATA_SET_TYPES } from 'constant';

import Loader from 'components/ui/Loader';

import Layout from 'components/Layout';
import DashboardFiltersForm from 'components/DashboardFiltersForm';
import DashboardAnalyticsTable from 'components/DashboardAnalyticsTable';

import { getAssets } from 'features/assets/assetsSlice';

import { getCalcSettingsIds, getSelectedSimData } from './dashboardAnalyticsSlice';

export interface DashboardAnalyticsProps {
  dataSet?: DashboardDataSet;
}
const defaultProps = {
  dataSet: DASHBOARD_ANALYTICS_DATA_SET_TYPES.summary
};

const DashboardAnalytics = (props: DashboardAnalyticsProps): JSX.Element => {
  const [selectedJobId, setSelectedJobId] = useState<number | null>(null);

  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    dispatch(getAssets());
    dispatch(getCalcSettingsIds());
  }, [dispatch]);

  const jobs = useSelector((state: RootState) => state.dashboardAnalytics.jobs);
  const simData = useSelector((state: RootState) => state.dashboardAnalytics.simData);
  const loading = useSelector((state: RootState) => state.dashboardAnalytics.loading);

  useEffect(() => {
    setSelectedJobId(jobs[0]?.jobData.id);
  }, [jobs, props.dataSet]);

  useEffect(() => {
    if (selectedJobId) {
      dispatch(getSelectedSimData({ selectedJobId, dataSet: props.dataSet }));
    }
  }, [dispatch, selectedJobId, props.dataSet]);

  const errorMsg = !loading ? 'There is no table data for this Job' : null;

  return (
    <Layout withPageButtons={false}>
      <DashboardFiltersForm
        jobs={jobs}
        chooseJob={(selectedOption) => {
          setSelectedJobId(Number(selectedOption?.value));
        }}
        selectedJobId={selectedJobId}
      />
      {
        loading ? <Loader loaderType="static" /> : null
      }
      {
        !loading && simData ? (
          <DashboardAnalyticsTable
            dataSet={props.dataSet}
            analyticsRows={simData}
            // @TODO: as variable - this is a value of select field and margin underneath
            additionalValueToHeightSubtraction={68}
          />
        ) : errorMsg
      }
    </Layout>
  );
};

DashboardAnalytics.defaultProps = defaultProps;

export default DashboardAnalytics;
