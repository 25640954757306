import { JOB_DEFAULT_PAGE_SIZE } from 'constant';

import { fetchCalcSetting, fetchJobs } from 'api';

export const getCalcSettingsIdsGeneralFunction = () => fetchJobs(JOB_DEFAULT_PAGE_SIZE)
  .then((jobs: any) => {
    const calcSettingsIds = jobs.reduce((acc: any[], current: any) => {
      if (acc.findIndex((el: number) => el === current.simulatorCalcSerialID) === -1) {
        acc.push(current.simulatorCalcSerialID);
      }
      return acc;
    }, []);

    const calcSettingsIdsResponses = calcSettingsIds
      .map((el: any) => fetchCalcSetting(el));

    return Promise.allSettled([
      ...calcSettingsIdsResponses
    ])
      .then((results) => {
        const correctCalcSettingsData = results
          .filter((el: any) => el.status === 'fulfilled')
          .map((el: any) => el?.value);

        const newJobs: any[] = [];

        jobs.forEach((jobElement: any) => {
          const calcSettings = correctCalcSettingsData
            .filter((calcElement) => calcElement.id === jobElement.simulatorCalcSerialID);

          if (calcSettings.length > 0) {
            newJobs.push({
              jobData: jobElement,
              calcSettingsData: calcSettings[0]
            });
          }
        });

        return {
          jobs: newJobs
        };
      });
  });
