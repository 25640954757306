import styled from '@emotion/styled';

import JobTile from 'components/ui/JobTile';

const TabWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: stretch;
  padding: 16px 0;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    border-left: 1px solid #e3e3e3; // @TODO: theme
  }
`;

const DraggableElementsWrapper = styled.div`
  flex: 1 1 50%;
  height: 285px;
  padding: 0 10px 0 16px;
`;

// @ts-ignore
const StyledJobTile = styled(JobTile)`
  margin: 0;
`;

export {
  TabWrapper,
  DraggableElementsWrapper,
  StyledJobTile
};

