import React from 'react';

import {
  Wrapper,
  AdditionalTextWrapper,
  AdditionalComponentWrapper
} from './FormHeading.styles';

export interface FormHeadingProps {
  children: React.ReactNode;
  additionalText?: string | null;
  additionalComponent?: React.ReactNode | null;
  withBorder?: boolean;
  withPadding?: boolean;
  id?: string;
}
const defaultProps = {
  withBorder: true,
  withPadding: true,
  additionalText: null,
  additionalComponent: null,
  id: null
};

const FormHeading = (props: FormHeadingProps): JSX.Element => (
  <Wrapper
    id={props.id}
    withBorder={props.withBorder as boolean}
    withPadding={props.withPadding as boolean}
  >
    {props.children}
    {
      props.additionalText ? (
        <AdditionalTextWrapper>
          {props.additionalText}
        </AdditionalTextWrapper>
      ) : null
    }
    {
      props.additionalComponent ? (
        <AdditionalComponentWrapper>
          {props.additionalComponent}
        </AdditionalComponentWrapper>
      ) : null
    }
  </Wrapper>
);

FormHeading.defaultProps = defaultProps;

export default FormHeading;
