import { AssetCurve, AssetForwardCurve, MetricType, ValueType } from 'interfaces';

import { METRIC_TYPES_IDS, VALUE_TYPE } from 'constant';

export const getAssetValue = (
  assetsCurves: AssetCurve[],
  metricType: MetricType | null,
  labelId: number,
  id: string
): number | null => {
  const asset: AssetCurve = assetsCurves?.filter((el: AssetCurve) => el.id === id)[0];
  const assetByTenor = asset?.forwardCurve?.filter(
    (el: AssetForwardCurve) => el.tenor === labelId
  )?.[0];
  const valueType: ValueType = metricType === METRIC_TYPES_IDS.volatility
    ? VALUE_TYPE.volatility
    : VALUE_TYPE.return;
  const returnValue: number = assetByTenor?.[valueType] * 100 || 0;

  return Number(Number(returnValue).toFixed(2));
};
