import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import {
  DashboardTable,
  DashboardDataSet,
  RootState,
  AnalyticsPortfolioData,
  ObjectName,
  Asset,
  PortfolioData
} from 'interfaces';

import { DASHBOARD_ANALYTICS_DATA_SET_TYPES, OBJECT_NAME_TYPES } from 'constant';

import { fetchSimData } from 'api';

import { getCalcSettingsIdsGeneralFunction } from 'utils/dashboard';

import { getData } from './utils';

const initialState: DashboardTable = {
  jobs: [],
  simData: null,
  loading: true
};

export const getCalcSettingsIds = createAsyncThunk(
  'dashboardAnalytics/getJobs',
  async () => getCalcSettingsIdsGeneralFunction()
);

export const getSelectedSimData = createAsyncThunk<{
  data: PortfolioData, dataSet: DashboardDataSet, assetsPayloads: Asset[]
}, {
  selectedJobId: number, dataSet: DashboardDataSet
}, { state: RootState }>(
  'dashboardAnalytics/getSelectedJobsChartSimData',
  async (
    { selectedJobId, dataSet },
    { getState }
  ) => fetchSimData(selectedJobId, dataSet)
    .then((response: PortfolioData) => {
      const { assetsPayloads } = getState().assets;

      return {
        data: response,
        dataSet,
        assetsPayloads
      };
    })
);

const dashboardAnalytics = createSlice({
  name: 'createScenario',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCalcSettingsIds.fulfilled, (state, action) => {
        const { jobs } = action.payload;
        return {
          ...state,
          jobs
        };
      })
      .addCase(getSelectedSimData.pending, (state) => ({
        ...state,
        loading: true
      }))
      .addCase(getSelectedSimData.fulfilled, (state, action) => {
        const { dataSet, assetsPayloads } = action.payload;
        const portfolioData = action.payload?.data?.portfolioData;
        const newData: AnalyticsPortfolioData[] = [];

        Object
          .keys(portfolioData)
          .forEach((portfolioId: string) => Object
            .keys(portfolioData[portfolioId].scenarioData)
            .forEach((scenarioId: string) => Object
              .keys(portfolioData[portfolioId].scenarioData[scenarioId].timePeriodData)
              .forEach((period: string) => {
                if (dataSet !== DASHBOARD_ANALYTICS_DATA_SET_TYPES.summary) {
                  const objectName: ObjectName = dataSet === DASHBOARD_ANALYTICS_DATA_SET_TYPES.assetAttribute
                    ? OBJECT_NAME_TYPES.assetAttribData
                    : OBJECT_NAME_TYPES.assetStatsData;

                  Object
                    .keys(portfolioData[portfolioId].scenarioData[scenarioId]
                      .timePeriodData[period][objectName]
                    )
                    .forEach((attribute: string) => {
                      newData.push(getData(
                        portfolioData,
                        portfolioId,
                        scenarioId,
                        period,
                        dataSet,
                        attribute,
                        objectName,
                        assetsPayloads
                      ));
                    });
                } else {
                  newData.push(getData(portfolioData, portfolioId, scenarioId, period, dataSet));
                }
              })
            ));

        return {
          ...state,
          simData: newData,
          loading: false
        };
      })
      .addCase(getSelectedSimData.rejected, (state) => ({
        ...state,
        simData: null,
        loading: false
      }));
  }
});

export default dashboardAnalytics.reducer;
