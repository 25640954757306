import styled from '@emotion/styled';

import Icons from 'components/ui/Icons';
import TextField from 'components/ui/TextField';

const Wrapper = styled.div`
  padding-top: 20px;
`;

// @ts-ignore
const StyledIcons = styled(Icons)`
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
`;

// @ts-ignore
const StyledTextField = styled(TextField)`
  && {
    & .MuiTextField-root {
      padding-right: 0;
      padding-left: 0;
    }
    & .MuiInputBase-root {
      background: ${(props) => props.theme.colorMono.white};
    }
  }
`;

const LinkButton = styled.button`
  color: ${(props) => props.theme.color.secondary};
  &:hover {
    text-decoration: underline;
  }
`;

export {
  Wrapper,
  StyledIcons,
  StyledTextField,
  LinkButton
};
