import styled from '@emotion/styled';

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  gap: 20px;
  height: 100%;
`;

export {
  Wrapper
};
