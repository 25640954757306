import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { ExternalLabelProps } from 'components/ui/ExternalLabel';
import InfoIconBox from 'components/ui/InfoIconBox';

const Wrapper = styled.div<ExternalLabelProps>`
  display: inline-flex;
  align-items: center;
  margin-bottom: 8px;
  font-size: 12px;
  line-height: 1.35;
  color: ${(props) => props.theme.colorText.secondary};

  ${(props) => !props.noFullWidth && css`
    width: 100%;
  `};
  ${(props) => props.uppercase && css`
    text-transform: uppercase;
  `};

  ${(props) => props.variant === 'secondary' && css`
    font-size: 14px;
    color: ${props.theme.colorText.primary};
  `};
`;

// @ts-ignore
const StyledInfoIconBox = styled(InfoIconBox)`
  display: inline-flex;
  align-items: center;
  margin-left: 5px;
  color: #9c9c9c;
`;

export {
  Wrapper,
  StyledInfoIconBox
};
