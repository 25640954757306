import React from 'react';

import {
  Wrapper
} from './TileWrapper.styles';

export interface BoxProps {
  children: React.ReactNode;
  className?: string;
}
const defaultProps = {
  className: ''
};

const TileWrapper = (props: BoxProps): JSX.Element => (
  <Wrapper className={props.className}>
    {props.children}
  </Wrapper>
);

TileWrapper.defaultProps = defaultProps;

export default TileWrapper;
