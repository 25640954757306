import styled from '@emotion/styled';

import { Autocomplete } from '@mui/material';

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;

const StyledAutocomplete = styled(Autocomplete)`
  + .MuiAutocomplete-popper {
    .MuiAutocomplete-paper {
      border-radius: 0;
      box-shadow: 0 2px 6px rgba(0 0 0 / .25);
      font-size: 14px;
      line-height: 1.3;
      color: ${(props) => props.theme.colorText.secondary};
    }
    .MuiAutocomplete-listbox {
      padding: 1px 0;
    }
  }
`;

export {
  Wrapper,
  StyledAutocomplete
};
