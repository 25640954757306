import React from 'react';

import {
  Wrapper
} from './Tile.styles';

export interface TileProps {
  children: React.ReactNode;
  className?: string;
  noStyle?: boolean;
  size?: 'big' | 'medium' | 'small' | 'none' | null;
  fullWidth?: boolean;
}
const defaultProps = {
  className: '',
  noStyle: false,
  size: null,
  fullWidth: false
};

const Tile = (props: TileProps): JSX.Element => (
  <Wrapper
    className={props.className}
    noStyle={props.noStyle}
    size={props.size}
    fullWidth={props.fullWidth}
  >
    {props.children}
  </Wrapper>
);

Tile.defaultProps = defaultProps;

export default Tile;
