import styled from '@emotion/styled/macro';

import { FormColumnsProps } from 'components/ui/FormColumns';

const Wrapper = styled.div<FormColumnsProps>`
  display: flex;
  width: 100%;
`;

export {
  Wrapper
};
