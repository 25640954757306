import { DashboardDataSet } from 'interfaces';
import { DASHBOARD_ANALYTICS_DATA_SET_TYPES } from 'constant';

import { commonColumnDefs } from './commonColumnDefs';
import { summaryColumnDefs } from './summaryColumnDefs';
import { assetsColumnDefs } from './assetsColumnDefs';
import { assetAttributeColumnDefs } from './assetAttributeColumnDefs';

export const getColumnDefs = (dataSet: DashboardDataSet) => {
  switch (dataSet) {
    case DASHBOARD_ANALYTICS_DATA_SET_TYPES.summary:
      return [
        ...commonColumnDefs,
        ...summaryColumnDefs
      ];

    case DASHBOARD_ANALYTICS_DATA_SET_TYPES.assetAttribute:
      return [
        ...commonColumnDefs,
        ...assetsColumnDefs,
        ...assetAttributeColumnDefs
      ];

    case DASHBOARD_ANALYTICS_DATA_SET_TYPES.assetStats:
      return [
        ...commonColumnDefs,
        ...assetsColumnDefs,
        ...summaryColumnDefs
      ];

    default:
      return [];
  }
};
