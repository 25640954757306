import { authAudience, authClientId, authDomain, appBaseUrl } from 'config/environment';

const authRedirectUrl = appBaseUrl as string;
const authScope = 'current_user update:current_user_metadata offline_access';

export {
  authAudience,
  authClientId,
  authDomain,
  authRedirectUrl,
  authScope
};
