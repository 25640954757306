import React, { useEffect, useState, useCallback } from 'react';

import {
  Asset,
  AssetCurve,
  AssetsFilters,
  AssetsFiltersWithSelectedOption,
  Components
} from 'interfaces';
import {
  DEFAULT_SCENARIO_TYPE,
  FILTERS_NAMES,
  FORECAST_SELECT_TYPES_IDS,
  FORECAST_TYPES_OPTIONS, LAYOUT_FORECAST_INPUTS_TABLE_ITEM_HEIGHT,
  METRIC_TYPES_IDS,
  PAGE_BUTTONS_WRAPPER_HEIGHT,
  SET_FORMAT_INPUTS_RETURN_ID,
  SET_FORMAT_INPUTS_RETURN_RANGE_ID,
  SET_FORMAT_INPUTS_VOLATILITY_ID,
  SET_FORMAT_INPUTS_VOLATILITY_RANGE_ID
} from 'constant';

import {
  getDefaultAssetsDictionariesFilters,
  getAssetsDictionariesFilters,
  updateFiltersBySelectedOptions
} from 'utils/assetsUtils';
import { forecastSetRangeTableData, forecastSetTableData } from 'utils/scenario';

import FormHeading from 'components/ui/FormHeading';
import Select from 'components/ui/Select';
import Icons from 'components/ui/Icons';
import Button from 'components/ui/Button';
import Autocomplete, { Option } from 'components/ui/Autocomplete';

import {
  ButtonWrapper,
  FormBox,
  FormBoxWrapper,
  StyledBox,
  Wrapper
} from './ScenarioForecast.styles';

export interface ScenarioForecastProps {
  returnTableData: Components[];
  setReturnTableData: any;
  returnRangeTableData: Components[];
  setReturnRangeTableData: any;
  volatilityTableData: Components[];
  setVolatilityTableData: any;
  volatilityRangeTableData: Components[];
  setVolatilityRangeTableData: any;
  assetsPayloads: Asset[];
  assetsCurves: AssetCurve[];
  forecastType: any;
  setForecastType: any;
}

const ScenarioForecast = (props: ScenarioForecastProps): JSX.Element => {
  const defaultSelectedOptions = {
    className: null,
    region: null,
    priceCurrency: null,
    assetClass: null,
    name: null
  };

  const [addButtonWasClicked, setAddButtonWasClicked] = useState<boolean>(false);
  const [selectedOptions, setSelectedOptions] = useState<any>(defaultSelectedOptions);

  const [assetsPayloads, setAssetsPayloads] = useState<AssetsFilters>({
    [FILTERS_NAMES.name]: [],
    [FILTERS_NAMES.assetClass]: [],
    [FILTERS_NAMES.region]: [],
    [FILTERS_NAMES.priceCurrency]: []
  });

  useEffect(() => {
    setAssetsPayloads(
      getAssetsDictionariesFilters(props.assetsPayloads)
    );
  }, [props.assetsPayloads, props.returnTableData]);

  const [assetsDictionariesFilters, setAssetsDictionariesFilters] = useState<AssetsFiltersWithSelectedOption>(
    getDefaultAssetsDictionariesFilters(assetsPayloads)
  );

  useEffect(() => {
    setAssetsDictionariesFilters(
      getDefaultAssetsDictionariesFilters(assetsPayloads)
    );
  }, [assetsPayloads]);

  const scrollToAddedElementSection = (id: string) => {
    setTimeout(() => {
      const setFormatInputsElement = document.getElementById(id);
      const element = {
        top: (setFormatInputsElement?.getBoundingClientRect()?.top || 0),
        height: (setFormatInputsElement?.getBoundingClientRect()?.height || 0)
      };

      if (
        window.scrollY + window.innerHeight - PAGE_BUTTONS_WRAPPER_HEIGHT
        < window.scrollY + element.top + element.height + LAYOUT_FORECAST_INPUTS_TABLE_ITEM_HEIGHT
      ) {
        window.scroll({
          top: element.top + element.height + LAYOUT_FORECAST_INPUTS_TABLE_ITEM_HEIGHT,
          behavior: 'smooth'
        });
      }
    }, 0);
    setAddButtonWasClicked(false);
  };

  useEffect(() => {
    if (addButtonWasClicked) {
      scrollToAddedElementSection(SET_FORMAT_INPUTS_RETURN_ID);
    }
  }, [props.returnTableData, addButtonWasClicked]);

  useEffect(() => {
    if (addButtonWasClicked) {
      scrollToAddedElementSection(SET_FORMAT_INPUTS_RETURN_RANGE_ID);
    }
  }, [props.returnRangeTableData, addButtonWasClicked]);

  useEffect(() => {
    if (addButtonWasClicked) {
      scrollToAddedElementSection(SET_FORMAT_INPUTS_VOLATILITY_ID);
    }
  }, [props.volatilityTableData, addButtonWasClicked]);

  useEffect(() => {
    if (addButtonWasClicked) {
      scrollToAddedElementSection(SET_FORMAT_INPUTS_VOLATILITY_RANGE_ID);
    }
  }, [props.volatilityRangeTableData, addButtonWasClicked]);

  const addForecast = () => {
    switch (props.forecastType.value) {
      case FORECAST_SELECT_TYPES_IDS.meanReturn: {
        props.setReturnTableData([
          ...props.returnTableData,
          forecastSetTableData(
            props.assetsPayloads,
            props.assetsCurves,
            selectedOptions,
            props.returnTableData.length
          )
        ]);
        break;
      }
      case FORECAST_SELECT_TYPES_IDS.meanReturnRange: {
        props.setReturnRangeTableData([
          ...props.returnRangeTableData,
          ...forecastSetRangeTableData(
            props.assetsPayloads,
            props.assetsCurves,
            selectedOptions,
            props.returnRangeTableData.length
          )
        ]);
        break;
      }
      case FORECAST_SELECT_TYPES_IDS.volatility: {
        props.setVolatilityTableData([
          ...props.volatilityTableData,
          forecastSetTableData(
            props.assetsPayloads,
            props.assetsCurves,
            selectedOptions,
            props.volatilityTableData.length,
            METRIC_TYPES_IDS.volatility
          )
        ]);
        break;
      }
      case FORECAST_SELECT_TYPES_IDS.volatilityRange: {
        props.setVolatilityRangeTableData([
          ...props.volatilityRangeTableData,
          ...forecastSetRangeTableData(
            props.assetsPayloads,
            props.assetsCurves,
            selectedOptions,
            props.volatilityRangeTableData.length,
            METRIC_TYPES_IDS.volatility
          )
        ]);
        break;
      }
      default: return;
    }

    props.setForecastType(DEFAULT_SCENARIO_TYPE);

    setSelectedOptions(defaultSelectedOptions);
    setAssetsPayloads(
      getAssetsDictionariesFilters(props.assetsPayloads)
    );
    setAssetsDictionariesFilters(
      getDefaultAssetsDictionariesFilters(assetsPayloads)
    );

    setAddButtonWasClicked(true);
  };

  return (
    <Wrapper>
      <FormHeading
        withPadding={false}
        additionalComponent={(
          <Select
            name="forecastType"
            color="secondary"
            withExternalLabel
            size="small"
            withMarginBottom={false}
            label="Forecast type:"
            labelPosition="left"
            onChange={(option: any) => {
              props.setForecastType(option);
            }}
            options={FORECAST_TYPES_OPTIONS}
            selected={props.forecastType}
          />
        )}
      >
        Set Forecast
      </FormHeading>
      <StyledBox>
        <FormBoxWrapper>
          <FormBox>
            <Autocomplete
              size="medium"
              withExternalLabel
              withMarginBottom={false}
              id={FILTERS_NAMES.region}
              name={FILTERS_NAMES.region}
              handleChange={(selectedOption: Option | null) => {
                updateFiltersBySelectedOptions(
                  selectedOption,
                  'region',
                  assetsDictionariesFilters,
                  setAssetsDictionariesFilters,
                  assetsPayloads
                );
                setSelectedOptions({
                  ...selectedOptions,
                  region: selectedOption?.value
                });
              }}
              label="Geography"
              placeholder="Geography"
              options={
                assetsDictionariesFilters.region.assets.map((asset: any) => ({
                  value: asset[FILTERS_NAMES.region],
                  label: asset[FILTERS_NAMES.region]
                }))
              }
              value={selectedOptions.region}
            />
          </FormBox>
          <FormBox>
            <Autocomplete
              size="medium"
              withExternalLabel
              withMarginBottom={false}
              id={FILTERS_NAMES.priceCurrency}
              name={FILTERS_NAMES.priceCurrency}
              handleChange={(selectedOption: Option | null) => {
                updateFiltersBySelectedOptions(
                  selectedOption,
                  'priceCurrency',
                  assetsDictionariesFilters,
                  setAssetsDictionariesFilters,
                  assetsPayloads
                );
                setSelectedOptions({
                  ...selectedOptions,
                  priceCurrency: selectedOption?.value
                });
              }}
              label="Currency"
              placeholder="Currency"
              options={
                assetsDictionariesFilters.priceCurrency.assets.map((asset: any) => ({
                  value: asset[FILTERS_NAMES.priceCurrency],
                  label: asset[FILTERS_NAMES.priceCurrency]
                }))
              }
              value={selectedOptions.priceCurrency}
            />
          </FormBox>
          <FormBox wider>
            <Autocomplete
              size="medium"
              withExternalLabel
              withMarginBottom={false}
              id={FILTERS_NAMES.assetClass}
              name={FILTERS_NAMES.assetClass}
              handleChange={(selectedOption: Option | null) => {
                updateFiltersBySelectedOptions(
                  selectedOption,
                  'assetClass',
                  assetsDictionariesFilters,
                  setAssetsDictionariesFilters,
                  assetsPayloads
                );
                setSelectedOptions({
                  ...selectedOptions,
                  assetClass: selectedOption?.value
                });
              }}
              label="Asset Classes"
              placeholder="Choose an option"
              options={
                assetsDictionariesFilters.assetClass.assets.map((asset: any) => ({
                  value: asset[FILTERS_NAMES.assetClass],
                  label: asset[FILTERS_NAMES.assetClass]
                }))
              }
              value={selectedOptions.assetClass}
            />
          </FormBox>
        </FormBoxWrapper>
        <FormBoxWrapper>
          <FormBox wider>
            <Autocomplete
              size="medium"
              withExternalLabel
              withMarginBottom={false}
              id={FILTERS_NAMES.name}
              name={FILTERS_NAMES.name}
              handleChange={(selectedOption: Option | null) => {
                updateFiltersBySelectedOptions(
                  selectedOption,
                  'name',
                  assetsDictionariesFilters,
                  setAssetsDictionariesFilters,
                  assetsPayloads
                );
                setSelectedOptions({
                  ...selectedOptions,
                  name: selectedOption?.value
                });
              }}
              label="Search Index Variable"
              placeholder="Choose an option"
              options={
                assetsDictionariesFilters.name.assets.map((asset: any) => ({
                  value: asset[FILTERS_NAMES.name],
                  label: asset[FILTERS_NAMES.name]
                }))
              }
              value={selectedOptions.name}
            />
          </FormBox>
          <ButtonWrapper>
            <Button
              variant="outlined"
              color="secondary"
              size="medium"
              disabled={!selectedOptions.name}
              endIcon={(
                <Icons iconType="plus" size={10} />
              )}
              onClick={addForecast}
            >
              Add Forecast
            </Button>
          </ButtonWrapper>
        </FormBoxWrapper>
      </StyledBox>
    </Wrapper>
  );
};

export default ScenarioForecast;
