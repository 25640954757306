import styled from '@emotion/styled';

import { hexToRgbMixin } from 'styles/mixins';

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const TableOptionsWrapper = styled.div`
  position: absolute;
  right: 10px;
  display: flex;
  align-items: center;
`;

const TableOptionsBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TableOptionsButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  padding: 0 5px;
  color: ${(props) => hexToRgbMixin(props.theme.colorMono.white, 0.54)};
`;

export {
  HeaderWrapper,
  TableOptionsWrapper,
  TableOptionsBox,
  TableOptionsButton
};
