import React from 'react';

import { PortfolioDetails } from 'interfaces';

import { formatDate } from 'utils/formatDate';

import FormColumns from 'components/ui/FormColumns';
import FormColumn from 'components/ui/FormColumn';
import ExternalLabel from 'components/ui/ExternalLabel';
import SimpleTable from 'components/ui/SimpleTable';

import {
  InputPlaceholder,
  Row,
  StyledFormColumn
} from './ReviewPortfolioModal.styles';

export interface ReviewPortfolioModalProps {
  portfolio: PortfolioDetails;
}

const ReviewPortfolioModal = (props: ReviewPortfolioModalProps): JSX.Element => {
  const tableColumnDefs = [
    {
      field: 'description',
      headerName: 'Asset name'
    },
    {
      field: 'quantity',
      headerName: 'Weight (%)',
      maxWidth: 160
    }
  ];

  return (
    <React.Fragment>
      <FormColumns>
        <FormColumn even={false}>
          <Row>
            <ExternalLabel>Portfolio Name</ExternalLabel>
            <InputPlaceholder>{props.portfolio.name}</InputPlaceholder>
          </Row>
          <Row>
            <FormColumns>
              <StyledFormColumn>
                <ExternalLabel>Currency</ExternalLabel>
                <InputPlaceholder>{props.portfolio.currency}</InputPlaceholder>
              </StyledFormColumn>
              <StyledFormColumn>
                <ExternalLabel>Date</ExternalLabel>
                <InputPlaceholder>{formatDate(props.portfolio.referenceDate)}</InputPlaceholder>
              </StyledFormColumn>
            </FormColumns>
          </Row>
          <Row>
            <ExternalLabel>Description</ExternalLabel>
            <InputPlaceholder>{props.portfolio.description}</InputPlaceholder>
          </Row>
        </FormColumn>
        <FormColumn even={false}>
          <SimpleTable
            tableColumnDefs={tableColumnDefs}
            tableData={props.portfolio.holdings}
          />
        </FormColumn>
      </FormColumns>
    </React.Fragment>
  );
};

export default ReviewPortfolioModal;
