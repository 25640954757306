import React from 'react';
import { Link, LinkProps } from 'react-router-dom';

import { ButtonProps, PropTypes } from '@material-ui/core';

import { BUTTON_ALLOW_COMPONENTS_TYPES } from 'constant';

import {
  StyledButton
} from './Button.styles';

export interface IButtonProps extends ButtonProps {
  color?: PropTypes.Color;
  component?: BUTTON_ALLOW_COMPONENTS_TYPES.button
  | BUTTON_ALLOW_COMPONENTS_TYPES.link
  | BUTTON_ALLOW_COMPONENTS_TYPES.anchor
  | BUTTON_ALLOW_COMPONENTS_TYPES.other;
  componentFunction?: any;
  size?: 'small' | 'medium' | 'large';
  variant?: 'text' | 'outlined' | 'contained';
}
const defaultProps = {
  color: 'primary',
  component: 'button',
  componentFunction: null,
  size: 'large',
  variant: 'contained'
};

const Button = (props: IButtonProps): JSX.Element => {
  const getComponent = (componentFunction: any): any => {
    switch (props.component) {
      case BUTTON_ALLOW_COMPONENTS_TYPES.anchor: {
      // component name use by material-ui
        return 'a';
      }
      case BUTTON_ALLOW_COMPONENTS_TYPES.link: {
        return React.forwardRef((linkProps: LinkProps, ref: React.ForwardedRef<HTMLAnchorElement>) => (
          <Link ref={ref} {...linkProps} />
        ));
      }
      case BUTTON_ALLOW_COMPONENTS_TYPES.other: {
        return React.forwardRef((linkProps, ref: React.ForwardedRef<any>) => (
          componentFunction({ ref, ...linkProps })
        ));
      }

      default:
      // component name use by material-ui
        return 'button';
    }
  };

  return (
    <StyledButton
      {...props}
      component={getComponent(props.componentFunction)}
      size={props.size}
    />
  );
};

Button.defaultProps = defaultProps;

export default Button;
