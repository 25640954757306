import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';

import {
  JobScenario,
  JobViewDataAdapter,
  RootState,
  Scenarios,
  ScenarioSet
} from 'interfaces';

import {
  fetchCalcSetting,
  fetchScenarioSet,
  fetchScenario,
  fetchPortfolio
} from 'api';
import { removeCalcSetting } from 'api/endpoints/calcSettings';

const jobAdapter = createEntityAdapter<JobViewDataAdapter>({
  selectId: (job) => job.calcId
});

export const getCalcSettings = createAsyncThunk<any, {
  calcId: number
}, { state: RootState }>(
  'job/getCalcSettings',
  async ({ calcId }) => fetchCalcSetting(calcId)
    .then((response: any) => {
      const { name, scenarioSets, portfolios } = response;

      return {
        calcId,
        jobName: name,
        scenarioSets,
        portfolios
      };
    })
);

export const getScenarioSets = createAsyncThunk<any, {
  scenarioSets: ScenarioSet[], calcId: number
}, { state: RootState }>(
  'job/getScenarioSets',
  async ({ scenarioSets, calcId }) => {
    const scenarioSetIds = scenarioSets.map((el: ScenarioSet) => el.scenarioSetSerialID);

    return Promise.all(
      [
        ...scenarioSetIds.map((id) => fetchScenarioSet(id))
      ]
    )
      .then((responses: Scenarios[]) => {
        const scenarios = responses.map((el: Scenarios) => el.scenarios);

        // @TODO: only unique scenarioSerialID
        const allScenarioIds = scenarios.reduce((acc: number[], current: JobScenario[]) => acc
          .concat(current.map((el: JobScenario) => el.scenarioSerialID)), []);

        return Promise.all(
          [
            ...allScenarioIds.map((id: number) => fetchScenario(id))
          ]
        )
          .then((scenariosResponses: any) => ({
            calcId,
            scenarioList: scenariosResponses
          }));
      });
  }
);

export const getPortfolios = createAsyncThunk(
  'job/getPortfolios',
  // @ts-ignore
  async ({ portfolios, calcId } : any) => {
    const portfoliosIds = portfolios.map((el: any) => el.portfolioSerialID);

    return Promise.all(
      [
        ...portfoliosIds.map((id: number) => fetchPortfolio(id))
      ]
    )
      .then((portfolioResponses: any) => ({
        calcId,
        portfolioList: portfolioResponses
      }));
  }
);

export const deleteCalcId = createAsyncThunk<any, {
  calcId: number | null
}, { state: RootState }>(
  'job/deleteCalcId',
  async ({ calcId }) => removeCalcSetting(calcId)
    .then(() => ({
      calcId
    }))
);

const job = createSlice({
  name: 'job',
  initialState: jobAdapter.getInitialState(),
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCalcSettings.fulfilled, (state, action) => {
        const {
          calcId, jobName, scenarioSets, portfolios
        } = action.payload;
        jobAdapter.addOne(state, {
          calcId,
          jobName,
          scenarioSets,
          portfolios
        });
      })
      .addCase(getCalcSettings.rejected, () => {
        // @TODO: Handle error
      })
      .addCase(getScenarioSets.fulfilled, (state, action) => {
        const { calcId, scenarioList } = action.payload;

        jobAdapter.updateOne(state, {
          id: calcId,
          changes: {
            scenarioList
          }
        });
      })
      .addCase(getScenarioSets.rejected, () => {
        // @TODO: Handle error
      })
      .addCase(getPortfolios.fulfilled, (state, action) => {
        const { calcId, portfolioList } = action.payload;

        jobAdapter.updateOne(state, {
          id: calcId,
          changes: {
            portfolioList
          }
        });
      })
      .addCase(getPortfolios.rejected, () => {
        // @TODO: Handle error
      })
      .addCase(deleteCalcId.fulfilled, (state, action) => {
        const { calcId } = action.payload;
        jobAdapter.removeOne(state, calcId);
      })
      .addCase(deleteCalcId.rejected, () => {
        // @TODO: Handle error
      });
  }
});

export default job.reducer;
