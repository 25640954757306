import { textRenderer } from 'utils/tableFormatters';

export const assetsColumnDefs = [
  {
    field: 'assetName',
    headerName: 'Asset name',
    flex: 1,
    minWidth: 300,
    maxWidth: 400,
    cellRenderer: textRenderer
  }
];
