import { useState, useEffect } from 'react';

import { useAuth0 } from '@auth0/auth0-react';

import { authAudience, authScope } from 'config/auth';

export const useAccessToken = (): string | null => {
  const { isAuthenticated, getAccessTokenSilently, loginWithRedirect } = useAuth0();

  const [accessToken, setAccessToken] = useState<string | null>(null);

  useEffect(() => {
    const getAccessToken = async () => {
      if (!isAuthenticated) {
        setAccessToken(null);

        return;
      }

      await getAccessTokenSilently({
        authorizationParams: {
          audience: authAudience,
          scope: authScope
        }
      }).then((token: string) => {
        setAccessToken(token);
      }).catch(() => {
        setAccessToken(null);
        loginWithRedirect();
      });
    };

    getAccessToken();
  });

  return accessToken;
};
