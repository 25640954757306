import styled from '@emotion/styled';

import IconButton from 'components/ui/IconButton';

// @ts-ignore
const StyledIconButton = styled(IconButton)`
  border-radius: 50%;
  background: ${(props) => props.theme.colorMono.white};

  &:hover {
    background: ${(props) => props.theme.colorMono.white};
  }
`;

export {
  StyledIconButton
};
