import styled from '@emotion/styled';

import Select from 'components/ui/Select';

const Wrapper = styled.div`
  margin-bottom: 20px;
`;

const JobAutocompleteWrapper = styled.div`
  background: ${(props) => props.theme.colorMono.white};
`;

// @ts-ignore
const StyledJobSelect = styled(Select)`
  && {
    & .MuiInputBase-root {
      border-color: ${(props) => props.theme.colorMono.white};
      background: ${(props) => props.theme.colorMono.white};
      font-weight: 600;

      &.Mui-focused {
        border-color: ${(props) => props.theme.colorMono.white};
      }
    }
  }
`;

export {
  Wrapper,
  JobAutocompleteWrapper,
  StyledJobSelect
};
