import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { JobManagerData, JobSettingsForm } from 'interfaces';

import { DEFAULT_JOB_SETTING } from 'constant';

import {
  fetchCalcSettings,
  fetchPortfolios,
  fetchScenarios,
  sendCalcSetting,
  sendScenarioSet,
  sendJob
} from 'api';

const initialState: JobManagerData = {
  scenarios: [],
  portfolios: [],
  jobs: [],
  finalizeJobReady: false,
  calcSettingsId: null,
  notification: {
    open: false,
    message: null,
    type: undefined
  },
  settings: {
    numberOfSamples: DEFAULT_JOB_SETTING.numberOfSettings.toString(),
    modelHorizon: DEFAULT_JOB_SETTING.modelHorizon.toString(),
    samplingInterval: DEFAULT_JOB_SETTING.samplingInterval.toString(),
    // samplingHorizons: 26,
    forecastHorizon: DEFAULT_JOB_SETTING.forecastHorizon.toString(),
    reportingCurrency: DEFAULT_JOB_SETTING.reportingCurrency
  }
};

export const getData = createAsyncThunk(
  'jobManager/getData',
  async () => Promise.all([
    fetchScenarios(),
    fetchPortfolios(),
    fetchCalcSettings()
  ])
    .then(([scenarios, portfolios, jobs]: any) => ({
      scenarios,
      portfolios,
      jobs
    }))
);

export const createJob = createAsyncThunk<any, {
  calcId: number | null, jobName: string, settings: JobSettingsForm, portfolioList: any[], scenarioList: any[]
}>(
  'jobManager/createJob',
  async ({
    calcId, jobName, settings, portfolioList, scenarioList
  }) => {
    const scenarioSetPayload = {
      name: 'Default',
      scenarios: scenarioList
        .map((item: any) => ({
          scenarioSerialID: item.id
        })),
      lastModified: new Date().toISOString()
    };

    const getJobsPayload = (result: any) => ({
      name: jobName,
      portfolios: portfolioList.map((item: any) => ({
        portfolioSerialID: item?.id
      })),
      scenarioSets: [
        {
          scenarioSetSerialID: result
        }
      ],
      numberOfSamples: Number(settings.numberOfSamples),
      modelHorizon: Number(settings.modelHorizon),
      samplingInterval: Number(settings.samplingInterval.value),
      // samplingHorizons: 26,
      forecastHorizon: Number(settings.forecastHorizon.value),
      reportingCurrency: settings.reportingCurrency
    });

    return sendScenarioSet(scenarioSetPayload)
      .then((result: any) => {
        const payload = getJobsPayload(result);

        return sendCalcSetting(payload).then((calcSettingsResult: any) => ({
          calcSettingsId: calcSettingsResult
        }));
      });
  }
);

export const finalizeJob = createAsyncThunk<any, { calcSettingsId: number | null, valuationDate: Date }>(
  'jobManager/finalizeJob',
  async ({ calcSettingsId, valuationDate }) => sendJob({
    simulatorCalcSerialID: calcSettingsId,
    valuationDate: valuationDate.toISOString()
  })
);

const jobManager = createSlice({
  name: 'jobManager',
  initialState,
  reducers: {
    deleteJob (state, action) {
      const { id } = action.payload;
      const jobElementIndex = state.jobs.findIndex((el) => el.id === id);
      const newJobs = [...state.jobs];

      newJobs.splice(jobElementIndex, 1);

      return {
        ...state,
        jobs: newJobs
      };
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getData.fulfilled, (state, action) => {
        const { scenarios, portfolios, jobs } = action.payload;

        const portfoliosSorted = [...portfolios].sort((a, b) => Number(b.id) - Number(a.id));
        const scenariosSorted = [...scenarios].sort((a, b) => Number(b.id) - Number(a.id));
        const jobsSorted = [...jobs].sort((a, b) => Number(b.id) - Number(a.id));

        return {
          ...state,
          portfolios: portfoliosSorted,
          scenarios: scenariosSorted,
          // @TODO: optimization
          jobs: jobsSorted.slice(0, 9) // only 10 elements - can be removed after optimization
        };
      })
      .addCase(getData.rejected, () => {
        // @TODO: Handle error
      })
      .addCase(createJob.fulfilled, (state, action) => ({
        ...state,
        notification: {
          open: true,
          message: 'Successfully created CalcSettings',
          type: 'success'
        },
        finalizeJobReady: true,
        calcSettingsId: action.payload?.calcSettingsId
      }))
      .addCase(createJob.rejected, (state) => ({
        ...state,
        notification: {
          open: true,
          message: 'Creating CalcSetting failed',
          type: 'error'
        },
        finalizeJobReady: false
      }))
      .addCase(finalizeJob.fulfilled, (state) => ({
        ...state,
        notification: {
          open: true,
          message: 'Successfully created Job',
          type: 'success'
        },
        finalizeJobReady: false
      }))
      .addCase(finalizeJob.rejected, (state) => ({
        ...state,
        notification: {
          open: true,
          message: 'Create Job failed',
          type: 'error'
        },
        finalizeJobReady: false
      }));
  }
});

export const { deleteJob } = jobManager.actions;

export default jobManager.reducer;
