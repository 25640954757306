import * as yup from 'yup';
import { defaultMessages } from 'constant';

export const scenarioDetailsValidationSchema = yup.object({
  name: yup.string().required(defaultMessages.required).nullable(),
  scenarioDate: yup.date()
    .required(defaultMessages.required)
    .typeError(defaultMessages.fieldMustBeDateFormat)
    .nullable()
    .default(undefined),
  description: yup.string().required(defaultMessages.required).nullable(),
  scenarioType: yup.string().required(defaultMessages.required).nullable(),
  addedTags: yup.string().nullable()
});
