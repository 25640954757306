import styled from '@emotion/styled';

import Button from 'components/ui/Button';
import Modal from 'components/ui/Modal';

const StyledModal = styled(Modal)`
  & > div[style] {
    background: none !important;
  }
  p + p {
    margin-top: 15px;
  }
  .modal-wrapper {
    min-width: 300px;
    max-width: 300px;
    border-radius: 2px;
    background: #16161a;
    box-shadow: none;
    color: #cefdd9;

    @media (min-width: 1200px) {
      min-width: 300px;
      max-width: 300px;
    }

    @media (min-width: 1500px) {
      min-width: 300px;
      max-width: 300px;
    }
  }
  .modal-close-button {
    &:hover {
      background: ${(props) => props.theme.colorGray.white12};
    }
  }
  .modal-header {
    padding-right: 25px;
    padding-left: 25px;
  }
  .modal-content {
    padding-right: 25px;
    padding-left: 25px;
  }
  .modal-footer {
    justify-content: space-between;
    padding: 15px 25px 25px;
  }
`;

// @ts-ignore
const StyledButton = styled(Button)`
  color: #c6c6c6;
`;

export {
  StyledModal,
  StyledButton
};
