import styled from '@emotion/styled';
import { css } from '@emotion/react';

import {
  MAIN_NAV_WIDE_WIDTH,
  MAIN_NAV_WIDTH,
  MAIN_HEADER_HEIGHT,
  PAGE_BUTTONS_WRAPPER_HEIGHT, LAYOUT_DEFAULT_H_MARGIN
} from 'constant';

import Container, { ContainerProps } from 'components/ui/Container';

interface ContentProps {
  menuOpen: boolean | undefined;
  withPageButtons?: boolean | undefined;
}
interface LayoutContainerProps {
  sidePanelPadding: number | null;
  paddingSide: string;
}

const Wrapper = styled.div`
  height: 100%;
`;

const Content = styled.div<ContentProps>`
  padding-top: calc(${MAIN_HEADER_HEIGHT}px + ${LAYOUT_DEFAULT_H_MARGIN}px);
  padding-bottom: calc(${PAGE_BUTTONS_WRAPPER_HEIGHT}px + ${LAYOUT_DEFAULT_H_MARGIN}px);
  padding-left: ${MAIN_NAV_WIDTH}px;
  transition: ${(props) => props.theme.layout.transition};

  ${(props) => props.menuOpen && css`
    padding-left: ${MAIN_NAV_WIDE_WIDTH}px;
  `};
  ${(props) => !props.withPageButtons && css`
    padding-bottom: ${LAYOUT_DEFAULT_H_MARGIN}px;
  `};
`;

const StyledContainer = styled(Container)<ContainerProps & LayoutContainerProps>`
  ${(props) => !!props.sidePanelPadding && props.paddingSide === 'left' && css`
    padding-left: ${props.sidePanelPadding}px;
  `};
  ${(props) => !!props.sidePanelPadding && props.paddingSide === 'right' && css`
    padding-right: ${props.sidePanelPadding}px;
  `};
`;

export {
  Wrapper,
  Content,
  StyledContainer
};
