import React from 'react';

import {
  Wrapper
} from './FormColumns.styles';

export interface FormColumnsProps {
  children: any;
}

const FormColumns = (props: FormColumnsProps): JSX.Element => (
  <Wrapper>
    {props.children}
  </Wrapper>
);

export default FormColumns;
