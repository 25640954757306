import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { PortfolioManagerData } from 'interfaces';

import { fetchPortfolios, removePortfolio } from 'api';

import { formatDate } from 'utils/formatDate';

const initialState: PortfolioManagerData = {
  notification: {
    open: false,
    message: null,
    type: undefined
  },
  refreshList: false,
  portfolios: []
};

export const getPortfolios = createAsyncThunk(
  'portfolioManager/getPortfolios',
  // @ts-ignore
  async () => fetchPortfolios()
);

export const deletePortfolio = createAsyncThunk(
  'portfolioManager/deletePortfolio',
  // @ts-ignore
  async ({ portfolioId }) => removePortfolio(portfolioId)
);

const portfolioManager = createSlice({
  name: 'portfolioManager',
  initialState,
  reducers: {
    resetRefresh (state) {
      return {
        ...state,
        refreshList: initialState.refreshList
      };
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPortfolios.fulfilled, (state, action) => {
        // @TODO: format date
        const portfolioNotSorted = action.payload.map((el: any) => ({
          ...el,
          referenceDate: formatDate(el.referenceDate)
        }));

        const portfolios = [...portfolioNotSorted].sort((a, b) => Number(b.id) - Number(a.id));

        return {
          ...state,
          portfolios
        };
      })
      .addCase(getPortfolios.rejected, () => {
        // @TODO: Handle error
      })
      .addCase(deletePortfolio.fulfilled, (state) => ({
        ...state,
        refreshList: true,
        notification: {
          open: true,
          message: 'Successfully deleted portfolio',
          type: 'success'
        }
      }))
      .addCase(deletePortfolio.rejected, (state, action) => ({
        ...state,
        notification: {
          open: true,
          message: 'Failed to delete portfolio',
          type: 'error'
        }
      }));
  }
});

export const { resetRefresh } = portfolioManager.actions;
export default portfolioManager.reducer;
