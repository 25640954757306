import { css } from '@emotion/react';

import { hexToRgbMixin } from 'styles/mixins';

import { theme } from './theme';

const GlobalStyles = css`
  html {
    box-sizing: border-box;
    overflow-x: hidden;
    overflow-y: scroll;
    height: 100%;
    font-family: ${theme.layout.baseFontFamily};
    // font-size: ${theme.fontSize.base};
  }

  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }

  body {
    height: 100%;
    background: ${theme.layout.background} !important;
    // font-size: ${theme.fontSize.normal};
    font-size: 14px;
    line-height: ${theme.lineHeight.base};
    color: ${theme.colorText.primary};
  }

  body,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  ol,
  ul {
    margin: 0;
    padding: 0;
  }

  body,
  p,
  ol,
  ul {
    font-weight: ${theme.fontWeight.regular};
  }

  img {
    max-width: none;
    height: auto;
  }

  textarea,
  select,
  input,
  button {
    outline: none;
    font-family: inherit;
  }

  input {
    padding: 0;
    border-radius: 0;

    &::-ms-clear,
    &::-ms-reveal {
      display: none;
      pointer-events: none;
    }

    &::-webkit-contacts-auto-fill-button {
      position: absolute;
      right: 0;
      display: none;
      visibility: hidden;
      pointer-events: none;
    }

    &::-webkit-caps-lock-indicator,
    &::-webkit-credentials-auto-fill-button {
      display: none;
      pointer-events: none;
    }

    &:-webkit-autofill {
      box-shadow: 0 0 0 1000px ${theme.colorMono.white} inset;
    }

    &:required {
      box-shadow: none;
    }
  }

  button {
    padding: 0;
    border: 0;
    background: none;
    font: inherit;
    color: inherit;
    cursor: pointer;
    &:active,
    &:focus {
      outline: none;
    }
  }

  h1, h2, h3, h4, h5, h6 {
    font-weight: normal;
  }
  h1 {
    font-size: 24px;
  }
  h2 {
    font-size: 18px;
  }

  a {
    color: inherit;
    text-decoration: none;
    cursor: pointer;
  }

  .MuiPaper-rounded {
    border-radius: 0;
  }
  .MuiList-padding {
    padding-top: 0;
    padding-bottom: 0;
  }
  .MuiListItem-root.Mui-selected,
  .MuiListItem-root.Mui-selected:hover {
    background-color: ${hexToRgbMixin(theme.color.primary, 0.2)};
  }
  .MuiPopover-root, .MuiPickersPopper-root, .MuiDialog-root {
    z-index: 10002 !important;
  }
  .MuiPickersPopper-root {
    .PrivatePickersMonth-root.Mui-selected,
    .PrivatePickersMonth-root.Mui-selected:hover,
    .PrivatePickersYear-yearButton.Mui-selected,
    .PrivatePickersYear-yearButton.Mui-selected:hover,
    .PrivatePickersDay-yearButton.Mui-selected,
    .PrivatePickersDay-yearButton.Mui-selected:hover,
    .MuiPickersToolbar-root {
      background: ${theme.color.secondary};
    }
    .MuiButton-textPrimary,
    .MuiPickersMonth-root.Mui-selected {
      color: ${theme.color.secondary};
    }
    .MuiPickersDay-root.Mui-selected {
      background: ${theme.color.secondary};
      color: ${theme.colorMono.white};
    }
    .MuiPickersMonth-root.Mui-selected,
    .MuiPickersMonth-root:focus {
      color: ${theme.color.secondary};
    }
    .MuiPickersYear-yearButton:focus,
    .MuiPickersYear-yearButton:hover,
    .MuiPickersYear-yearButton.Mui-selected {
      background: ${theme.color.secondary};
      color: ${theme.colorMono.white};
    }

    /* Calendar view like as in: StaticDatePicker styles */
    .MuiPickersDay-root {
      border-radius: 0;
    }
    .MuiPickersDay-today {
      border: 0;
      background: #e2e2e2;
    }
    .MuiDayPicker-weekContainer {
      margin: 5px 0;
    }
  }
  .MuiTooltip-popper {
    &.MuiTooltip-popperArrow {
      .MuiTooltip-tooltip {
        min-width: 60px;
        background: #16161a; // @TODO: move to theme
        font-size: 12px;
        color: #cefdd9; // @TODO: move to theme
      }
      .MuiTooltip-arrow {
        color: #16161a;
      }
    }
  }

  /* AG Grid */
  // Icons
  .ag-theme-alpine .ag-checkbox-input-wrapper,
  .ag-theme-alpine .ag-icon {
    font-family: ${theme.fontFamily.icons};
  }
  .ag-theme-alpine .ag-checkbox-input-wrapper::after {
    content: '\\e91d';
  }
  .ag-theme-alpine .ag-checkbox-input-wrapper.ag-checked::after {
    content: '\\e91e';
  }
  .ag-theme-alpine .ag-checkbox-input-wrapper.ag-indeterminate::after {
    content: '\\e91f';
  }
  .ag-theme-alpine .ag-icon-grip::before {
    content: '\\e90b';
  }
  .ag-theme-alpine .ag-icon-filter::before {
    content: '\\e922';
  }
  .ag-theme-alpine .ag-icon-menu::before {
    content: '\\e923';
  }
  .ag-theme-alpine .ag-icon-columns::before {
    content: '\\e916';
  }
  .ag-theme-alpine .ag-icon-pin::before {
    content: '\\e924';
  }
  .ag-theme-alpine .ag-icon-copy::before {
    content: '\\e925';
  }
  .ag-theme-alpine .ag-icon-paste::before {
    content: '\\e926';
  }
  .ag-theme-alpine .ag-icon-save::before {
    content: '\\e927';
  }
  .ag-theme-alpine .ag-icon-csv::before {
    content: '\\e929';
  }
  .ag-theme-alpine .ag-icon-excel::before {
    content: '\\e928';
  }
  .ag-theme-alpine .ag-icon-cancel::before {
    content: '\\e905';
    font-size: 8px;
  }
  .ag-theme-alpine .ag-column-drop-cell-button {
    min-width: 15px;
  }
  .ag-theme-alpine .ag-icon-group::before {
    content: '\\e916';
    color: #9c9c9c;
  }
  .ag-theme-alpine .ag-icon-tree-closed::before {
    content: '\\e907';
    font-size: 9px;
    color: #5a5a5a;
  }
  .ag-theme-alpine .ag-icon-tree-open::before {
    content: '\\e908';
    font-size: 9px;
    color: #5a5a5a;
  }
  .ag-theme-alpine .ag-ltr .ag-menu-option-icon {
    color: #9c9c9c;
  }

  // @TODO: to remove
  .MuiSlider-root {
    .MuiSlider-rail, .MuiSlider-thumb {
      color: ${theme.color.secondary};
    }
    .MuiSlider-track {
      background-color: ${theme.color.secondary};
    }
  }
`;

export {
  GlobalStyles
};
