import styled from '@emotion/styled';

import DroppableInner from 'components/ui/Box';
import Heading from 'components/ui/Heading';

const StyledBox = styled(DroppableInner)`
  position: relative;
  margin-bottom: 25px;
  padding: 20px 10px 0 0;
  background: white;

  .js-plotly-plot .plotly .main-svg {
    padding: 25px 25px 25px 15px;
  }
`;

// @ts-ignore
const StyledHeading = styled(Heading)`
  margin-left: 86px;
`;

export {
  StyledBox,
  StyledHeading
};
