import React from 'react';

import { Draggable } from 'react-beautiful-dnd';

import {
  Wrapper
} from './DraggableWrapper.styles';

export interface DraggableWrapperProps {
  children: React.ReactNode;
  draggableId: string;
  index: number;
  className?: string;
  small?: boolean;
}
const defaultProps = {
  className: '',
  small: false
};

const DraggableWrapper = (props: DraggableWrapperProps): JSX.Element => (
  <Wrapper className={props.className} small={props.small}>
    <Draggable draggableId={props.draggableId} index={props.index}>
      {
        (draggableProvided, snapshot) => (
          <div
            {...draggableProvided.draggableProps}
            {...draggableProvided.dragHandleProps}
            ref={draggableProvided.innerRef}
          >
            {props.children}
          </div>
        )
      }
    </Draggable>
  </Wrapper>
);

DraggableWrapper.defaultProps = defaultProps;

export default DraggableWrapper;
