import { MONTH_ABBREV_YEAR_DATE_FORMAT } from 'constant';
import { formatDate } from 'utils/formatDate';

const PORTFOLIO_NAME_INDEX = 0;
const SCENARIO_NAME_INDEX_PART_1 = 1;
const SCENARIO_NAME_INDEX_PART_2 = 2;
const CHART_NAMES_SEPARATOR = '-';

export const getDataFromSimdata = (simdata: any, clickedFragmentData: any) => {
  if (!clickedFragmentData || !simdata) {
    return {};
  }

  const { data: clickedData, y: timePeriod } = clickedFragmentData;
  const clickedLabel = clickedData.name.split(CHART_NAMES_SEPARATOR);
  const portfolioName = clickedLabel[PORTFOLIO_NAME_INDEX].trim();
  const scenarioName = clickedLabel[SCENARIO_NAME_INDEX_PART_1].trim() + (clickedLabel[SCENARIO_NAME_INDEX_PART_2] ? ` - ${clickedLabel[SCENARIO_NAME_INDEX_PART_2]?.trim()}` : '');
  const clickedSimdata: any = Object
    .values(simdata)
    .filter((el: any) => el.portfolioName === portfolioName)?.[0];
  const clickedScenarioSimdata: any = Object
    .values(clickedSimdata.scenarioData)
    .filter((el: any) => el.scenarioName === scenarioName)?.[0];

  const clickedSimdataTimePeriod = clickedScenarioSimdata?.timePeriodData[Math.round(timePeriod)];

  return {
    cVaR95: Number(clickedSimdataTimePeriod?.cVaR95 * 100).toFixed(2),
    cVaR99: Number(clickedSimdataTimePeriod?.cVaR99 * 100).toFixed(2),
    vaR95: Number(clickedSimdataTimePeriod?.vaR95 * 100).toFixed(2),
    vaR99: Number(clickedSimdataTimePeriod?.vaR99 * 100).toFixed(2),
    mean: Number(clickedSimdataTimePeriod?.mean * 100).toFixed(2),
    vol: Number(clickedSimdataTimePeriod?.vol * 100).toFixed(2),
    volAnnualized: Number(clickedSimdataTimePeriod?.volAnnualized * 100).toFixed(2),
    timePeriodDate: formatDate(clickedSimdataTimePeriod?.timePeriodDate, MONTH_ABBREV_YEAR_DATE_FORMAT)
  };
};
