import { gateway } from 'config/environment';

const axios = require('axios').default;

const instance = axios.create({
  baseURL: gateway,
  headers: {
    'Content-Type': 'application/json; charset=UTF-8',
    'Cache-Control': 'no-cache',
    Pragma: 'no-cache'
  }
});

instance.interceptors.response.use(
  (response: { data: any; }) => response.data,
  (error: any) => { throw error; }
);

export default instance;
