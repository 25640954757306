import React, { useEffect, useState } from 'react';

import { MenuItem, SelectProps } from '@material-ui/core';

import { ITextFieldProps } from 'components/ui/TextField';
import Icons from 'components/ui/Icons';

import {
  SelectWrapper,
  StyledTextField
} from './Select.styles';

export interface ISelect extends SelectProps {
  color?: 'primary' | 'secondary' | undefined;
  className?: string,
  label?: string;
  inputProps?: any;
  SelectProps?: any;
  selected?: any;
  options?: any;
  onChange?: any;
}
const defaultProps = {
  color: 'primary',
  className: '',
  label: null,
  inputProps: {},
  SelectProps: {},
  selected: {},
  options: {},
  onChange: () => {}
};

const Select = ({
  inputProps,
  SelectProps,
  ...props
}: ISelect & ITextFieldProps): JSX.Element => {
  const [selectedOption, setSelectedOption] = useState(props.selected);

  const handleChange = (event: any) => {
    const option = props.options.filter((el: any) => el.value === event.target.value)[0];

    setSelectedOption(option);
    props.onChange(option);
  };

  useEffect(() => {
    setSelectedOption(props.selected);
  }, [props.selected]);

  return (
    <SelectWrapper fullWidth={props.fullWidth}>
      <StyledTextField
        className={props.className}
        color={props.color}
        size={props.size}
        labelPosition={props.labelPosition}
        labelVariant={props.labelVariant}
        withExternalLabel={props.withExternalLabel}
        withMarginBottom={props.withMarginBottom}
        label={props.label}
        value={selectedOption ? selectedOption.value : ''}
        onChange={handleChange}
        select
        SelectProps={{
          ...SelectProps,
          IconComponent: (props: any) => (
            // @TODO: icon in circle
            <Icons {...props} iconType="chevron-down" size={7} />
          ),
          MenuProps: {
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left'
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left'
            },
            getContentAnchorEl: null,
            ...SelectProps.MenuProps,
            MenuListProps: {
              ...SelectProps.MenuProps?.MenuListProps
            },
            disableScrollLock: true
          }
        }}
      >
        {
          props.options.map((option: any) => (
            <MenuItem
              key={option.value}
              value={option.value}
            >
              {option.label}
            </MenuItem>
          ))
        }
      </StyledTextField>
    </SelectWrapper>
  );
};

Select.defaultProps = defaultProps;

export default Select;
