import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { PAGE_BUTTONS_WRAPPER_HEIGHT } from 'constant';

import Box from 'components/ui/Box';

interface AdditionalProps {
  margin: number;
}

const StyledBox = styled(Box)<AdditionalProps>`
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 3;
  height: ${PAGE_BUTTONS_WRAPPER_HEIGHT}px;
  padding: 12px 0;
  border-width: 1px 0 0;

  ${(props) => props.margin && css`
    left: ${props.margin}px;
  `};
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 12px;
`;

export {
  StyledBox,
  Wrapper
};
