import React from 'react';

import { StandardTextFieldProps } from '@material-ui/core/TextField/TextField';

import {
  Wrapper,
  StyledTextField,
  StyledInfoIconBox,
  MaxLengthWrapper,
  StyledExternalLabel
} from './TextField.styles';

// @TODO: How to resolve size props warning
export interface ITextFieldProps extends Omit<StandardTextFieldProps, 'size'> {
  size?: 'small' | 'medium' | 'large';
  value?: string | null;
  withExternalLabel?: boolean;
  labelVariant?: 'primary' | 'secondary' | undefined;
  withMarginBottom?: boolean;
  showMaxLengthInfo?: boolean;
  maxLength?: number;
  noFullWidth?: boolean;
  className?: string;
  label?: string;
  labelInfo?: string;
  labelPosition?: 'top' | 'left';
  inputProps?: any;
  SelectProps?: any;
}
const defaultProps = {
  value: '',
  withExternalLabel: false,
  labelVariant: 'primary',
  withMarginBottom: true,
  showMaxLengthInfo: false,
  maxLength: null,
  noFullWidth: false,
  className: '',
  label: null,
  labelPosition: 'top',
  labelInfo: null,
  size: 'large',
  inputProps: {},
  SelectProps: {}
};

const TextField = ({
  label,
  labelInfo,
  withMarginBottom,
  withExternalLabel,
  labelVariant,
  maxLength,
  showMaxLengthInfo,
  inputProps,
  labelPosition,
  className,
  ...props
}: ITextFieldProps): JSX.Element => (
  <Wrapper
    className={className}
    noFullWidth={props.noFullWidth as boolean}
    withMarginBottom={withMarginBottom as boolean}
    labelPosition={labelPosition}
  >
    {
      withExternalLabel ? (
        <StyledExternalLabel
          variant={labelVariant}
          labelPosition={labelPosition}
          noFullWidth={props.noFullWidth as boolean}
          htmlFor={props.id}
        >
          {label}
          {
            labelInfo ? (
              <StyledInfoIconBox title={labelInfo} size={16} />
            ) : null
          }
          {
            showMaxLengthInfo ? (
              <MaxLengthWrapper>{props.value?.length || 0} / {maxLength}</MaxLengthWrapper>
            ) : null
          }
        </StyledExternalLabel>
      ) : null
    }
    <StyledTextField
      noFullWidth={props.noFullWidth as boolean}
      placeholder={props.placeholder || label}
      inputProps={{
        ...inputProps,
        maxLength
      }}
      {...props}
      value={props.value || ''}
    />
  </Wrapper>
);

TextField.defaultProps = defaultProps;

export default TextField;
