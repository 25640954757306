import React from 'react';

import { TabsProps } from '@material-ui/core';

import {
  StyledTabs
} from './Tabs.styles';

const Tabs = (props: TabsProps): JSX.Element => (
  <StyledTabs
    {...props}
  />
);

export default Tabs;
