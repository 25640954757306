import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';

import { Droppable } from 'react-beautiful-dnd';

import { JobName, Portfolio, Scenario } from 'interfaces';

import { JobManagerDataContext } from 'context/jobManagerDataContext';

import JobTile from 'components/ui/JobTile';
import Icons from 'components/ui/Icons';
import DraggableWrapper from 'components/ui/DraggableWrapper';
import Scrollbar from 'components/ui/Scrollbar';

import {
  Wrapper,
  NameWrapper,
  NameInner,
  JobNameInput,
  JobNameButton,
  NameTextWrapper,
  ElementsWrapper,
  DragAndDropText,
  DroppableInner
} from './JobCard.styles';

export interface JobCardProps {
  calcId: number;
  jobName: JobName | undefined;
  portfolioList: Portfolio[];
  scenarioList: Scenario[];
  deleteCard: (calcId: number) => void;
  editMode?: boolean;
  newCard?: boolean;
  className?: string;
}
const defaultProps = {
  editMode: false,
  newCard: false,
  className: ''
};

const JobCard = (props: JobCardProps): JSX.Element => {
  const history = useHistory();

  const {
    editedCardPortfolios,
    setEditedCardPortfolios,
    editedCardScenarios,
    setEditedCardScenarios,
    defaultPortfolios,
    portfolios,
    setPortfolios,
    defaultScenarios,
    scenarios,
    setScenarios,
    editedCardJobName,
    setEditedCardJobName
  } = useContext(JobManagerDataContext);

  const [jobName, setJobName] = useState<string | undefined | null>('');
  const [portfolioList, setPortfolioList] = useState(props.portfolioList);
  const [scenarioList, setScenarioList] = useState(props.scenarioList);

  useEffect(() => {
    if (props.newCard) {
      setJobName(editedCardJobName);
    } else {
      setJobName(props.jobName);
    }
  }, [props.newCard, editedCardJobName, props.jobName]);

  useEffect(() => {
    if (props.newCard) {
      setPortfolioList(editedCardPortfolios);
    } else {
      setPortfolioList(props.portfolioList);
    }
  }, [props.newCard, editedCardPortfolios, props.portfolioList]);

  useEffect(() => {
    if (props.newCard) {
      setScenarioList(editedCardScenarios);
    } else {
      setScenarioList(props.scenarioList);
    }
  }, [props.newCard, editedCardScenarios, props.scenarioList]);

  return (
    <Wrapper
      className={props.className}
      editMode={props.editMode}
    >
      <NameWrapper editMode={props.editMode}>
        <NameInner editMode={props.editMode}>
          {
            props.newCard ? (
              <JobNameInput
                id="jobName"
                name="jobName"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setJobName(event.target.value);
                  setEditedCardJobName(event.target.value);
                }}
                placeholder="Job name"
                value={jobName as string}
                editMode={props.editMode}
              />
            ) : <NameTextWrapper>{props.jobName}</NameTextWrapper>
          }
          {
            props.editMode && !props.newCard ? (
              <React.Fragment>
                <JobNameButton
                  onClick={() => {
                    history.push(`/job-view/${props.calcId}`);
                  }}
                >
                  <Icons iconType="folder-details" size={17} />
                </JobNameButton>
                <JobNameButton
                  onClick={() => props.deleteCard(props.calcId)}
                >
                  <Icons iconType="trash" size={17} />
                </JobNameButton>
              </React.Fragment>
            ) : null
          }
        </NameInner>
      </NameWrapper>
      <ElementsWrapper editMode={props.editMode}>
        <Scrollbar small={!props.editMode}>
          <Droppable droppableId={`editModeJobs_${props.calcId}`}>
            {(provided, snapshot) => (
              <DroppableInner
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {
                  !portfolioList.length && !scenarioList.length && props.editMode ? (
                    <DragAndDropText>
                      Drag items from the top into this box
                    </DragAndDropText>
                  ) : null
                }
                {
                  portfolioList.map((portfolio, index) => (
                    <DraggableWrapper
                      small={!props.editMode}
                      key={`slider_${props.calcId}_portfolio_${portfolio.id}`}
                      draggableId={`slider_${props.calcId}_portfolio_${portfolio.id}`}
                      index={index}
                    >
                      <JobTile
                        draggable={props.newCard && props.editMode}
                        tileType={props.editMode ? 'secondary' : 'none'}
                        onDelete={props.newCard && props.editMode ? () => {
                          const deletedElement = defaultPortfolios.filter((el: Portfolio) => el.id === portfolio.id)[0];

                          const newPortfolioList = [...editedCardPortfolios];
                          newPortfolioList.splice(editedCardPortfolios.findIndex((el: Portfolio) => el.id === portfolio.id), 1);

                          setEditedCardPortfolios(newPortfolioList);

                          const newPortfolios = [...portfolios];

                          newPortfolios.push(deletedElement);
                          setPortfolios(newPortfolios.sort((a, b) => Number(b.id) - Number(a.id)));
                        } : null}
                      >
                        {portfolio.name}
                      </JobTile>
                    </DraggableWrapper>
                  ))
                }
                {
                  scenarioList.map((scenario, index) => (
                    <DraggableWrapper
                      small={!props.editMode}
                      key={`slider_${props.calcId}_portfolio_${scenario.id}`}
                      draggableId={`slider_${props.calcId}_portfolio_${scenario.id}`}
                      index={index}
                    >
                      <JobTile
                        draggable={props.newCard && props.editMode}
                        tileType={props.editMode ? 'primary' : 'none'}
                        onDelete={props.newCard && props.editMode ? () => {
                          const deletedElement = defaultScenarios.filter((el: Scenario) => el.id === scenario.id)[0];

                          const newScenarioList = [...editedCardScenarios];
                          newScenarioList.splice(editedCardPortfolios.findIndex((el: Portfolio) => el.id === scenario.id), 1);

                          setEditedCardScenarios(newScenarioList);

                          const newScenarios = [...scenarios];

                          newScenarios.push(deletedElement);
                          setScenarios(newScenarios.sort((a, b) => Number(b.id) - Number(a.id)));
                        } : null}
                      >
                        {scenario.name}
                      </JobTile>
                    </DraggableWrapper>
                  ))
                }
                {provided.placeholder}
              </DroppableInner>
            )}
          </Droppable>
        </Scrollbar>
      </ElementsWrapper>
    </Wrapper>
  );
};

JobCard.defaultProps = defaultProps;

export default JobCard;
