import React from 'react';

import Icons from 'components/ui/Icons';

import { IIconButtonProps } from 'components/ui/IconButton';
import {
  StyledIconButton
} from './SliderNavButton.styles';

export interface SliderNavButtonProps {
  navDirection: 'left' | 'right';
  onClick: () => void;
  className?: string;
}
const defaultProps = {
  className: ''
};

const SliderNavButton = (props: SliderNavButtonProps & IIconButtonProps): JSX.Element => (
  <StyledIconButton
    variant="outlined"
    color="default"
    className={props.className}
    onClick={props.onClick}
    size="small"
  >
    <Icons
      iconType={props.navDirection === 'right' ? 'chevron-right' : 'chevron-left'}
      size={11}
    />
  </StyledIconButton>
);

SliderNavButton.defaultProps = defaultProps;

export default SliderNavButton;
