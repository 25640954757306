import styled from '@emotion/styled';
import { css } from '@emotion/react';

interface WrapperProps {
  small: boolean | undefined;
}

const Wrapper = styled.div<WrapperProps>`
  & + & {
    margin-top: 10px;

    ${(props) => props.small && css`
      margin-top: 5px;
    `}
  }
`;

export {
  Wrapper
};
