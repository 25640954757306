import React from 'react';
import { FormikProps } from 'formik';
import _cloneDeep from 'lodash/cloneDeep';

import {
  Table,
  TableBody,
  TableHead
} from '@mui/material';

import {
  IPortfolioDetailsForm,
  AssetForForm,
  AssetsFilters
} from 'interfaces';

import FormHeading from 'components/ui/FormHeading';
import DroppableInner from 'components/ui/Box';
import Button from 'components/ui/Button';

import PortfolioAssetsFormTableRow from 'components/PortfolioAssetsFormTableRow';

import {
  Wrapper,
  StyledTableCell,
  HighlightBox,
  StyledInfoIconBox,
  StyledTableRow
} from './PortfolioAssetsForm.styles';

export interface PortfolioAssetsFormProps {
  assetsDictionaries: AssetsFilters;
  formikProps: FormikProps<any>;
  initialValues: IPortfolioDetailsForm;
}

const PortfolioAssetsForm = (props: PortfolioAssetsFormProps): JSX.Element => {
  const emptyRow: AssetForForm = {
    description: '',
    region: '',
    priceCurrency: '',
    assetClass: '',
    asset: '',
    quantity: ''
  };

  const getQuantitySum = (elements: AssetForForm[]) => elements
    .reduce((acc: number, current: AssetForForm) => acc + Number(current.quantity), 0);

  const addNewRow = (formikProps: FormikProps<{ holdings: AssetForForm[] }>) => {
    const newHoldings = _cloneDeep(formikProps.values.holdings);

    newHoldings.push(emptyRow);

    formikProps.setFieldValue('holdings', newHoldings, false);
  };

  return (
    <Wrapper>
      <FormHeading
        additionalText={
          props.initialValues.lastModified
            ? `Last update: ${props.initialValues.lastModified}`
            : null
        }
      >
        Portfolio Constituents
      </FormHeading>
      <DroppableInner>
        <Table>
          <TableHead>
            <StyledTableRow>
              <StyledTableCell>Asset name</StyledTableCell>
              <StyledTableCell>Currency</StyledTableCell>
              <StyledTableCell>Geography</StyledTableCell>
              <StyledTableCell>Class</StyledTableCell>
              <StyledTableCell>Asset</StyledTableCell>
              <StyledTableCell>Weight %</StyledTableCell>
              <StyledTableCell />
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {
              props.formikProps.values.holdings.map((asset: AssetForForm, index: number) => (
                <PortfolioAssetsFormTableRow
                  key={index}
                  assetsDictionaries={props.assetsDictionaries}
                  formikProps={props.formikProps}
                  index={index}
                  emptyRow={emptyRow}
                />
              ))
            }
            <StyledTableRow>
              <StyledTableCell colSpan={5}>
                <Button
                  size="small"
                  color="secondary"
                  variant="outlined"
                  onClick={() => addNewRow(props.formikProps)}
                >
                  Add
                </Button>
              </StyledTableCell>
              <StyledTableCell>
                <HighlightBox>
                  Total: <b>{getQuantitySum(props.formikProps.values.holdings)}%</b>
                  <StyledInfoIconBox title="Total value of weight" />
                </HighlightBox>
              </StyledTableCell>
              <StyledTableCell />
            </StyledTableRow>
          </TableBody>
        </Table>
      </DroppableInner>
    </Wrapper>
  );
};

export default PortfolioAssetsForm;
