import { createTheme } from '@material-ui/core/styles';
import { createTheme as newMuiCreateTheme } from '@mui/material/styles';
import { Theme } from '@emotion/react';
import _map from 'lodash/map';

import { colors, typography } from './basic';

const baseFontFamily = _map(typography.fontFamily, (el) => `${el}`).join(', ').replace(', Icons', '');

export interface ThemeColors {
  [colorName: string]: string;
}
export interface ThemeTypography {
  [colorName: string]: string | number;
}
export interface ThemeLayout {
  baseFontFamily: string;
  background: string;
  borderColor: string;
  boxShadow: string;
  padding: number;
  transition: string;
}
export interface ITheme {
  color: ThemeColors;
  colorGray: ThemeColors;
  colorMono: ThemeColors;
  colorText: ThemeColors;
  colorIcon: ThemeColors;
  fontFamily: ThemeTypography;
  fontSize: ThemeTypography;
  lineHeight: ThemeTypography;
  fontWeight: ThemeTypography;
  layout: ThemeLayout;
}

export const theme: Theme = {
  ...colors,
  ...typography,

  layout: {
    baseFontFamily,
    background: colors.color.gray,
    borderColor: '#c6c6c6',
    boxShadow: '.1rem .2rem .5rem 0 rgba(0 0 0 / 15%)',
    padding: 45,
    transition: 'all .3s ease-in-out'
  }
};

const materialTheme = {
  palette: {
    primary: {
      main: colors.color.secondary
    },
    secondary: {
      main: colors.color.primary
    },
    background: {
      default: theme.colorMono.white
    }
  },
  typography: {
    fontFamily: baseFontFamily
    // htmlFontSize: 10
  }
};

export const muiTheme = createTheme(materialTheme);

export const newMuiTheme = newMuiCreateTheme(materialTheme);
