import styled from '@emotion/styled';

import { Popper, MenuList, MenuItem } from '@mui/material';

import Box from 'components/ui/Box';

const HeaderButtons = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 3;
  display: flex;
  align-items: center;
  gap: 5px;
  height: 51px;
  padding: 0 10px;
  border-left: 1px solid #e3e3e3;
`;

const StyledBox = styled(Box)`
  padding: 0;
`;

const StyledPopper = styled(Popper)`
  z-index: 100;
`;

const StyledMenuList = styled(MenuList)`
  padding: 0;
`;

const StyledMenuItem = styled(MenuItem)`
  padding: 8px 15px;
  font-size: 14px;
  color: ${(props) => props.theme.colorText.secondary};
`;

export {
  HeaderButtons,
  StyledBox,
  StyledPopper,
  StyledMenuList,
  StyledMenuItem
};
