import React from 'react';

import api from 'config/api';

import { useAccessToken } from 'auth/useAccessToken';

const AxiosWithAuthProvider = (): null => {
  const accessToken = useAccessToken();

  api.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

  return null;
};

export default AxiosWithAuthProvider;
