import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AppDispatch, RootState } from 'interfaces';

import { SCENARIO_EXPLORER_FILTERS_ID } from 'constant';

import defaultChart from 'data/defaultChart.json';

import Layout from 'components/Layout';
import ScenarioManager from 'components/ScenarioManager';
import ScenarioExplorerFilters from 'components/ScenarioExplorerFilters';
import DefaultChart from 'components/DefaultChart';

import { getScenarios, resetRefresh } from 'features/scenarioManager/scenarioManagerSlice';

const ScenarioExplorer = (): JSX.Element => {
  const dispatch = useDispatch<AppDispatch>();

  const [layoutReady, setLayoutReady] = useState(false);

  const refreshList = useSelector((state: RootState) => state.scenarioManager.refreshList);

  useEffect(() => {
    // @ts-ignore
    dispatch(getScenarios());
    dispatch(resetRefresh());
  }, [dispatch, refreshList]);

  const scenarios = useSelector((state: RootState) => state.scenarioManager.scenarios);

  return (
    <Layout
      withSidePanel
      sidePanelId={SCENARIO_EXPLORER_FILTERS_ID}
      sidePanelElement={(sidePanelElementProps: any) => (
        <ScenarioExplorerFilters {...sidePanelElementProps} />
      )}
      paddingSide="left"
      setLayoutReady={setLayoutReady}
      withPageButtons={false}
    >
      {
        layoutReady ? (
          <DefaultChart chartData={defaultChart} />
        ) : null
      }
      <ScenarioManager
        scenarios={scenarios}
        withSearch
        subscribeButton
        onSubscribe={(id: any) => {
          // @TODO: subscribe
        }}
      />
    </Layout>
  );
};

export default ScenarioExplorer;
