import api from 'config/api';
import { DashboardDataSet } from 'interfaces';
import { DASHBOARD_ANALYTICS_DATA_SET_TYPES } from 'constant';

export const fetchSimCharts = (jobId: number) => (
  api.get(`/api/simcharts/${jobId}`)
);

export const fetchSimData = (
  jobId: number,
  dataSet: DashboardDataSet = DASHBOARD_ANALYTICS_DATA_SET_TYPES.summary
) => (
  api.get(`/api/simdata/${jobId}?dataset=${dataSet}`)
);
