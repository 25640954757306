import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { IconButton } from '@mui/material';

import { hexToRgbMixin } from 'styles/mixins';

const StyledIconButton = styled(IconButton)<any>`
  display: flex;
  align-items: center;
  padding: 0;
  border: 1px solid;
  border-radius: 0;
  box-shadow: none;
  font-size: 14px;
  line-height: 20px;
  color: ${(props) => props.theme.colorMono.white};
  text-transform: none;
  transition: ${(props) => props.theme.layout.transition};

  &.MuiIconButton-colorPrimary,
  .MuiIconButton-label {
    color: ${(props) => props.theme.colorMono.white};
    transition: ${(props) => props.theme.layout.transition};
  }

  &:hover,
  &:active,
  &:focus,
  &:disabled,
  &:disabled:hover,
  &.Mui-disabled,
  &.Mui-disabled:hover {
    box-shadow: none;
  }

  ${(props) => props.variant === 'contained' && props.color === 'primary' && css`
    border-color: ${props.theme.color.primary};
    background: ${props.theme.color.primary};

    &:hover {
      border-color: ${props.theme.color.primaryHover};
      background: ${props.theme.color.primaryHover};
    }
    &:focus {
      border-color: ${props.theme.color.primaryFocus};
    }
    &:active {
      border-color: ${props.theme.color.primaryActive};
      background: ${props.theme.color.primaryActive};
    }
    &:disabled,
    &:disabled:hover,
    &.Mui-disabled,
    &.Mui-disabled:hover {
      border-color: #c6c6c6;
      background: #c6c6c6;
      color: #8d8d8d;
    }
  `};

  ${(props) => props.variant === 'contained' && props.color === 'secondary' && css`
    border-color: ${props.theme.color.secondary};
    background: ${props.theme.color.secondary};

    &:hover {
      border-color: ${props.theme.color.secondaryHover};
      background: ${props.theme.color.secondaryHover};
    }
    &:focus {
      border-color: ${props.theme.color.secondaryFocus};
    }
    &:active {
      border-color: ${props.theme.color.secondaryActive};
      background: ${props.theme.color.secondaryActive};
    }
    &:disabled,
    &:disabled:hover,
    &.Mui-disabled,
    &.Mui-disabled:hover {
      border-color: #c6c6c6;
      background: #c6c6c6;
      color: #8d8d8d;
    }
  `};

  ${(props) => props.variant === 'outlined' && props.color === 'primary' && css`
    border-color: ${props.theme.colorText.primary};
    color: ${props.theme.colorText.primary};

    &:hover {
      border-color: ${props.theme.colorText.primary};
      background: ${hexToRgbMixin(props.theme.colorText.primary, 0.1)};
    }

    &:disabled,
    &:disabled:hover,
    &.Mui-disabled,
    &.Mui-disabled:hover {
      border-color: #c6c6c6;
      color: #adadad;
    }

    .MuiIconButton-label {
      color: ${props.theme.colorText.primary};
    }
  `};

  ${(props) => props.variant === 'outlined' && props.color === 'secondary' && css`
    border-color: ${props.theme.color.secondary};
    color: ${props.theme.color.secondary};

    &:hover {
      border-color: ${props.theme.color.secondary};
      background: ${hexToRgbMixin(props.theme.color.secondary, 0.1)};
    }
    &:disabled,
    &:disabled:hover,
    &.Mui-disabled,
    &.Mui-disabled:hover {
      border-color: #c6c6c6;
      color: #adadad;
    }

    .MuiIconButton-label {
      color: ${props.theme.color.secondary};
    }
  `};

  ${(props) => props.variant === 'outlined' && props.color === 'default' && css`
    border-color: #c6c6c6;
    color: ${props.theme.colorText.primary};

    &:hover {
      border-color: #c6c6c6;
      background: ${hexToRgbMixin('#9c9c9c', 0.1)};
    }
    &:disabled,
    &:disabled:hover,
    &.Mui-disabled,
    &.Mui-disabled:hover {
      border-color: #c6c6c6;
      color: #adadad;
    }

    .MuiIconButton-label {
      color: ${props.theme.colorText.primary};
    }
  `};

  ${(props) => props.variant === 'text' && props.color === 'default' && css`
    border: 0;
    background: ${props.theme.colorMono.white};
    color: ${props.theme.colorText.primary};

    &:hover {
      background: ${props.theme.color.secondary};
      color: ${props.theme.colorMono.white};

      .MuiIconButton-label {
        color: ${props.theme.colorMono.white};
      }
    }

    &:disabled,
    &:disabled:hover,
    &.Mui-disabled,
    &.Mui-disabled:hover {
      border: 1px solid #e0dede;
      color: #adadad;

      .MuiIconButton-label {
        color: #adadad;
      }
    }

    .MuiIconButton-label {
      color: ${props.theme.colorText.primary};
    }
  `};

  ${(props) => props.size === 'small' && css`
    width: 32px;
    height: 32px;
  `};
  ${(props) => props.size === 'medium' && css`
    width: 40px;
    height: 40px;
  `};
  ${(props) => props.size === 'large' && css`
    width: 48px;
    height: 48px;
  `};
`;

export {
  StyledIconButton
};
