import React from 'react';

import {
  IconWrapper
} from './Icons.styles';

export type IconsTypes = 'logo' | 'user-filled' | 'closed-filled' | 'checkmark-filled'
| 'warning-filled' | 'delete' | 'chevron-top-down' | 'chevron-down' | 'chevron-up'
| 'chevron-right' | 'chevron-right-light' | 'drug-and-drop' | 'calendar' | 'settings'
| 'edit' | 'folder-details' | 'trash' | 'more-option' | 'info' | 'plus'
| 'checkmark-thick' | 'checkmark' | 'nested' | 'minimize' | 'maximize' | 'arrow-right'
| 'view' | 'chevron-left' | 'chevron-left-light' | 'checkbox-blank-outline' | 'checkbox-marked'
| 'checkbox-minus' | 'curve-arrow' | 'create' | 'filter' | 'bullet-list' | 'pin' | 'content-copy'
| 'content-paste' | 'download' | 'file-excel-outline' | 'file-outline' | 'search';

export interface IconsProps {
  iconType: IconsTypes;
  size?: number;
  color?: string;
  className?: string;
}
const defaultProps = {
  size: 20,
  color: null,
  className: ''
};

const Icons = (props: IconsProps) => (
  <React.Fragment>
    <IconWrapper
      className={props.className}
      iconType={props.iconType}
      size={props.size}
      color={props.color}
    />
  </React.Fragment>
);

Icons.defaultProps = defaultProps;

export default Icons;
