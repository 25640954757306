import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { TileProps } from 'components/ui/Tile';

const Wrapper = styled.div<TileProps>`
  flex: 1 1 auto;

  ${(props) => !props.noStyle && css`
    background: ${props.theme.colorMono.white};
    border-left: 2px solid ${props.theme.color.secondary};
    padding: 15px;
  `};

  ${(props) => props.size === 'none' && css`
    max-width: 100%;
    width: calc(50% - 20px * 2);

    ${props.fullWidth && css`
      max-width: 100%;
      min-width:  100%;
    `};
  `};
  ${(props) => props.size === 'big' && css`
    max-width: 60%;
    min-width: calc(60% - 2 * 20px);
  `};
  ${(props) => props.size === 'medium' && css`
    max-width: 38%;
    min-width: 38%;
  `};
  ${(props) => props.size === 'small' && css`
    max-width: 20%;
    min-width: calc(20% - 2 * 20px);
  `};
`;

export {
  Wrapper
};
