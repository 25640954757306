import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import {
  Asset,
  AssetCurve,
  RootState,
  ScenarioViewData
} from 'interfaces';

import { fetchScenario, fetchForecasts } from 'api';

import {
  changeNumbersToStrings,
  createMonthData,
  getAssetName,
  getMinMaxLabel,
  getMetricsLabel,
  getUniqueComponents,
  getUniqueComponentsWithMinMaxPair
} from 'utils/scenario';

const initialState: ScenarioViewData = {
  scenarioDetails: {
    name: null,
    description: null,
    scenarioType: null,
    creationDate: null,
    publishDate: null,
    tags: []
  },
  scenarioForecast: {
    components: [],
    forecastDate: null
  },
  loading: false
};

export const getScenario = createAsyncThunk<{
  assetsPayloads: Asset[], assetsCurves: AssetCurve[], scenario: any, forecast: any
}, { scenarioId: number }, { state: RootState }>(
  'scenarioView/getScenario',
  async ({ scenarioId }, { getState }) => fetchScenario(scenarioId)
    .then((scenarioResponse: any) => {
      const forecastId = scenarioResponse.components[0].forecastSerialID;

      return fetchForecasts(forecastId)
        .then((forecastResponse: any) => {
          const { assetsPayloads, assetsCurves } = getState().assets;

          return {
            assetsPayloads,
            assetsCurves,
            scenario: scenarioResponse,
            forecast: forecastResponse
          };
        });
    })
);

const scenarioView = createSlice({
  name: 'scenarioView',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getScenario.pending, (state) => ({
        ...state,
        loading: true
      }))
      .addCase(getScenario.fulfilled, (state, action) => {
        const { assetsPayloads, assetsCurves, scenario, forecast } = action.payload;

        const components: any[] = [];

        forecast?.components.forEach((el: any, index: number) => {
          components.push({
            id: index + 1,
            minMaxLabel: getMinMaxLabel(el.componentType),
            componentType: el.componentType,
            metricType: el.metricType,
            metricTypeLabel: getMetricsLabel(el.metricType),
            assetId: el.singleIndexID,
            assetName: getAssetName(assetsPayloads, el.singleIndexID),
            ...createMonthData(assetsCurves, el.targetDate, el.value, el.singleIndexID, el.metricType, true)
          });
        });

        const uniqueComponents: any[] = getUniqueComponents(assetsCurves, components, true);

        const uniqueComponentsWithMaxMinPair: any[] = getUniqueComponentsWithMinMaxPair(
          uniqueComponents,
          assetsCurves
        );

        return {
          ...state,
          scenarioDetails: {
            name: scenario?.name,
            description: scenario?.description,
            scenarioType: scenario?.themeType,
            publishDate: scenario?.publishDate,
            creationDate: scenario?.creationDate,
            tags: scenario?.hashTags.map((el: any) => el.tag)
          },
          scenarioForecast: {
            components: changeNumbersToStrings(uniqueComponentsWithMaxMinPair),
            forecastDate: forecast?.forecastDate
          },
          loading: false
        };
      })
      // @TODO: Handle error
      .addCase(getScenario.rejected, (state) => ({
        ...state,
        loading: false
      }));
  }
});

export default scenarioView.reducer;
