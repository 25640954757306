import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { AppDispatch, RootState } from 'interfaces';

import Loader from 'components/ui/Loader';

import Layout from 'components/Layout';
import JobViewComponent from 'components/JobView';

import { getCalcSettings, getScenarioSets, getPortfolios } from './jobViewSlice';

const JobView = (): JSX.Element => {
  const { calcId }: any = useParams();

  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    if (calcId) {
      dispatch(getCalcSettings({ calcId }));
    }
  }, [dispatch, calcId]);

  const jobName = useSelector((state: RootState) => state.jobView.jobName);
  const scenarioSets = useSelector((state: RootState) => state.jobView.scenarioSets);
  const portfolios = useSelector((state: RootState) => state.jobView.portfolios);

  useEffect(() => {
    if (scenarioSets.length) {
      dispatch(getScenarioSets({ scenarioSets }));
    }
  }, [dispatch, scenarioSets]);

  useEffect(() => {
    if (portfolios.length) {
      dispatch(getPortfolios({ portfolios }));
    }
  }, [dispatch, portfolios]);

  const portfolioList = useSelector((state: RootState) => state.jobView.portfolioList);
  const scenarioList = useSelector((state: RootState) => state.jobView.scenarioList);
  const loading = useSelector((state: RootState) => state.jobView.loading);

  return (
    <Layout>
      {
        !loading ? (
          <JobViewComponent
            jobName={jobName}
            portfolioList={portfolioList}
            scenarioList={scenarioList}
          />
        ) : (
          <Loader loaderType="static" />
        )
      }
    </Layout>
  );
};

export default JobView;
