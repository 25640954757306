import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { Swiper, SwiperSlide } from 'swiper/react';

import SliderNavButton from 'components/ui/SliderNavButton';

interface EmblaWrapperProps {
  layoutWidth: number | null;
}
interface StyledJobProps {
  layoutWidth?: number | null;
  editMode?: boolean | null;
}
interface StyledSwiperProps {
  transformValue?: number | null;
}

const Wrapper = styled.div`
  position: relative;
  margin-top: 45px;
`;

const SliderWrapper = styled.div<EmblaWrapperProps>`
  position: relative;
  overflow: hidden;
  max-width: 0;

  ${(props) => props.layoutWidth && css`
    max-width: ${props.layoutWidth}px;
  `};
`;

const SliderViewport = styled.div`
  overflow: hidden;
  width: 100%;
`;

const SliderNavButtonStyles = css`
  position: absolute;
  top: 50%;
  z-index: 3;
  transform: translateY(-50%);
`;

const StyledSwiper = styled(Swiper)<StyledSwiperProps>`
  display: flex;

  .swiper-wrapper {
    box-sizing: border-box;
    // important is required
    transform: translate3d(${(props) => props.transformValue}px, 0, 0) !important;
  }
`;

const StyledSwiperSlide = styled(SwiperSlide)<StyledJobProps>`
  flex: 0 0 auto;

  ${(props) => props.layoutWidth && css`
    // 8px = flex gap
    // important is required
    width: calc(((${props.layoutWidth}px - (${props.layoutWidth}px / 2)) / 2) - 8px) !important;

    ${props.editMode && css`
      width: calc(${props.layoutWidth}px / 2) !important;
    `};
  `};
`;

const PrevButton = styled(SliderNavButton)`
  ${SliderNavButtonStyles};
  left: -16px;
`;

const NextButton = styled(SliderNavButton)`
  ${SliderNavButtonStyles};
  right: -16px;
`;

export {
  Wrapper,
  SliderWrapper,
  SliderViewport,
  StyledSwiper,
  StyledSwiperSlide,
  PrevButton,
  NextButton
};
