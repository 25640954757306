import { AssetCurve, MetricType } from 'interfaces';

import { COMPONENTS_MONTH_PREFIX } from 'constant';

import { getAssetValue } from './getAssetValue';

const getCorrectValue = (
  assetsCurves: AssetCurve[],
  metricType: MetricType,
  id: string,
  label: string,
  valueOne: any, valueTwo: any
) => {
  const labelId = Number(label.replace(COMPONENTS_MONTH_PREFIX, ''));

  if (Number.isNaN(labelId) || valueOne === valueTwo) {
    return valueTwo || valueOne;
  }

  if (valueOne !== getAssetValue(assetsCurves, metricType, labelId, id)) {
    return valueOne;
  }
  if (valueTwo !== getAssetValue(assetsCurves, metricType, labelId, id)) {
    return valueTwo;
  }

  return null;
};

export const getUniqueComponents = (
  assetsCurves: AssetCurve[],
  components: any[],
  edit: boolean
): any[] => components.reduce((acc, current) => {
  const uniqueArray = [...acc];
  const currentDataInArrayIndex = acc.findIndex(
    (el: any) => el.assetName === current.assetName && el.componentType === current.componentType
  );

  if (currentDataInArrayIndex > -1) {
    const notUniqueElement = acc[currentDataInArrayIndex];
    const newUniqueObject: any = {};

    Object.entries(notUniqueElement).forEach(([notUniqueElementKey, notUniqueElementValue]) => {
      Object.entries(current).forEach(([currentKey, currentValue]) => {
        if (currentKey === notUniqueElementKey) {
          const valueWhiteEditing = currentValue === null ? notUniqueElementValue : currentValue;
          newUniqueObject[currentKey] = edit
            ? valueWhiteEditing
            : getCorrectValue(
              assetsCurves,
              current.metricType,
              current.assetID,
              currentKey,
              notUniqueElementValue,
              currentValue
            );
        }
      });
    });

    uniqueArray[currentDataInArrayIndex] = newUniqueObject;
  } else {
    uniqueArray.push(current);
  }

  return uniqueArray;
}, []);
