import styled from '@emotion/styled';

const ChipsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
`;

export {
  ChipsWrapper
};
