import styled from '@emotion/styled';
import { css } from '@emotion/react';

import TextField from 'components/ui/TextField';

type SelectWrapperProps = {
  fullWidth: boolean | undefined;
};
type StyledTextFieldProps = {
  color?: 'primary' | 'secondary' | undefined;
};

const SelectWrapper = styled.div<SelectWrapperProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  ${(props) => props.fullWidth && css`
    width: 100%;
  `};

  input {
    cursor: pointer;
  }
`;

// @ts-ignore
const StyledTextField = styled(TextField)<StyledTextFieldProps>`
  & .MuiTextField-root {
    min-width: 180px;
  }

  ${(props) => props.color === 'primary' && css`
    & .MuiSelect-icon {
      margin-top: 9px;
      margin-right: 12px;
    }
  `};
  ${(props) => props.color === 'secondary' && css`
    & .MuiInputBase-root {
      border-color: transparent;
      border-radius: 0;
      background: transparent;
      color: ${props.theme.color.primary};

      &.Mui-focused {
        border-color: transparent;
        border-bottom-color: ${props.theme.color.primary};
        background: transparent;
      }
    }
    & .MuiSelect-icon {
      margin-top: 9px;
      margin-right: 8px;
      color: ${props.theme.color.primary};
    }
    & .MuiInputBase-root {
      &.Mui-error {
        border-color: transparent;
        border-bottom-color: ${props.theme.color.error};
      }
    }
  `};

  .MuiSelect-select:focus {
    background: transparent;
  }
`;

export {
  SelectWrapper,
  StyledTextField
};
