import React from 'react';

import Icons from 'components/ui/Icons';
import TextField, { ITextFieldProps } from 'components/ui/TextField';

import {
  StyledStaticDatePicker
} from './StaticDatePicker.styles';

export interface IStaticDatePickerProps extends Omit<ITextFieldProps, 'onChange'> {
  disableFuture?: boolean;
  disablePast?: boolean;
  openTo?: 'day' | 'month' | 'year';
  onChange?: any;
}
const defaultProps = {
  disableFuture: false,
  disablePast: false,
  openTo: 'day',
  onChange: () => {}
};

const StaticDatePicker = (props: IStaticDatePickerProps): JSX.Element => (
  <StyledStaticDatePicker
    displayStaticWrapperAs="desktop"
    openTo={props.openTo}
    value={props.value}
    onChange={props.onChange}
    // @ts-ignore
    renderInput={(params: ITextFieldProps) => <TextField {...params} />}
    components={{
      OpenPickerIcon: (iconProps) => <Icons iconType="calendar" size={16} {...iconProps} />,
      LeftArrowIcon: (iconProps) => <Icons iconType="chevron-left-light" size={12} {...iconProps} />,
      RightArrowIcon: (iconProps) => <Icons iconType="chevron-right-light" size={12} {...iconProps} />,
      SwitchViewIcon: (iconProps) => <Icons iconType="chevron-down" size={7} {...iconProps} />
    }}
  />
);

StaticDatePicker.defaultProps = defaultProps;

export default StaticDatePicker;
