import { percentRenderer, numberRenderer } from 'utils/tableFormatters';

export const summaryColumnDefs = [
  {
    field: 'meanAnnualized',
    headerName: 'Mean annualized',
    flex: 1,
    minWidth: 180,
    maxWidth: 200,
    cellRenderer: percentRenderer
  },
  {
    field: 'volAnnualized',
    headerName: 'Volatility annualized',
    flex: 1,
    minWidth: 200,
    maxWidth: 220,
    cellRenderer: percentRenderer
  },
  {
    field: 'vaR95',
    headerName: 'VAR95%',
    flex: 1,
    minWidth: 130,
    maxWidth: 200,
    cellRenderer: percentRenderer
  },
  {
    field: 'vaR99',
    headerName: 'VAR99%',
    flex: 1,
    minWidth: 130,
    maxWidth: 200,
    cellRenderer: percentRenderer
  },
  {
    field: 'cVaR95',
    headerName: 'CVAR95%',
    flex: 1,
    minWidth: 130,
    maxWidth: 200,
    cellRenderer: percentRenderer
  },
  {
    field: 'cVaR99',
    headerName: 'CVAR99%',
    flex: 1,
    minWidth: 130,
    maxWidth: 200,
    cellRenderer: percentRenderer
  },
  {
    field: 'mean',
    headerName: 'Mean',
    flex: 1,
    minWidth: 110,
    maxWidth: 200,
    cellRenderer: percentRenderer
  },
  {
    field: 'vol',
    headerName: 'Volatility',
    flex: 1,
    minWidth: 135,
    maxWidth: 200,
    cellRenderer: percentRenderer
  },
  {
    field: 'min',
    headerName: 'Min',
    flex: 1,
    minWidth: 120,
    maxWidth: 200,
    cellRenderer: percentRenderer
  },
  {
    field: 'max',
    headerName: 'Max',
    flex: 1,
    minWidth: 120,
    maxWidth: 200,
    cellRenderer: percentRenderer
  },
  {
    field: 'percentile05',
    headerName: 'Percentile 05',
    flex: 1,
    minWidth: 155,
    maxWidth: 200,
    cellRenderer: percentRenderer
  },
  {
    field: 'percentile25',
    headerName: 'Percentile 25',
    flex: 1,
    minWidth: 155,
    maxWidth: 200,
    cellRenderer: percentRenderer
  },
  {
    field: 'percentile50',
    headerName: 'Percentile 50',
    flex: 1,
    minWidth: 155,
    maxWidth: 200,
    cellRenderer: percentRenderer
  },
  {
    field: 'percentile75',
    headerName: 'Percentile 75',
    flex: 1,
    minWidth: 155,
    maxWidth: 200,
    cellRenderer: percentRenderer
  },
  {
    field: 'percentile95',
    headerName: 'Percentile 95',
    flex: 1,
    minWidth: 155,
    maxWidth: 200,
    cellRenderer: percentRenderer
  },
  {
    field: 'skewness',
    headerName: 'Skewness',
    flex: 1,
    minWidth: 135,
    maxWidth: 200,
    cellRenderer: numberRenderer
  }
];
