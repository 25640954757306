import React, { useMemo, useState } from 'react';

import { AgGridReact } from 'ag-grid-react';

import {
  Wrapper
} from './SimpleTable.styles';

export interface ColumnDefs {
  field: string;
  editable?: boolean;
  sortable?: boolean;
  resizable?: boolean;
}
export interface SimpleTableProps {
  tableColumnDefs: ColumnDefs[];
  tableData: any;
  className?: string;
  colorPrimary?: boolean;
}
const defaultProps = {
  className: '',
  colorPrimary: true
};

const SimpleTable = (props: SimpleTableProps): JSX.Element => {
  const defaultColDef = useMemo(() => ({
    initialWidth: 100,
    width: 100,
    resizable: false,
    editable: false,
    sortable: true,
    flex: 1
  }), []);

  const columnDefs = props.tableColumnDefs;

  const [rowData] = useState(props.tableData);

  return (
    <Wrapper className={props.className} colorPrimary={props.colorPrimary}>
      <AgGridReact
        className="ag-theme-alpine"
        rowData={rowData}
        defaultColDef={defaultColDef}
        columnDefs={columnDefs}
        rowHeight={48}
        headerHeight={38}
      />
    </Wrapper>
  );
};

SimpleTable.defaultProps = defaultProps;

export default SimpleTable;
