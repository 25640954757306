import React from 'react';

import { ChipProps } from '@material-ui/core';

import {
  StyledChip
} from './Chip.styles';

const Chip = (props: ChipProps): JSX.Element => (
  <StyledChip
    {...props}
  />
);

export default Chip;
