import React from 'react';

import { MAIN_HEADER_ID } from 'constant';
import { navigation, SubNavigation } from 'config/navigation';

import {
  StyledBox,
  StyledContainer,
  Title,
  UserWrapper,
  StyledLink,
  StyledIcons
} from './Header.styles';

export interface HeaderProps {
  menuOpen: boolean;
}

const Header = (props: HeaderProps): JSX.Element => {
  const currentRoutingLink = window.location.pathname.split('/');

  const getTitle = () => {
    let title = '';

    navigation.forEach((el) => {
      const activeItem: SubNavigation = el.subNavigation
        .filter((el: any) => el.link.replace('/', '') === currentRoutingLink[1])?.[0];

      if (activeItem) {
        title = `${activeItem.name} ${currentRoutingLink[2] ? `no. ${currentRoutingLink[2]}` : ''}`;
      }
    });

    return title;
  };

  return (
    <StyledBox id={MAIN_HEADER_ID} menuOpen={props.menuOpen}>
      <StyledContainer>
        <Title>{getTitle()}</Title>
        <UserWrapper>
          <StyledLink to="/logout">
            <StyledIcons iconType="user-filled" size={36} />
            Logout
          </StyledLink>
        </UserWrapper>
      </StyledContainer>
    </StyledBox>
  );
};

export default Header;
