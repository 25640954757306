import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { useAuth0 } from '@auth0/auth0-react';

import AuthNotification from 'auth/AuthNotification';
import AuthPage from 'auth/AuthPage';

import Button from 'components/ui/Button';
import Loader from 'components/ui/Loader';
import Typography from 'components/ui/Typography';

import {
  StyledIcons
} from './Login.styles';

const Login = (): JSX.Element => {
  const history = useHistory();

  const { loginWithRedirect, isLoading } = useAuth0();

  const [loader] = useState<boolean>(false);
  const [notification] = useState<any>(null);

  const loginSsoApi = async () => {
    await loginWithRedirect();
  };

  return (
    <AuthPage
      title="Log In"
      footer={(
        <React.Fragment>
          <Typography withMarginBottom>
            Do you have an Activation Code?
          </Typography>
          <Button
            color="primary"
            onClick={() => history.push('/register')}
            fullWidth
          >
            Activate Registration
          </Button>
        </React.Fragment>
      )}
      footerWithBorder
    >
      {
        notification && notification?.length ? (
          <AuthNotification
            severity={notification?.[0]}
            message={notification?.[1]}
          />
        ) : null
      }
      {
        isLoading ? (
          <Loader loaderType="static" />
        ) : null
      }
      {
        !notification && !loader ? (
          <Button
            color="secondary"
            onClick={loginSsoApi}
            fullWidth
          >
            SSO LOGIN
            <StyledIcons iconType="arrow-right" size={14} />
          </Button>
        ) : null
      }
    </AuthPage>
  );
};

export default Login;
