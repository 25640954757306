import styled from '@emotion/styled';
import { css } from '@emotion/react';

interface WrapperProps {
  colorPrimary: boolean | undefined;
}

const Wrapper = styled.div<WrapperProps>`
  height: 100%;

  .ag-theme-alpine .ag-root-wrapper {
    border: 0;
  }
  .ag-root-wrapper-body.ag-layout-normal {
    height: auto;
    min-height: auto;
    margin: 0;
  }
  .ag-root-wrapper.ag-layout-normal {
    height: auto;
  }
  .ag-header {
    background: ${(props) => props.theme.color.secondary};

    ${(props) => props.colorPrimary && css`
      background: ${props.theme.color.primary};
    `};
  }
  .ag-header-cell {
    padding-right: 25px;
    padding-left: 25px;
    font-size: 14px;
    color: ${(props) => props.theme.colorMono.white};
  }
  .ag-theme-alpine .ag-cell {
    display: block;
    padding: 10px 25px;
    border-right: 1px solid #e8e8e8;
    background: ${(props) => props.theme.colorMono.white};
    font-size: 14px;
    line-height: 2;
    :first-of-type {
      border-left: 1px solid #e8e8e8;
    }
  }
  .ag-header-cell-menu-button {
    display: none;
  }
  .ag-header-cell-label {
    align-items: center;
  }
  .ag-theme-alpine .ag-icon {
    opacity: 1;
  }
  .ag-cell-label-container {
    // default
    .ag-sort-indicator-container {
      &::before {
        content: '\\e906';
        padding-top: 3px;
        padding-left: 6px;
        font-family: ${(props) => props.theme.fontFamily.icons}, sans-serif;
        font-size: 15px;
      }
    }
    .ag-sort-indicator-icon .ag-icon {
      font-family: ${(props) => props.theme.fontFamily.icons}, sans-serif;
      font-size: 6px;
    }

    &.ag-header-cell-sorted-asc,
    &.ag-header-cell-sorted-desc {
      .ag-sort-indicator-container::before {
        content: normal;
      }
    }
    // asc
    &.ag-header-cell-sorted-asc {
      .ag-sort-indicator-icon .ag-icon::before {
        content: '\\e907';
      }
    }
    // desc
    &.ag-header-cell-sorted-desc {
      .ag-sort-indicator-icon .ag-icon::before {
        content: '\\e908';
      }
    }
  }
  .ag-ltr .ag-cell-focus:not(.ag-cell-range-selected):focus-within,
  .ag-ltr .ag-context-menu-open .ag-cell-focus:not(.ag-cell-range-selected),
  .ag-ltr .ag-full-width-row.ag-row-focus:focus .ag-cell-wrapper.ag-row-group,
  .ag-ltr .ag-cell-range-single-cell,
  .ag-ltr .ag-cell-range-single-cell.ag-cell-range-handle,
  .ag-rtl .ag-cell-focus:not(.ag-cell-range-selected):focus-within,
  .ag-rtl .ag-context-menu-open .ag-cell-focus:not(.ag-cell-range-selected),
  .ag-rtl .ag-full-width-row.ag-row-focus:focus .ag-cell-wrapper.ag-row-group,
  .ag-rtl .ag-cell-range-single-cell,
  .ag-rtl .ag-cell-range-single-cell.ag-cell-range-handle {
    border-color: transparent;
    border-right-color: #e8e8e8;
    box-shadow: none;
  }
  .ag-theme-alpine .ag-filter-toolpanel-header,
  .ag-theme-alpine .ag-filter-toolpanel-search,
  .ag-theme-alpine .ag-status-bar,
  .ag-theme-alpine .ag-header-row,
  .ag-theme-alpine .ag-panel-title-bar-title,
  .ag-theme-alpine .ag-multi-filter-group-title-bar {
    font-weight: 400;
  }
`;

export {
  Wrapper
};
