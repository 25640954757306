import api from 'config/api';

export const fetchForecasts = (id: number) => (
  api.get(`/api/forecasts/${id}`)
);

export const sendForecasts = (scenarioData: any) => (
  api.post('/api/forecasts', scenarioData)
);

export const updateForecasts = (id: number | null, scenarioData: any) => (
  api.put(`/api/forecasts/${id}`, scenarioData)
);

export const sendScenario = (scenarioData: any) => (
  api.post('/api/scenarios', scenarioData)
);

export const updateScenario = (id: number, scenarioData: any) => (
  api.put(`/api/scenarios/${id}`, scenarioData)
);

export const fetchScenarios = () => (
  api.get('/api/scenarios')
);

export const fetchScenario = (id: number) => (
  api.get(`/api/scenarios/${id}`)
);

export const removeScenario = (id: number) => (
  api.delete(`/api/scenarios/${id}`)
);

export const sendScenarioSet = (data: any) => (
  api.post('/api/scenariosets', data)
);

export const updateScenarioSet = (calcId: number, data: any) => (
  api.put(`/api/scenariosets/${calcId}`, data)
);

export const fetchScenarioSet = (id: number) => (
  api.get(`/api/scenariosets/${id}`)
);
