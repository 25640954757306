import React from 'react';

import { useTheme } from '@emotion/react';

import {
  Wrapper,
  StyledDragIcons,
  DeleteButton,
  StyledDeleteIcons
} from './JobTile.styles';

export interface JobTileProps {
  children: React.ReactNode;
  className?: string;
  tileType?: 'primary' | 'secondary' | 'default' | 'none';
  draggable?: boolean;
  small?: boolean;
  onDelete?: (() => void) | null | undefined
}
const defaultProps = {
  className: '',
  tileType: 'none',
  draggable: false,
  small: false,
  onDelete: null
};

const JobTile = (props: JobTileProps): JSX.Element => {
  const theme = useTheme();

  return (
    <Wrapper
      className={props.className}
      tileType={props.tileType}
      small={props.small}
    >
      {
        props.draggable ? (
          <StyledDragIcons iconType="drug-and-drop" size={13} color={theme.colorIcon.primary} />
        ) : null
      }
      {props.children}
      {
        props.onDelete ? (
          <DeleteButton onClick={(event: any) => {
            event.preventDefault();

            props.onDelete?.();
          }}
          >
            <StyledDeleteIcons iconType="closed-filled" size={14} />
          </DeleteButton>
        ) : null
      }
    </Wrapper>
  );
};

JobTile.defaultProps = defaultProps;

export default JobTile;
