import React, { useState, useEffect } from 'react';

import {
  Asset,
  AssetCurve,
  Components,
  IScenarioDetails,
  ScenarioDetailsForm
} from 'interfaces';

import {
  DEFAULT_SCENARIO_TYPE,
  FORECAST_SELECT_TYPES_IDS,
  SET_FORMAT_INPUTS_RETURN_ID, SET_FORMAT_INPUTS_RETURN_RANGE_ID,
  SET_FORMAT_INPUTS_VOLATILITY_ID,
  SET_FORMAT_INPUTS_VOLATILITY_RANGE_ID
} from 'constant';

import { isDataTableNotInDefaultValues } from 'utils/scenario';

import Button from 'components/ui/Button';
import Icons from 'components/ui/Icons';
import FormHeading from 'components/ui/FormHeading';

import ScenarioForecastInputs from 'components/ScenarioForecastInputs';
import ScenarioDetails from 'components/ScenarioDetails';
import ScenarioForecast from 'components/ScenarioForecast';
import PageButtonsWrapper from 'components/PageButtonsWrapper';

import {
  ScenarioForecastInputsWrapper,
  ErrorMessage
} from './CreateScenario.styles';

export interface CreateScenarioProps {
  assetsPayloads: Asset[];
  assetsCurves: AssetCurve[];
  scenarioDetails: IScenarioDetails;
  returnTableData: Components[];
  returnRangeTableData: Components[];
  volatilityTableData: Components[];
  volatilityRangeTableData: Components[];
  initialValues: ScenarioDetailsForm;
  createScenario: (data: any) => void;
}

const CreateScenario = (props: CreateScenarioProps): JSX.Element => {
  const [forecastType, setForecastType] = useState<any>(DEFAULT_SCENARIO_TYPE);
  const [scenarioDetails, setScenarioDetails] = useState<IScenarioDetails>(props.scenarioDetails);

  const [dataTable, setDataTable] = React.useState<Components[]>([]);
  const [returnTableData, setReturnTableData] = React.useState<Components[]>(props.returnTableData);
  const [returnRangeTableData, setReturnRangeTableData] = React.useState<Components[]>(props.returnRangeTableData);
  const [volatilityTableData, setVolatilityTableData] = React.useState<Components[]>(props.volatilityTableData);
  const [volatilityRangeTableData, setVolatilityRangeTableData] = React.useState<Components[]>(props.volatilityRangeTableData);

  const [dataTablesNotInDefaultValues, setDataTablesNotInDefaultValues] = React.useState<boolean>(false);

  useEffect(() => {
    setScenarioDetails(props.scenarioDetails);
  }, [props.scenarioDetails]);

  useEffect(() => {
    setReturnTableData(props.returnTableData);
  }, [props.returnTableData]);

  useEffect(() => {
    setReturnRangeTableData(props.returnRangeTableData);
  }, [props.returnRangeTableData]);

  useEffect(() => {
    setVolatilityTableData(props.volatilityTableData);
  }, [props.volatilityTableData]);

  useEffect(() => {
    setVolatilityRangeTableData(props.volatilityRangeTableData);
  }, [props.volatilityRangeTableData]);

  useEffect(() => {
    setDataTable(
      [
        ...returnTableData,
        ...returnRangeTableData,
        ...volatilityTableData,
        ...volatilityRangeTableData
      ]
    );
  }, [
    returnTableData,
    returnRangeTableData,
    volatilityTableData,
    volatilityRangeTableData
  ]);

  useEffect(() => {
    // Attention: SetState has delay, setTimeout prevent from getting old version of state
    setTimeout(() => {
      if (props.assetsPayloads.length && props.assetsCurves.length) {
        setDataTablesNotInDefaultValues(
          isDataTableNotInDefaultValues(
            props.assetsPayloads,
            props.assetsCurves,
            dataTable
          )
        );
      }
    }, 0);
  }, [
    props.assetsPayloads,
    props.assetsCurves,
    dataTable
  ]);

  return (
    <React.Fragment>
      <ScenarioDetails
        initialValues={props.initialValues}
        scenarioDetails={scenarioDetails}
        setScenarioDetails={setScenarioDetails}
      />
      <ScenarioForecast
        returnTableData={returnTableData}
        setReturnTableData={setReturnTableData}
        returnRangeTableData={returnRangeTableData}
        setReturnRangeTableData={setReturnRangeTableData}
        volatilityTableData={volatilityTableData}
        setVolatilityTableData={setVolatilityTableData}
        volatilityRangeTableData={volatilityRangeTableData}
        setVolatilityRangeTableData={setVolatilityRangeTableData}
        assetsPayloads={props.assetsPayloads}
        assetsCurves={props.assetsCurves}
        forecastType={forecastType}
        setForecastType={setForecastType}
      />
      {
        dataTable.length > 0 ? (
          <ScenarioForecastInputsWrapper>
            <FormHeading>
              Set Forecast Inputs
            </FormHeading>
            {
              returnTableData.length > 0 ? (
                <ScenarioForecastInputs
                  id={SET_FORMAT_INPUTS_RETURN_ID}
                  tableData={returnTableData}
                  setTableData={setReturnTableData}
                  staticForecastType={FORECAST_SELECT_TYPES_IDS.meanReturn}
                />
              ) : null
            }
            {
              returnRangeTableData.length > 0 ? (
                <ScenarioForecastInputs
                  id={SET_FORMAT_INPUTS_RETURN_RANGE_ID}
                  tableData={returnRangeTableData}
                  setTableData={setReturnRangeTableData}
                  staticForecastType={FORECAST_SELECT_TYPES_IDS.meanReturnRange}
                />
              ) : null
            }
            {
              volatilityTableData.length > 0 ? (
                <ScenarioForecastInputs
                  id={SET_FORMAT_INPUTS_VOLATILITY_ID}
                  tableData={volatilityTableData}
                  setTableData={setVolatilityTableData}
                  staticForecastType={FORECAST_SELECT_TYPES_IDS.volatility}
                />
              ) : null
            }
            {
              volatilityRangeTableData.length > 0 ? (
                <ScenarioForecastInputs
                  id={SET_FORMAT_INPUTS_VOLATILITY_RANGE_ID}
                  tableData={volatilityRangeTableData}
                  setTableData={setVolatilityRangeTableData}
                  staticForecastType={FORECAST_SELECT_TYPES_IDS.volatilityRange}
                />
              ) : null
            }
          </ScenarioForecastInputsWrapper>
        ) : null
      }
      <PageButtonsWrapper>
        {
          dataTable.length > 0 && !dataTablesNotInDefaultValues ? (
            <ErrorMessage color="error" size="small">
              Set Forecast Inputs to proceed
            </ErrorMessage>
          ) : null
        }
        <Button
          onClick={() => {
            props.createScenario({
              scenarioDetails,
              returnTableData: dataTable
            });
          }}
          color="secondary"
          disabled={dataTable.length === 0 || !scenarioDetails.isValid || !dataTablesNotInDefaultValues}
          endIcon={(
            <Icons iconType="checkmark" size={7} />
          )}
        >
          {scenarioDetails.id ? 'Update Scenario' : 'Create Scenario'}
        </Button>
      </PageButtonsWrapper>
    </React.Fragment>
  );
};

export default CreateScenario;
