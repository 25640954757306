import styled from '@emotion/styled';

import { BoxProps } from 'components/ui/Box';

interface WrapperProps extends BoxProps {
  ref?: any;
}

const Wrapper = styled.div<any>`
  padding: 22px 25px 28px;
  border: 1px solid #e3e3e3; // @TODO: theme
  background: ${(props) => props.theme.colorMono.white};
`;

export {
  Wrapper
};
