import styled from '@emotion/styled';

import Icons from 'components/ui/Icons';

// @ts-ignore
const StyledIcons = styled(Icons)`
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
`;

export {
  StyledIcons
};
