import React from 'react';

import Tabs from 'components/ui/Tabs';
import Tab from 'components/ui/Tab';
import TabPanel from 'components/ui/TabPanel';
import JobTile from 'components/ui/JobTile/JobTile';

export interface JobViewTabsProps {
  scenariosList?: any[];
  portfolioList?: any[];
}
const defaultProps = {
  scenariosList: [],
  portfolioList: []
};

const JobViewTabs = (props: JobViewTabsProps): JSX.Element => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.FormEvent<HTMLFormElement>, newValue: any) => {
    setValue(newValue);
  };

  return (
    <React.Fragment>
      <div>
        <Tabs value={value} onChange={handleChange as any}>
          <Tab label="Portfolios" />
          <Tab label="Scenarios" />
          <Tab label="Rules" disabled />
        </Tabs>
      </div>
      <TabPanel value={value} index={0}>
        {
          props.portfolioList?.map((portfolio: any) => (
            <JobTile tileType="secondary" key={portfolio.tagID}>{portfolio.name}</JobTile>
          ))
        }
      </TabPanel>
      <TabPanel value={value} index={1}>
        {
          props.scenariosList?.map((scenario: any) => (
            <JobTile tileType="primary" key={scenario.tagID}>{scenario.name}</JobTile>
          ))
        }
      </TabPanel>
      <TabPanel value={value} index={2}>
        Rules TODO
      </TabPanel>
    </React.Fragment>
  );
};

JobViewTabs.defaultProps = defaultProps;

export default JobViewTabs;
