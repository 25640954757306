import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import AuthPage from 'auth/AuthPage';
import AuthNotification from 'auth/AuthNotification';

import Button from 'components/ui/Button';
import Loader from 'components/ui/Loader';

import {
  Wrapper,
  StyledIcons,
  StyledTextField,
  LinkButton
} from './Register.styles';

const Register = (): JSX.Element => {
  const history = useHistory();

  const [required] = useState<boolean>(false);
  const [loader] = useState<boolean>(false);
  const [notification] = useState<any>(null);
  const [accountLogin, setAccountLogin] = useState<any>({
    accountEmail: '',
    accountKey: '',
    message: ''
  });

  const setAccountValues = (value: any, name: string) => {
    setAccountLogin({
      ...accountLogin,
      [name]: value
    });
  };

  return (
    <AuthPage
      title="Register with Key"
      contentWithBorder
      footer={(
        <LinkButton onClick={() => history.push('/')}>
          Back to Login
        </LinkButton>
      )}
    >
      <Wrapper>
        {
          notification && notification?.length ? (
            <AuthNotification severity={notification?.[0]} message={notification?.[1]} />
          ) : null
        }
        {
          loader ? (
            <Loader loaderType="static" />
          ) : null
        }
        {/* @TODO: validation */}
        <StyledTextField
          withExternalLabel
          name="accountEmail"
          label="Email"
          value={accountLogin.accountEmail}
          error={required}
          required
          // inputPattern={INPUT_PATTERN_EMAIL}
          // validationPattern={VALIDATION_PATTERN_EMAIL}
          tabIndex={1}
          onChange={(event: any) => setAccountValues(event?.target?.value, event?.target?.name)}
        />
        <StyledTextField
          withExternalLabel
          name="accountKey"
          label="Activation Key"
          value={accountLogin.accountKey}
          error={required}
          required
          // inputPattern={INPUT_PATTERN_WORDS}
          tabIndex={1}
          onChange={(event: any) => setAccountValues(event?.target?.value, event?.target?.name)}
        />
        <Button
          color="secondary"
          onClick={() => {}}
          fullWidth
        >
          Continue Registration
          <StyledIcons iconType="arrow-right" size={14} />
        </Button>
      </Wrapper>
    </AuthPage>
  );
};

export default Register;
