import styled from '@emotion/styled';

import DroppableInner from 'components/ui/Box';
import IconButton from 'components/ui/IconButton';

const StyledBox = styled(DroppableInner)`
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  justify-content: flex-end;
  height: 302px;
  padding: 0;
`;

const CreateWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 14px;
  font-size: 14px;
  color: #9c9c9c;
`;

const CreateJobImage = styled.img`
  max-width: 80px;
`;

const ArrowImage = styled.img`
  align-self: flex-end;
  max-height: 73px;
  margin-right: 53px;
  margin-bottom: -22px;
`;

// @ts-ignore
const StyledIconButton = styled(IconButton)`
  align-self: flex-end;
`;

export {
  StyledBox,
  CreateWrapper,
  CreateJobImage,
  ArrowImage,
  StyledIconButton
};
