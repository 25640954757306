import React, { useEffect } from 'react';
import { Route, RouteProps } from 'react-router';

import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';

import api from 'config/api';

import { useAccessToken } from 'auth/useAccessToken';

import AuthPage from 'auth/AuthPage';
import Loader from 'components/ui/Loader';

const ProtectedRoute = (props: RouteProps) => {
  const { isAuthenticated } = useAuth0();

  const accessToken = useAccessToken();

  useEffect(() => {
    if (accessToken) {
      api.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    }
  }, [accessToken]);

  return isAuthenticated ? (
    <Route {...props} />
  ) : null;
};

export default withAuthenticationRequired(ProtectedRoute, {
  // Show a message while the user waits to be redirected to the login page
  onRedirecting: () => (
    <AuthPage title="SAPIAT">
      <Loader loaderType="static" />
    </AuthPage>
  ),
  returnTo: () => window.location.hash.substr(1)
});
