import { Asset, AssetCurve, ForecastTableData, MetricType } from 'interfaces';

import { COMPONENTS_TYPE_IDS, METRIC_TYPES_IDS } from 'constant';

import { createMonthData } from './createMonthData';

export const forecastSetTableData = (
  assetsPayloads: Asset[],
  assetsCurves: AssetCurve[],
  selectedOptions: Asset,
  tableDataLength: number,
  metricType: MetricType | null = null
): ForecastTableData => {
  const asset = assetsPayloads.filter((asset: Asset) => asset.name === selectedOptions.name)[0];

  return ({
    id: tableDataLength + 1,
    componentType: COMPONENTS_TYPE_IDS.point,
    metricType: metricType || METRIC_TYPES_IDS.price,
    assetName: selectedOptions?.name,
    ...createMonthData(assetsCurves, null, null, asset.id, metricType, false)
  });
};
