import { COMPONENTS_MONTH_PREFIX } from 'constant';

export const changeNumbersToStrings = (components: any[]): any[] => components.map((el: any) => {
  const newObject: any = {};
  Object.entries(el)
    .forEach(([key, value]) => {
      const labelId = Number(key.replace(COMPONENTS_MONTH_PREFIX, ''));

      newObject[key] = !Number.isNaN(labelId) && (value === 0 || (value && !Number.isNaN(Number(value))))
        ? Number(value).toString()
        : value;
    });

  return newObject;
});
