import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AppDispatch, RootState } from 'interfaces';

import { SIDE_PANEL_ID } from 'constant';

import Loader from 'components/ui/Loader';

import Layout from 'components/Layout';
import Chart from 'components/Chart';
import DashboardFiltersForm from 'components/DashboardFiltersForm';
import DetailsPanel from 'components/DetailsPanel';

import {
  getCalcSettingsIds,
  getSelectedJobsChartData,
  getSelectedJobsChartSimData
} from './dashboardMainSlice';

const DashboardMain = (): JSX.Element => {
  const [selectedJobId, setSelectedJobId] = useState<number | null>(null);
  const [detailsPanelData, setDetailsPanelData] = useState<any | null>(null);
  const [sidebarChangedCounter, setSidebarChangedCounter] = useState(0);

  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    dispatch(getCalcSettingsIds());
  }, [dispatch]);

  const jobs = useSelector((state: RootState) => state.dashboardMain.jobs);
  const chartData = useSelector((state: RootState) => state.dashboardMain.chartData);
  const simData = useSelector((state: RootState) => state.dashboardMain.simData);
  const loading = useSelector((state: RootState) => state.dashboardMain.loading);

  useEffect(() => {
    setSelectedJobId(jobs[0]?.jobData.id);
  }, [jobs]);

  useEffect(() => {
    if (selectedJobId) {
      dispatch(getSelectedJobsChartData({ selectedJobId }));
      dispatch(getSelectedJobsChartSimData({ selectedJobId }));
    }
  }, [dispatch, selectedJobId]);

  const errorMsg = !loading ? 'There is no chart for this Job' : null;

  return (
    <Layout
      sidePanelId={SIDE_PANEL_ID}
      withSidePanel
      sidePanelElement={() => (
        <DetailsPanel
          type="dashboard"
          data={detailsPanelData}
          withHideButton
          onButtonClick={() => {
            setSidebarChangedCounter(sidebarChangedCounter + 1);
          }}
        />
      )}
      sidebarChangedCounter={sidebarChangedCounter}
      withPageButtons={false}
    >
      <DashboardFiltersForm
        jobs={jobs}
        chooseJob={(selectedOption) => {
          setSelectedJobId(Number(selectedOption?.value));
          // @TODO: clear selected data in right panel
        }}
        selectedJobId={selectedJobId}
      />
      {
        loading ? <Loader loaderType="static" /> : null
      }
      {
        !loading && chartData ? (
          <Chart
            chartData={chartData}
            simData={simData}
            onChartClick={(data: any) => {
              setDetailsPanelData({
                ...detailsPanelData,
                ...data
              });
            }}
          />
        ) : errorMsg
      }
    </Layout>
  );
};

export default DashboardMain;
