import { AssetsFilters, AssetsFiltersWithSelectedOption } from 'interfaces';
import { FILTERS_NAMES } from 'constant';

import { findUnique } from 'utils/findUnique';

export const getDefaultAssetsDictionariesFilters = (assets: AssetsFilters): AssetsFiltersWithSelectedOption => ({
  [FILTERS_NAMES.name]: {
    selected: null,
    assets: assets.name
  },
  [FILTERS_NAMES.assetClass]: {
    selected: null,
    assets: findUnique(assets.assetClass, FILTERS_NAMES.assetClass)
  },
  [FILTERS_NAMES.region]: {
    selected: null,
    assets: findUnique(assets.region, FILTERS_NAMES.region)
  },
  [FILTERS_NAMES.priceCurrency]: {
    selected: null,
    assets: findUnique(assets.priceCurrency, FILTERS_NAMES.priceCurrency)
  }
});
