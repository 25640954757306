import dashboardIcon from 'assets/menu/dashboard-icon.svg';
import scenarioIcon from 'assets/menu/scenario-icon.svg';
import portfolioIcon from 'assets/menu/portfolio-icon.svg';
import rulesIcon from 'assets/menu/rules-icon.svg';
import settingsIcon from 'assets/menu/settings-icon.svg';

export interface SubNavigation {
  id: string;
  name: string;
  link: string;
  hidden?: boolean;
}
export interface INavigation {
  id: string;
  name: string;
  icon: string;
  subNavigation: SubNavigation[];
  link?: string;
  disabled?: boolean;
}
export interface MenuItemOpen {
  [id: string]: boolean;
}

export const navigation: INavigation[] = [
  {
    id: 'dashboard',
    name: 'Dashboard',
    link: '/',
    icon: dashboardIcon,
    subNavigation: [
      {
        id: 'dashboard-main',
        name: 'Main',
        link: '/'
      },
      {
        id: 'dashboard-analytics-summary',
        name: 'Analytics - summary',
        link: '/dashboard-analytics-summary'
      },
      {
        id: 'dashboard-analytics-assets-attribute',
        name: 'Analytics - assets attribute',
        link: '/dashboard-analytics-assets-attribute'
      },
      {
        id: 'dashboard-analytics-assets-stats',
        name: 'Analytics - assets stats',
        link: '/dashboard-analytics-assets-stats'
      }
    ]
  },
  {
    id: 'scenario',
    name: 'Scenario',
    icon: scenarioIcon,
    subNavigation: [
      {
        id: 'create-scenario',
        name: 'Create scenario',
        link: '/create-scenario'
      },
      {
        id: 'scenario-explorer',
        name: 'Scenario explorer',
        link: '/scenario-explorer'
      },
      {
        id: 'scenario-manager',
        name: 'Scenario manager',
        link: '/scenario-manager'
      },
      {
        id: 'edit-scenario',
        name: 'Edit scenario',
        link: '/edit-scenario',
        hidden: true
      },
      {
        id: 'scenario-view',
        name: 'Scenario view',
        link: '/scenario-view',
        hidden: true
      }
    ]
  },
  {
    id: 'portfolio',
    name: 'Portfolios',
    icon: portfolioIcon,
    subNavigation: [
      {
        id: 'create-portfolio',
        name: 'Create portfolio',
        link: '/create-portfolio'
      },
      {
        id: 'portfolio-manager',
        name: 'Portfolio manager',
        link: '/portfolio-manager'
      },
      {
        id: 'edit-portfolio',
        name: 'Edit portfolio',
        link: '/edit-portfolio',
        hidden: true
      },
      {
        id: 'portfolio-view',
        name: 'Portfolio view',
        link: '/portfolio-view',
        hidden: true
      }
    ]
  },
  {
    id: 'rules',
    name: 'Rules',
    icon: rulesIcon,
    disabled: true,
    subNavigation: [
      // {
      //   id: 'horizon-rules',
      //   name: 'Horizon rules',
      //   link: '/horizon-rules'
      // },
      // {
      //   id: 'rebalancing-rules',
      //   name: 'Rebalancing rules',
      //   link: '/rebalancing-rules'
      // },
      // {
      //   id: 'reporting-rules',
      //   name: 'Reporting rules',
      //   link: '/reporting-rules'
      // },
      // {
      //   id: 'scheduler-rules',
      //   name: 'Scheduler rules',
      //   link: '/scheduler-rules'
      // }
    ]
  },
  {
    id: 'settings',
    name: 'Settings',
    icon: settingsIcon,
    subNavigation: [
      {
        id: 'job-manager',
        name: 'Job Manager',
        link: '/job-manager'
      },
      {
        id: 'job-view',
        name: 'Job view',
        link: '/job-view',
        hidden: true
      }
    ]
  }
];
