import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { TextField } from '@material-ui/core';

import InfoIconBox from 'components/ui/InfoIconBox';
import ExternalLabel, { ExternalLabelProps } from 'components/ui/ExternalLabel';

import { ITextFieldProps } from 'components/ui/TextField';

type WrapperProps = {
  noFullWidth: boolean;
  withMarginBottom: boolean;
  labelPosition: string | undefined;
};

type StyledExternalLabelProps = {
  labelPosition: string | undefined;
};

const Wrapper = styled.div<WrapperProps>`
  height: 100%;

  ${(props) => !props.noFullWidth && css`
    width: 100%;
  `};
  ${(props) => props.withMarginBottom && css`
    margin-bottom: 25px;
  `};
  ${(props) => props.labelPosition === 'left' && css`
    display: flex;
  `};
`;

const StyledTextField = styled(({
  size,
  noFullWidth,
  ...props
} : ITextFieldProps) => (
  <TextField {...props} />
))<ITextFieldProps>`
  position: relative;
  min-width: 130px;

  ${(props) => !props.noFullWidth && css`
    width: 100%;
  `};

  & .MuiInputBase-root {
    border: 1px solid;
    border-color: ${(props) => props.theme.color.gray} ${(props) => props.theme.color.gray} #8d8d8d;
    border-radius: 0;
    background: ${(props) => props.theme.color.gray};

    &.Mui-focused {
      border-color: ${(props) => props.theme.color.secondary};
      background: ${(props) => props.theme.colorMono.white};
    }
    &.Mui-error {
      border-color: ${(props) => props.theme.color.error};
    }
  }

  .MuiFormHelperText-root.Mui-error {
    color: ${(props) => props.theme.color.error};
  }

  & .MuiInput-underline::before,
  & .MuiInput-underline::after {
    content: normal;
  }

  && {
    & .MuiAutocomplete-inputRoot {
      padding-right: 51px;
      padding-bottom: 0;
    }
    & .MuiInputBase-input,
    & .MuiAutocomplete-inputRoot .MuiInputBase-input {
      padding: 14px 15px 13px;

      ${(props) => props.size === 'small' && css`
        padding-top: 6px;
        padding-bottom: 5px;
        font-size: 14px;
      `};
      ${(props) => props.size === 'medium' && css`
        padding-top: 10px;
        padding-bottom: 9px;
      `};
    }
  }

  & .MuiInputBase-multiline {
    padding: 0;
  }

  & textarea, & input {
    &::placeholder {
      color: #575757;
    }
  }

  & .MuiFormHelperText-root {
    position: absolute;
    bottom: -22px;
  }

  & .MuiAutocomplete-endAdornment {
    top: 50%;
    right: 12px;
    transform: translateY(-50%);
  }
  & .MuiAutocomplete-clearIndicator,
  & .MuiAutocomplete-popupIndicator {
    display: inline-flex;
    align-items: center;
    width: 24px;
    height: 24px;
    margin-right: -6px;
    padding-top: 5px;
  }
  & .MuiAutocomplete-popupIndicator {
    margin-left: 2px;
    padding-top: 4px;
  }
  & .MuiInputBase-adornedEnd {
    padding-right: 40px;
  }
  & .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiInputBase-adornedEnd {
    padding-right: 65px;
  }
  & .MuiButtonBase-root {
    color: ${(props) => props.theme.colorMono.black};
  }
  & .MuiButtonBase-root.MuiIconButton-root.MuiIconButton-edgeEnd {
    margin-right: -28px;
    padding: 6px;
  }
`;

// @ts-ignore
const StyledInfoIconBox = styled(InfoIconBox)`
  display: inline-flex;
  align-items: center;
  margin-left: 5px;
  color: ${(props) => props.theme.colorMono.black};
`;

const MaxLengthWrapper = styled.div`
  margin-left: auto;
`;

// @ts-ignore
const StyledExternalLabel = styled(({
  labelPosition,
  ...props
} : ExternalLabelProps & StyledExternalLabelProps) => (
  <ExternalLabel {...props}>{props.children}</ExternalLabel>
))`
  ${(props) => props.labelPosition === 'left' && css`
    margin-right: 10px;
    margin-bottom: 0;
  `};
`;

export {
  Wrapper,
  StyledTextField,
  StyledInfoIconBox,
  MaxLengthWrapper,
  StyledExternalLabel
};
