import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { DashboardData } from 'interfaces';

import { fetchSimCharts, fetchSimData } from 'api';

import { getCalcSettingsIdsGeneralFunction } from 'utils/dashboard';

const initialState: DashboardData = {
  jobs: [],
  chartData: null,
  simData: null,
  chartRevision: null,
  loading: true
};

// @TODO: refactor
export const getCalcSettingsIds = createAsyncThunk(
  'dashboardMain/getJobs',
  async () => getCalcSettingsIdsGeneralFunction()
);

export const getSelectedJobsChartData = createAsyncThunk(
  'dashboardMain/getSelectedJobsChartData',
  // @ts-ignore
  async ({ selectedJobId }: { selectedJobId: number | null }) => fetchSimCharts(selectedJobId)
);

export const getSelectedJobsChartSimData = createAsyncThunk(
  'dashboardMain/getSelectedJobsChartSimData',
  // @ts-ignore
  async ({ selectedJobId }: { selectedJobId: number | null }) => fetchSimData(selectedJobId)
);

const dashboardMain = createSlice({
  name: 'createScenario',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCalcSettingsIds.fulfilled, (state, action) => {
        const { jobs } = action.payload;
        return {
          ...state,
          jobs
        };
      })
      .addCase(getSelectedJobsChartData.pending, (state, action) => ({
        ...state,
        loading: true
      }))
      .addCase(getSelectedJobsChartData.fulfilled, (state, action) => ({
        ...state,
        chartData: action.payload,
        loading: false
      }))
      .addCase(getSelectedJobsChartSimData.fulfilled, (state, action) => ({
        ...state,
        simData: action.payload?.portfolioData
      }))
      .addCase(getSelectedJobsChartData.rejected, (state, action) => ({
        ...state,
        chartData: null,
        loading: false
      }));
  }
});

export default dashboardMain.reducer;
