import React, { ElementType } from 'react';

import {
  Wrapper
} from './Heading.styles';

export interface HeadingProps {
  children: React.ReactNode;
  variant?: 'primary' | 'secondary' | 'default';
  as?: (ElementType<any> | undefined) & string;
  className?: string;
}
const defaultProps = {
  variant: 'secondary',
  as: 'h2',
  className: ''
};

const Heading = (props: HeadingProps): JSX.Element => (
  <Wrapper
    className={props.className}
    variant={props.variant}
    as={props.as}
  >
    {props.children}
  </Wrapper>
);

Heading.defaultProps = defaultProps;

export default Heading;
