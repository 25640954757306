import styled from '@emotion/styled';
import { css } from '@emotion/react';

import IconsFontWoff from 'assets/icons/icons.woff';
import IconsFontTtf from 'assets/icons/icons.ttf';
import FontSvg from 'assets/icons/icons.svg';

import { IconsProps } from 'components/ui/Icons';

const IconsGlobalStyles = css`
  @font-face {
    font-family: 'Icons';
    font-weight: normal;
    font-style: normal;
    font-display: block;
    src: url(${IconsFontWoff}) format('woff'), /* standards */
      url(${IconsFontTtf}) format('truetype'), /* Safari, Android, iOS */
      url(${FontSvg}) format('svg'); /* legacy iOS */
  }
`;

const IconWrapper = styled.span<IconsProps>`
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: ${(props) => props.theme.fontFamily.icons}, sans-serif !important;
  speak: never;
  font-size: inherit;
  font-weight: normal;
  font-style: normal;
  font-variant: normal;
  line-height: 1;
  color: inherit;
  text-transform: none;

  &::before {
    font-size: inherit;
    color: inherit;
  }

  ${(props) => props.size && css`
    &&& {
      font-size: ${props.size}px;
    }
  `};

  ${(props) => props.color && css`
    color: ${props.color};
  `};

  ${(props) => props.iconType === 'logo' && css`
    &::before {
      content: '\\e900';
    }
  `};
  ${(props) => props.iconType === 'user-filled' && css`
    &::before {
      content: '\\e901';
    }
  `};
  ${(props) => props.iconType === 'closed-filled' && css`
    &::before {
      content: '\\e902';
    }
  `};
  ${(props) => props.iconType === 'checkmark-filled' && css`
    &::before {
      content: '\\e903';
    }
  `};
  ${(props) => props.iconType === 'warning-filled' && css`
    &::before {
      content: '\\e904';
    }
  `};
  ${(props) => props.iconType === 'delete' && css`
    &::before {
      content: '\\e905';
    }
  `};
  ${(props) => props.iconType === 'chevron-top-down' && css`
    &::before {
      content: '\\e906';
    }
  `};
  ${(props) => props.iconType === 'chevron-down' && css`
    &::before {
      content: '\\e907';
    }
  `};
  ${(props) => props.iconType === 'chevron-up' && css`
    &::before {
      content: '\\e908';
    }
  `};
  ${(props) => props.iconType === 'chevron-right' && css`
    &::before {
      content: '\\e909';
    }
  `};
  ${(props) => props.iconType === 'chevron-right-light' && css`
    &::before {
      content: '\\e90a';
    }
  `};
  ${(props) => props.iconType === 'drug-and-drop' && css`
    &::before {
      content: '\\e90b';
    }
  `};
  ${(props) => props.iconType === 'calendar' && css`
    &::before {
      content: '\\e90c';
    }
  `};
  ${(props) => props.iconType === 'settings' && css`
    &::before {
      content: '\\e90d';
    }
  `};
  ${(props) => props.iconType === 'edit' && css`
    &::before {
      content: '\\e90e';
    }
  `};
  ${(props) => props.iconType === 'folder-details' && css`
    &::before {
      content: '\\e90f';
    }
  `};
  ${(props) => props.iconType === 'trash' && css`
    &::before {
      content: '\\e910';
    }
  `};
  ${(props) => props.iconType === 'more-option' && css`
    &::before {
      content: '\\e911';
    }
  `};
  ${(props) => props.iconType === 'info' && css`
    &::before {
      content: '\\e912';
    }
  `};
  ${(props) => props.iconType === 'plus' && css`
    &::before {
      content: '\\e913';
    }
  `};
  ${(props) => props.iconType === 'checkmark-thick' && css`
    &::before {
      content: '\\e914';
    }
  `};
  ${(props) => props.iconType === 'checkmark' && css`
    &::before {
      content: '\\e915';
    }
  `};
  ${(props) => props.iconType === 'nested' && css`
    &::before {
      content: '\\e916';
    }
  `};
  ${(props) => props.iconType === 'minimize' && css`
    &::before {
      content: '\\e917';
    }
  `};
  ${(props) => props.iconType === 'maximize' && css`
    &::before {
      content: '\\e918';
    }
  `};
  ${(props) => props.iconType === 'arrow-right' && css`
    &::before {
      content: '\\e91b';
    }
  `};
  ${(props) => props.iconType === 'view' && css`
    &::before {
      content: '\\e91c';
    }
  `};
  ${(props) => props.iconType === 'chevron-left' && css`
    &::before {
      content: '\\e919';
    }
  `};
  ${(props) => props.iconType === 'chevron-left-light' && css`
    &::before {
      content: '\\e91a';
    }
  `};
  ${(props) => props.iconType === 'checkbox-blank-outline' && css`
    &::before {
      content: '\\e91d';
    }
  `};
  ${(props) => props.iconType === 'checkbox-marked' && css`
    &::before {
      content: '\\e91e';
    }
  `};
  ${(props) => props.iconType === 'checkbox-minus' && css`
    &::before {
      content: '\\e91f';
    }
  `};
  ${(props) => props.iconType === 'curve-arrow' && css`
    &::before {
      content: '\\e920';
    }
  `};
  ${(props) => props.iconType === 'create' && css`
    &::before {
      content: '\\e921';
    }
  `};
  ${(props) => props.iconType === 'filter' && css`
    &::before {
      content: '\\e922';
    }
  `};
  ${(props) => props.iconType === 'bullet-list' && css`
    &::before {
      content: '\\e923';
    }
  `};
  ${(props) => props.iconType === 'pin' && css`
    &::before {
      content: '\\e924';
    }
  `};
  ${(props) => props.iconType === 'content-copy' && css`
    &::before {
      content: '\\e925';
    }
  `};
  ${(props) => props.iconType === 'content-paste' && css`
    &::before {
      content: '\\e926';
    }
  `};
  ${(props) => props.iconType === 'download' && css`
    &::before {
      content: '\\e927';
    }
  `};
  ${(props) => props.iconType === 'file-excel-outline' && css`
    &::before {
      content: '\\e928';
    }
  `};
  ${(props) => props.iconType === 'file-outline' && css`
    &::before {
      content: '\\e929';
    }
  `};
  ${(props) => props.iconType === 'search' && css`
    &::before {
      content: '\\e92a';
    }
  `};
`;

export {
  IconWrapper,
  IconsGlobalStyles
};
