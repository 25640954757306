import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { AppDispatch, RootState } from 'interfaces';

import Loader from 'components/ui/Loader';

import Layout from 'components/Layout';
import PortfolioViewDetails from 'components/PortfolioViewDetails';
import PortfolioViewAssetsTable from 'components/PortfolioViewAssetsTable';

import { getAssets } from 'features/assets/assetsSlice';
import { getPortfolio } from './portfolioViewSlice';

const PortfolioView = (): JSX.Element => {
  const { portfolioId }: any = useParams();

  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    dispatch(getAssets());
  }, [dispatch]);

  const assetsPayloads = useSelector((state: RootState) => state.assets.assetsPayloads);

  useEffect(() => {
    if (portfolioId && assetsPayloads.length) {
      dispatch(getPortfolio({ portfolioId }));
    }
  }, [dispatch, portfolioId, assetsPayloads]);

  const portfolioDetails = useSelector((state: RootState) => state.portfolioView.portfolioDetails);
  const loading = useSelector((state: RootState) => state.portfolioView.loading);

  return (
    <Layout>
      {
        !loading ? (
          <React.Fragment>
            <PortfolioViewDetails
              portfolioDetails={portfolioDetails}
            />
            <PortfolioViewAssetsTable
              holdings={portfolioDetails.holdings}
            />
          </React.Fragment>
        ) : (
          <Loader loaderType="static" />
        )
      }
    </Layout>
  );
};

export default PortfolioView;
