import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { HeadingProps } from 'components/ui/Heading';

const Wrapper = styled.h2<HeadingProps>`
  color: ${(props) => props.theme.color.secondary};

  ${(props) => props.variant === 'default' && css`
    color: ${props.theme.colorText.primary};
    font-weight: ${props.theme.fontWeight.semibold};

    ${props.as === 'h1' && css`
      font-size: 36px;
    `};
  `};
`;

export {
  Wrapper
};
