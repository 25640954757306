import styled from '@emotion/styled';

import { StaticDatePicker } from '@mui/x-date-pickers';

// @ts-ignore
const StyledStaticDatePicker = styled(StaticDatePicker)`
  && {
    .MuiCalendarOrClockPicker-root,
    .MuiPickersDay-root {
      background: ${(props) => props.theme.color.gray};
    }
    .MuiPickersDay-root {
      border-radius: 0;
    }
    .MuiPickersDay-today {
      border: 0;
      background: #e2e2e2;
    }
    .MuiDayPicker-weekContainer {
      margin: 5px 0;
    }
    .MuiPickersDay-root.Mui-selected {
      background: ${(props) => props.theme.color.secondary};
    }
  }
`;

export {
  StyledStaticDatePicker
};
