import { LAYOUT_DEFAULT_H_MARGIN, MAIN_HEADER_HEIGHT } from 'constant';

export const prepareData = (plot: any, normalHeight = false) => {
  const { width, height, ...layout } = plot?.layout;
  const data: any[] = [];

  // @TODO: handle small height devices
  const getHeight = () => window.innerHeight - MAIN_HEADER_HEIGHT - (5 * LAYOUT_DEFAULT_H_MARGIN);

  plot?.data.forEach((el: any) => {
    data.push({
      ...el,
      marker: {
        ...el.marker
      },
      line: {
        ...el.line
      },
      hovertemplate: '', // @TODO: hover template
      hoverinfo: 'none'
    });
  });

  const tickText = layout.yaxis.ticktext.map((el: string) => el.replace('-', ' '));

  return {
    data: [...data],
    layout: {
      ...layout,
      legend: {
        traceorder: layout.legend.traceorder,
        orientation: 'h',
        x: 0,
        y: 1.3 // 1.1
      },
      xaxis: {
        ...layout.xaxis,
        gridcolor: '#e8e8e8',
        linecolor: '#A4A4A4',
        zerolinecolor: '#d3d3d3',
        zerolinewidth: 1,
        griddash: 'dot',
        layer: 'below traces',
        title: 'Valuation (%)',
        tickformat: '',
        ticktext: layout.xaxis.tickvals.map((el: number) => Math.round(el * 100)),
        linewidth: 2,
        tickangle: layout.xaxis.tickvals.length > 17 ? -90 : -45
      },
      yaxis: {
        ...layout.yaxis,
        gridcolor: '#e8e8e8',
        zeroline: false,
        linecolor: '#e8e8e8',
        griddash: 'dot',
        layer: 'below traces',
        title: null,
        ticktext: tickText,
        tick0: 1,
        range: [1, layout.yaxis.range[layout.yaxis.range.length - 1]]
      },
      margin: {
        t: 0,
        b: 110,
        r: 0,
        l: 70
      },
      height: !normalHeight ? getHeight() : layout.height,
      hoverlabel: { bgcolor: '#FFF' },
      autosize: true
    },
    config: {
      responsive: true,
      editable: false,
      displayModeBar: false
    }
  };
};
