import { Asset } from 'interfaces';

export const findUnique = (arr: Asset[], filterName: string): any[] => {
  const uniqueArray = new Set();

  return arr.filter((element: any) => {
    const isDuplicate = uniqueArray.has(element[filterName]);
    uniqueArray.add(element[filterName]);

    return !isDuplicate;
  });
};
