import { JobSettingsForm } from 'interfaces';
import {
  DEFAULT_JOB_SETTING,
  JOB_SETTINGS_FORECAST_HORIZON_OPTIONS,
  JOB_SETTINGS_SAMPLING_INTERVAL_OPTIONS
} from 'constant';

export const getDefaultSettingsForForm = (): JobSettingsForm => ({
  numberOfSamples: DEFAULT_JOB_SETTING.numberOfSettings.toString(),
  modelHorizon: DEFAULT_JOB_SETTING.modelHorizon.toString(),
  samplingInterval: {
    label: JOB_SETTINGS_SAMPLING_INTERVAL_OPTIONS[2].label,
    value: JOB_SETTINGS_SAMPLING_INTERVAL_OPTIONS[2].value
  },
  forecastHorizon: {
    label: JOB_SETTINGS_FORECAST_HORIZON_OPTIONS[3].label,
    value: JOB_SETTINGS_FORECAST_HORIZON_OPTIONS[3].value
  },
  reportingCurrency: DEFAULT_JOB_SETTING.reportingCurrency
});
