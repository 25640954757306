import React, { useState, useEffect, useCallback } from 'react';

import { useTheme } from '@emotion/react';

import { HIDDEN_SIDEBAR_MARGIN, MAIN_CONTAINER_ID, MAIN_NAVIGATION_OPEN_KEY } from 'constant';

import Header from 'components/Header';
import Navigation from 'components/Navigation';

import {
  Wrapper,
  Content,
  StyledContainer
} from './Layout.styles';

export type LayoutProps = {
  children: React.ReactNode;
  sidePanelId?: string;
  withSidePanel?: boolean;
  sidePanelElement?: any; // React.ReactNode | ((sidePanelElementProps: any) => JSX.Element);
  paddingSide?: 'left' | 'right';
  setLayoutReady?: any;
  sidebarChangedCounter?: number;
  withPageButtons?: boolean;
};
const defaultProps = {
  sidePanelId: null,
  withSidePanel: false,
  sidePanelElement: null,
  paddingSide: 'right',
  setLayoutReady: () => {},
  sidebarChangedCounter: 0,
  withPageButtons: true
};

const Layout = (props: LayoutProps): JSX.Element => {
  const menuInStorage = JSON.parse(sessionStorage.getItem(MAIN_NAVIGATION_OPEN_KEY) as string);
  const [menuOpen, setMenuOpen] = useState(menuInStorage || false);

  useEffect(() => {
    setMenuOpen(menuInStorage || false);
  }, [menuInStorage]);

  const sideBarElement = props.sidePanelId ? document.getElementById(props.sidePanelId) : null;

  const theme = useTheme();

  const [sidePanelPadding, setSidePanelPadding] = useState<number | null>(null);

  const getPadding = useCallback(() => {
    const sidebarIsVisible = (sideBarElement?.getBoundingClientRect()?.x || 0) + HIDDEN_SIDEBAR_MARGIN < document.documentElement.clientWidth;

    const width = sidebarIsVisible && sideBarElement ? sideBarElement?.getBoundingClientRect()?.width : 0;
    setSidePanelPadding(width + theme.layout.padding || null);

    if (width > 0) {
      props.setLayoutReady?.(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setSidePanelPadding, props.setLayoutReady, theme, sideBarElement, props.sidebarChangedCounter]);

  useEffect(() => {
    getPadding();
  }, [getPadding, sideBarElement]);

  const useHandleScroll = useCallback(() => {
    getPadding();
  }, [getPadding]);

  useEffect(() => {
    getPadding();
    window.addEventListener('resize', useHandleScroll);
    return () => window.removeEventListener('resize', useHandleScroll);
  }, [getPadding, useHandleScroll, props.sidebarChangedCounter]);

  return (
    <Wrapper>
      <Header menuOpen={menuOpen} />
      <Navigation
        menuOpen={menuOpen}
        setMenuOpen={setMenuOpen}
      />
      <Content menuOpen={menuOpen} withPageButtons={props.withPageButtons}>
        {
          props.withSidePanel ? props.sidePanelElement?.({ menuOpen }) : null
        }
        <StyledContainer
          id={MAIN_CONTAINER_ID}
          paddingSide={props.paddingSide as string}
          sidePanelPadding={sidePanelPadding}
        >
          {props.children}
        </StyledContainer>
      </Content>
    </Wrapper>
  );
};

Layout.defaultProps = defaultProps;

export default Layout;
