import React from 'react';

import {
  Wrapper
} from './Box.styles';

export interface BoxProps {
  children: React.ReactNode;
  className?: string;
  id?: string | undefined;
}
const defaultProps = {
  className: '',
  id: undefined
};

const Box = React.forwardRef((props: BoxProps, ref): JSX.Element => (
  <Wrapper ref={ref} id={props.id} className={props.className}>
    {props.children}
  </Wrapper>
));

Box.defaultProps = defaultProps;

export default Box;
