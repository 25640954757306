import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { NativeSelect, FormControlLabel } from '@mui/material';

import { MAIN_NAV_WIDTH, MAIN_NAV_WIDE_WIDTH, MAIN_HEADER_HEIGHT } from 'constant';

import Box from 'components/ui/Box';
import Button from 'components/ui/Button';

interface MenuOpenProps {
  menuOpen?: boolean;
}

const StyledBox = styled(Box)<MenuOpenProps>`
  position: fixed;
  top: ${MAIN_HEADER_HEIGHT}px;
  bottom: 0;
  left: ${MAIN_NAV_WIDTH}px;
  z-index: 3;
  width: 290px;
  padding: 5px 5px 5px 15px;
  border-top: 0;
  border-left: 0;
  transition: ${(props) => props.theme.layout.transition};

  ${(props) => props.menuOpen && css`
    left: calc(${MAIN_NAV_WIDE_WIDTH}px - 1px);
  `};
`;

const FilterList = styled.ul`
  list-style: none;
`;

const FilterItem = styled.li`
  & + & {
    margin-top: 15px;
    padding-top: 15px;
    border-top: 1px solid ${(props) => props.theme.layout.borderColor};
  }
`;

// @ts-ignore
const StyledButton = styled(Button)`
  && {
    margin-bottom: 10px;
    text-align: left;
  }
`;

const StyledNativeSelect = styled(NativeSelect)`
  & .MuiNativeSelect-select {
    padding-left: 8px;
    background-color: #e5e5e5;
  }
`;

const StyledFormControlLabel = styled(FormControlLabel)`
  & .MuiFormControlLabel-label {
    font-family: 'Open Sans', sans-serif;
    font-size: 14px !important;
    font-weight: 400;
    font-style: normal;
    line-height: 18px;
  }
`;

const DateWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-left: 12px;
  color: ${(props) => props.theme.color.secondary};
`;

export {
  StyledBox,
  FilterList,
  FilterItem,
  StyledButton,
  StyledNativeSelect,
  StyledFormControlLabel,
  DateWrapper
};
