import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { DragDropContext } from 'react-beautiful-dnd';

import { AppDispatch, RootState, AssetsFilters, Notification } from 'interfaces';

import { DEFAULT_JOB_SETTING, FILTERS_NAMES } from 'constant';

import { findUnique } from 'utils/findUnique';

import { JobManagerDataContext } from 'context/jobManagerDataContext';

import Button from 'components/ui/Button';
import ModalConfirm from 'components/ui/ConfirmModal';
import Icons from 'components/ui/Icons';
import Layout from 'components/Layout';
import Notifications from 'components/ui/Notifications';
import JobManagerTabs from 'components/JobManagerTabs';
import JobManagerJobsSlider from 'components/JobManagerJobsSlider';
import JobScheduler from 'components/JobScheduler';
import JobSettings from 'components/JobSettings';
import PageButtonsWrapper from 'components/PageButtonsWrapper';

import { getAssets } from 'features/assets/assetsSlice';
import { deleteCalcId } from 'features/job/jobSlice';

import { getDefaultSettingsForForm } from './utils';

import {
  getData,
  createJob,
  finalizeJob,
  deleteJob
} from './jobManagerSlice';

const PORTFOLIO_DRAGGABLE_ID_PREFIX = 'portfolio';
const SCENARIO_DRAGGABLE_ID_PREFIX = 'scenario';

const JobManager = (): JSX.Element => {
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    dispatch(getData());
    dispatch(getAssets());
  }, [dispatch]);

  const notificationMsg = useSelector((state: RootState) => state.jobManager.notification);
  const defaultScenarios = useSelector((state: RootState) => state.jobManager.scenarios);
  const defaultPortfolios = useSelector((state: RootState) => state.jobManager.portfolios);
  const jobs = useSelector((state: RootState) => state.jobManager.jobs);
  const finalizeJobReady = useSelector((state: RootState) => state.jobManager.finalizeJobReady);
  const calcSettingsId = useSelector((state: RootState) => state.jobManager.calcSettingsId);

  const assetsPayloads = useSelector((state: RootState) => state.assets.assetsPayloads);

  const [notification, setNotification] = useState<Notification>(notificationMsg);
  const [assetsDictionaries, setAssetsDictionaries] = useState<AssetsFilters>({
    [FILTERS_NAMES.name]: [],
    [FILTERS_NAMES.assetClass]: [],
    [FILTERS_NAMES.region]: [],
    [FILTERS_NAMES.priceCurrency]: []
  });

  useEffect(() => {
    setAssetsDictionaries({
      [FILTERS_NAMES.name]: assetsPayloads,
      [FILTERS_NAMES.assetClass]: findUnique(assetsPayloads, FILTERS_NAMES.assetClass),
      [FILTERS_NAMES.region]: findUnique(assetsPayloads, FILTERS_NAMES.region),
      [FILTERS_NAMES.priceCurrency]: findUnique(
        assetsPayloads,
        FILTERS_NAMES.priceCurrency
      )
    });
  }, [assetsPayloads]);

  useEffect(() => {
    setNotification(notificationMsg);
  }, [setNotification, notificationMsg]);

  const [portfolios, setPortfolios] = useState(defaultPortfolios);
  const [scenarios, setScenarios] = useState(defaultScenarios);

  const [editedCardId, setEditedCardId] = useState<number | null>(null);
  const [editedCardPortfolios, setEditedCardPortfolios] = useState<any[]>([]);
  const [editedCardScenarios, setEditedCardScenarios] = useState<any[]>([]);
  const [editedCardJobName, setEditedCardJobName] = useState<any>('');

  const [deleteId, setDeleteId] = useState<number | null>(null);
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);

  const [showScheduler, setShowScheduler] = useState(false);
  const [showSettings, setShowSettings] = useState(false);
  const [settings, setSettings] = useState(getDefaultSettingsForForm());

  useEffect(() => {
    setPortfolios(defaultPortfolios);
  }, [defaultPortfolios]);

  useEffect(() => {
    setScenarios(defaultScenarios);
  }, [defaultScenarios]);

  const jobManagerData = useMemo(() => ({
    defaultPortfolios,
    portfolios,
    setPortfolios,
    defaultScenarios,
    scenarios,
    setScenarios,
    editedCardId,
    setEditedCardId,
    editedCardPortfolios,
    setEditedCardPortfolios,
    editedCardScenarios,
    setEditedCardScenarios,
    editedCardJobName,
    setEditedCardJobName
  }), [
    defaultPortfolios,
    portfolios,
    defaultScenarios,
    scenarios,
    editedCardId,
    editedCardPortfolios,
    editedCardScenarios,
    editedCardJobName
  ]);

  const onDragEnd = (result: any) => {
    const { draggableId } = result;
    const [source, id] = draggableId.split('_');

    if (!result.destination) {
      return;
    }

    switch (source) {
      case PORTFOLIO_DRAGGABLE_ID_PREFIX: {
        const selectedPortfolio = portfolios.filter((el: any) => el.id === Number(id)) || [];
        const restPortfolios = portfolios.filter((el: any) => el.id !== Number(id));

        if (editedCardPortfolios.findIndex((el: any) => el.id === Number(id)) === -1) {
          setEditedCardPortfolios([...editedCardPortfolios, ...selectedPortfolio]);
          setPortfolios(restPortfolios);
        }

        break;
      }

      case SCENARIO_DRAGGABLE_ID_PREFIX: {
        const selectedScenario = scenarios.filter((el: any) => el.id === Number(id)) || [];
        const restScenarios = scenarios.filter((el: any) => el.id !== Number(id));

        if (editedCardScenarios.findIndex((el: any) => el.id === Number(id)) === -1) {
          setEditedCardScenarios([...editedCardScenarios, ...selectedScenario]);
          setScenarios(restScenarios);
        }

        break;
      }

      default:
        break;
    }
  };

  const handleCreateNewCard = () => {
    setEditedCardPortfolios([]);
    setEditedCardScenarios([]);
    setPortfolios(defaultPortfolios);
    setScenarios(defaultScenarios);
    setEditedCardJobName('');
  };

  return (
    <Layout>
      <JobManagerDataContext.Provider value={jobManagerData}>
        <DragDropContext onDragEnd={onDragEnd}>
          <JobManagerTabs
            scenarios={scenarios}
            portfolios={portfolios}
          />
          <JobManagerJobsSlider
            jobs={jobs}
            onCreateNewCard={() => handleCreateNewCard()}
            deleteCard={(id: number) => {
              setDeleteId(id);
              setShowDeleteConfirmModal(true);
            }}
          />
        </DragDropContext>
        {
          showSettings ? (
            <JobSettings
              initialValues={{ ...settings }}
              currencyDictionary={assetsDictionaries.priceCurrency || []}
              setShowSettings={setShowSettings}
              onSubmit={(values) => {
                setSettings(values);
                setShowSettings(false);
              }}
            />
          ) : null
        }
        {
          editedCardId === 0 && showScheduler ? (
            <JobScheduler
              setShowScheduler={setShowScheduler}
              onDateSubmit={(date: Date) => {
                dispatch(finalizeJob({ calcSettingsId, valuationDate: date }));
                dispatch(getData());
                handleCreateNewCard();
              }}
            />
          ) : null
        }
        <PageButtonsWrapper>
          {
            editedCardId === 0 ? (
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => {
                  setShowSettings(true);
                }}
              >
                Setting
              </Button>
            ) : null
          }
          <Button
            color="secondary"
            disabled={
              editedCardId !== 0
                ? true
                : !(editedCardJobName && (editedCardPortfolios.length || editedCardScenarios.length))
            }
            onClick={() => {
              dispatch(createJob({
                calcId: editedCardId,
                jobName: editedCardJobName,
                settings,
                portfolioList: editedCardPortfolios,
                scenarioList: editedCardScenarios
              }));
            }}
          >
            {editedCardId !== 0 ? 'Edit' : 'Save'}
          </Button>
          {
            editedCardId === 0 ? (
              <Button
                color="primary"
                disabled={!finalizeJobReady}
                onClick={() => {
                  setShowScheduler(true);
                }}
                endIcon={(
                  <Icons iconType="checkmark" size={7} />
                )}
              >
                Schedule
              </Button>
            ) : null
          }
        </PageButtonsWrapper>
      </JobManagerDataContext.Provider>
      {
        showDeleteConfirmModal ? (
          <ModalConfirm
            title="Confirm delete"
            open={showDeleteConfirmModal}
            onClose={() => setShowDeleteConfirmModal(false)}
            onConfirm={() => {
              setShowDeleteConfirmModal(false);

              dispatch(deleteCalcId({ calcId: deleteId }));
              dispatch(deleteJob({ id: deleteId }));

              setDeleteId(null);
            }}
            onCancel={() => setShowDeleteConfirmModal(false)}
          >
            <p>Are you sure, you want to delete this calcSettings?</p>
            <p>You will not be able to recover it.</p>
          </ModalConfirm>
        ) : null
      }
      {
        notification.message && notification.open ? (
          <Notifications
            notification={notification}
            setNotification={setNotification}
          />
        ) : null
      }
    </Layout>
  );
};

export default JobManager;
