import styled from '@emotion/styled/macro';

import { TableCell, TableRow } from '@mui/material';

import { hexToRgbMixin } from 'styles/mixins';

import InfoIconBox from 'components/ui/InfoIconBox';

const Wrapper = styled.div`
  margin-top: 25px;
  margin-bottom: 20px;
`;

const StyledTableCell = styled(TableCell)`
  padding: 8px 10px;
  border-bottom: 0;

  &:nth-of-type(1) {
    width: 445px;
    max-width: 445px;
  }
  &:nth-of-type(5) {
    width: 280px;
    max-width: 280px;
  }
  &:nth-last-of-type(2) {
    width: 120px;
    padding-right: 0;
  }
  &:first-of-type {
    padding-left: 0;
  }
  // Delete button
  &:last-of-type {
    position: absolute;
    top: 50%;
    right: -30px;
    transform: translateY(-50%);
    width: 25px;
    padding: 0;
    opacity: 0;
    transition: all .1s ease-in-out;
  }
`;

const StyledTableRow = styled(TableRow)`
  position: relative;

  &:last-of-type {
    ${StyledTableCell} {
      padding-bottom: 0;
    }
  }
  &:hover {
    ${StyledTableCell}:last-of-type {
      opacity: 1;
    }
  }
`;

const HighlightBox = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 0 3px;
  width: 100%;
  height: 32px;
  padding: 0 10px;
  background: ${(props) => hexToRgbMixin(props.theme.color.secondary, 0.2)};
  font-size: 12px;
  color: ${(props) => props.theme.color.secondary};
`;

// @ts-ignore
const StyledInfoIconBox = styled(InfoIconBox)`
  display: inline-flex;
  align-items: center;
  margin-left: auto;
`;

export {
  Wrapper,
  StyledTableCell,
  HighlightBox,
  StyledInfoIconBox,
  StyledTableRow
};
