import React from 'react';
import styled from '@emotion/styled';

import { Modal } from '@material-ui/core';

import { IModalProps } from 'components/ui/Modal';

const StyledModal = styled(({
  footerElement,
  ...props
} : IModalProps) => (
  <Modal {...props}>{props.children}</Modal>
))`
  & > div[style] {
    background-color: rgba(0 0 0 / .2) !important;
  }
`;

const Wrapper = styled.div`
  box-sizing: border-box;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 90%;
  max-width: 90%;
  height: auto;
  background: ${(props) => props.theme.color.gray};
  box-shadow: 0 11px 15px -7px rgba(0 0 0 / .2), 0 24px 38px 3px rgba(0 0 0 / .14), 0 9px 46px 8px rgba(0 0 0 / .12);

  @media (min-width: 1200px) {
    min-width: 1000px;
    max-width: 1000px;
  }

  @media (min-width: 1500px) {
    min-width: 1350px;
    max-width: 1350px;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 25px 35px 10px;
`;

const Content = styled.div`
  box-sizing: border-box;
  overflow-y: auto;
  max-height: 76.6vh;
  max-height: calc(var(--vh, 1vh) * 76.6);
  padding: 10px 35px;

  @media only screen and (max-height: 500px) {
    max-height: 40vh;
    max-height: calc(var(--vh, 1vh) * 40);
  }

  @media (min-width: 800px) {
    max-height: 76.6vh;
    max-height: calc(var(--vh, 1vh) * 76.6);
  }

  @media only screen and (max-height: 500px) {
    max-height: 40vh;
    max-height: calc(var(--vh, 1vh) * 40);
  }
`;

const Title = styled.h2`
  font-size: 24px;
`;

const Footer = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 0 20px;
  padding: 25px 35px 35px;
  text-align: center;
`;

const CloseButton = styled.button`
  display: flex;
  align-items: center;
  margin-right: -10px;
  padding: 10px;
  border-radius: 50%;

  &:hover {
    background: #c6c6c6;
  }
`;

export {
  StyledModal,
  Wrapper,
  Header,
  Title,
  Content,
  Footer,
  CloseButton
};
