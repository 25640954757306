import React, { useEffect } from 'react';

import ReactApexChart from 'react-apexcharts';

import {
  Wrapper
} from './DateChart.styles';

export interface DateChartProps {
  values: any;
}

const DateChart = (props: DateChartProps): JSX.Element => {
  const options = {
    colors: [
      // eslint-disable-next-line func-names
      function ({ dataPointIndex }: any) {
        if (dataPointIndex < props.values[1] && dataPointIndex > props.values[0]) {
          return '#014547';
        } else {
          return '#E5E5E5';
        }
      }
    ],
    chart: {
      toolbar: {
        show: false
      },
      height: 120,
      type: 'bar',
      zoom: {
        enabled: false
      }
    },
    stroke: {
      curve: 'smooth',
      width: 2
    },
    grid: {
      show: false
    },
    legend: {
      show: false
    },
    dataLabels: {
      enabled: false
    },
    xaxis: {
      type: 'category',
      position: 'top',
      categories: [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec'
      ],
      show: false,
      labels: {
        show: false
      },
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      },
      tooltip: {
        enabled: false
      }
    },
    yaxis: {
      show: false,
      labels: {
        show: false
      },
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      },
      crosshairs: {
        show: false
      }
    },
    title: {
      text: undefined,
      align: 'left'
    },
    tooltip: {
      enabled: false
    }
  };

  const series = [
    {
      name: 'Inflation',
      data: [
        23, 31, 30, 10, 33, 36, 32, 23, 14, 18, 15, 12, 23, 31, 30, 10, 33, 36,
        32, 23, 14, 18, 15, 12, 23, 31, 30, 10, 33, 36, 32, 23, 14, 18, 15, 12,
        23, 31, 30, 10, 33, 36, 32, 23, 14, 18, 15, 12
      ]
    }
  ];

  useEffect(() => {
    options.colors = [
      ...options.colors,
      // eslint-disable-next-line func-names
      function ({ dataPointIndex }) {
        if (dataPointIndex < props.values[1] && dataPointIndex > props.values[0]) {
          return '#014547';
        } else {
          return '#E5E5E5';
        }
      }
    ];
  });

  return (
    <Wrapper>
      <ReactApexChart
        // @ts-ignore
        options={options}
        series={series}
        type="bar"
        width={280}
        height={140}
      />
    </Wrapper>
  );
};

export default DateChart;
