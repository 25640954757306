import React from 'react';
import styled from '@emotion/styled';

import { Tabs } from '@material-ui/core';

const StyledTabs = styled(({
  ...props
}) => (
  <Tabs {...props} />
))`
  & .MuiTabs-indicator {
    top: 0;
    bottom: auto;
    background: ${(props) => props.theme.color.secondary};
  }
`;

export {
  StyledTabs
};
