import styled from '@emotion/styled';
import { css } from '@emotion/react';

import Icons from 'components/ui/Icons';

import { JobTileProps } from 'components/ui/JobTile';

const Wrapper = styled.div<JobTileProps>`
  display: flex;
  flex: 1 1 auto;
  align-items: center;
  gap: 10px;
  min-height: 60px;
  margin-bottom: 10px;
  padding: 5px 15px;
  border: 1px solid #dfdfdf;
  border-right-width: 7px;
  background: ${(props) => props.theme.colorMono.white};
  font-size: 16px;

  ${(props) => props.small && css`
    margin-bottom: 5px;
  `};

  &:last-of-type {
    margin-bottom: 0;
  }

  ${(props) => props.tileType === 'primary' && css`
    border-right-color: ${props.theme.color.primary};
  `};
  ${(props) => props.tileType === 'secondary' && css`
    border-right-color: ${props.theme.color.secondary};
  `};
  ${(props) => props.tileType === 'default' && css`
    border-right-color: #16161a;
  `};
  ${(props) => props.tileType === 'none' && css`
    border-right-color: #d7d7d7;
  `};
`;

// @ts-ignore
const StyledDragIcons = styled(Icons)`
  margin-top: 2px;
`;

const DeleteButton = styled.button`
  margin-left: auto;
`;

// @ts-ignore
const StyledDeleteIcons = styled(Icons)`
  display: inline-block;
  color: #d0d0d1;

  &:hover {
    color: #9c9c9c;
  }
`;

export {
  Wrapper,
  StyledDragIcons,
  DeleteButton,
  StyledDeleteIcons
};
