import React from 'react';

import { Portfolio, Scenario } from 'interfaces';

interface JobManagerContext {
  setEditedCardPortfolios: (portfolios: Portfolio[]) => void;
  setEditedCardScenarios: (scenarios: Scenario[]) => void;
  setPortfolios: (portfolios: Portfolio[]) => void;
  defaultPortfolios: Portfolio[];
  setScenarios: (scenarios: Scenario[]) => void;
  defaultScenarios: Scenario[];
  setEditedCardJobName: (job: string) => void;
  editedCardPortfolios: Portfolio[];
  editedCardScenarios: Scenario[];
  portfolios: Portfolio[];
  scenarios: Scenario[];
  editedCardJobName: string;
  setEditedCardId: (card: number) => void;
}

const defaultContext: JobManagerContext = {
  setEditedCardPortfolios: () => {},
  setEditedCardScenarios: () => {},
  setPortfolios: () => {},
  defaultPortfolios: [],
  setScenarios: () => {},
  defaultScenarios: [],
  setEditedCardJobName: () => {},
  editedCardPortfolios: [],
  editedCardScenarios: [],
  portfolios: [],
  scenarios: [],
  editedCardJobName: '',
  setEditedCardId: () => {}
};

export const JobManagerDataContext = React.createContext<JobManagerContext>(defaultContext);
