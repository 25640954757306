import React from 'react';

import { Auth0Provider } from '@auth0/auth0-react';

import {
  authAudience,
  authClientId,
  authDomain,
  authRedirectUrl,
  authScope
} from 'config/auth';

export interface AuthWithHistoryProviderProps {
  children: React.ReactNode;
}

const AuthWithHistoryProvider = (props: AuthWithHistoryProviderProps): JSX.Element => (
  <Auth0Provider
    domain={authDomain}
    clientId={authClientId}
    cacheLocation="localstorage"
    authorizationParams={{
      audience: authAudience,
      scope: authScope,
      redirect_uri: authRedirectUrl
    }}
    useRefreshTokens
    useRefreshTokensFallback={false}
  >
    {props.children}
  </Auth0Provider>
);

export default AuthWithHistoryProvider;
