import React from 'react';

import Icons from 'components/ui/Icons';

import {
  Wrapper,
  ActionButton
} from './ManagerTableActions.styles';

export interface ManagerTableActionsProps {
  onDelete: (id: number) => void;
  onEdit: (id: number) => void;
  onView: (id: number) => void;
  data: any;
}
const defaultProps = {
};

const ManagerTableActions = (props: ManagerTableActionsProps): JSX.Element => (
  <Wrapper>
    <ActionButton onClick={() => props.onEdit(props.data.id)}>
      <Icons iconType="edit" size={15} />
    </ActionButton>
    <ActionButton onClick={() => props.onView(props.data.id)}>
      <Icons iconType="folder-details" size={15} />
    </ActionButton>
    <ActionButton onClick={() => props.onDelete(props.data.id)}>
      <Icons iconType="trash" size={15} />
    </ActionButton>
  </Wrapper>
);

ManagerTableActions.defaultProps = defaultProps;

export default ManagerTableActions;
