import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { AppDispatch, RootState } from 'interfaces';

import Loader from 'components/ui/Loader';

import Layout from 'components/Layout';
import ScenarioViewDetails from 'components/ScenarioViewDetails';
import ScenarioViewAssetsTable from 'components/ScenarioViewAssetsTable';

import { getAssets, getAssetsCurves } from 'features/assets/assetsSlice';
import { getScenario } from './scenarioViewSlice';

const ScenarioView = (): JSX.Element => {
  const { scenarioId }: any = useParams();

  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    dispatch(getAssets());
    dispatch(getAssetsCurves());
  }, [dispatch]);

  const assetsPayloads = useSelector((state: RootState) => state.assets.assetsPayloads);

  useEffect(() => {
    if (scenarioId && assetsPayloads.length) {
      dispatch(getScenario({ scenarioId }));
    }
  }, [dispatch, scenarioId, assetsPayloads]);

  const scenarioDetails = useSelector((state: RootState) => state.scenarioView.scenarioDetails);
  const scenarioForecast = useSelector((state: RootState) => state.scenarioView.scenarioForecast);
  const loading = useSelector((state: RootState) => state.scenarioView.loading);

  return (
    <Layout>
      {
        !loading ? (
          <React.Fragment>
            <ScenarioViewDetails
              scenarioDetails={scenarioDetails}
              unSubscribeOnClick={() => {
                // @TODO: unsubscribe
              }}
            />
            <ScenarioViewAssetsTable
              scenarioForecast={scenarioForecast}
            />
          </React.Fragment>
        ) : (
          <Loader loaderType="static" />
        )
      }
    </Layout>
  );
};

export default ScenarioView;
