import { configureStore } from '@reduxjs/toolkit';
import { setAutoFreeze } from 'immer';

import assetsReducer from 'features/assets/assetsSlice';
import dashboardMainReducer from 'features/dashboardMain/dashboardMainSlice';
import dashboardAnalyticsReducer from 'features/dashboardAnalytics/dashboardAnalyticsSlice';
import createPortfolioReducer from 'features/createPortfolio/createPortfolioSlice';
import portfolioViewReducer from 'features/portfolioView/portfolioViewSlice';
import portfolioManagerReducer from 'features/portfolioManager/portfolioManagerSlice';
import createScenarioReducer from 'features/createScenario/createScenarioSlice';
import scenarioViewReducer from 'features/scenarioView/scenarioViewSlice';
import scenarioManagerReducer from 'features/scenarioManager/scenarioManagerSlice';
import scenarioExplorerReducer from 'features/scenarioExplorer/scenarioExplorerSlice';
import jobReducer from 'features/job/jobSlice';
import jobViewReducer from 'features/jobView/jobViewSlice';
import jobManagerReducer from 'features/jobManager/jobManagerSlice';

setAutoFreeze(false);

const store = configureStore({
  reducer: {
    assets: assetsReducer,
    dashboardMain: dashboardMainReducer,
    dashboardAnalytics: dashboardAnalyticsReducer,
    createPortfolio: createPortfolioReducer,
    portfolioView: portfolioViewReducer,
    portfolioManager: portfolioManagerReducer,
    createScenario: createScenarioReducer,
    scenarioView: scenarioViewReducer,
    scenarioManager: scenarioManagerReducer,
    scenarioExplorer: scenarioExplorerReducer,
    job: jobReducer,
    jobView: jobViewReducer,
    jobManager: jobManagerReducer
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    immutableCheck: false,
    serializableCheck: false
  })
});

export default store;
