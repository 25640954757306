export const dateComparator = (date1: any, date2: any) => {
  const date1Number = monthToComparableNumber(date1);
  const date2Number = monthToComparableNumber(date2);
  if (date1Number === null && date2Number === null) {
    return 0;
  }
  if (date1Number === null) {
    return -1;
  }
  if (date2Number === null) {
    return 1;
  }
  return date1Number - date2Number;
};

// eg 29/08/2004 gets converted to 20040829
export const monthToComparableNumber = (date: any) => {
  if (date === undefined || date === null || date.length !== 10) {
    return null;
  }

  const yearNumber = Number.parseInt(date.substring(6, 10), 10);
  const monthNumber = Number.parseInt(date.substring(3, 5), 10);
  const dayNumber = Number.parseInt(date.substring(0, 2), 10);

  return yearNumber * 10000 + monthNumber * 100 + dayNumber;
};
