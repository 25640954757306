import React, { useRef, useState, useEffect, useCallback } from 'react';

import { ColDef, ColGroupDef } from 'ag-grid-enterprise';

import { ComponentsTableRow } from 'interfaces';

import { COMPONENTS_TYPE_IDS } from 'constant';

import { textRenderer } from 'utils/tableFormatters';

import ScenarioForecastInputsTable from 'components/ScenarioForecastInputsTable';

import Box from 'components/ui/Box';
import Typography from 'components/ui/Typography';

import { getLabel } from './utils';

import {
  Wrapper,
  DeleteButton,
  StyledIcons,
  TableEditOverlay
} from './ScenarioForecastInputs.styles';

type InitialDataRow = {
  [name: string]: number;
};
type InitialData = {
  [name: number]: InitialDataRow[];
};

export interface ScenarioForecastInputsProps {
  tableData: any;
  setTableData: any;
  staticForecastType: any;
  id?: string;
}
const defaultProps = {
  id: null
};

const ScenarioForecastInputs = (props: ScenarioForecastInputsProps): JSX.Element => {
  const gridRef: any = useRef();

  const [rowData, setRowData] = useState(props.tableData);
  const [initialData, setInitialData] = useState<InitialData>({});

  const [cellIsEdit, setCellIsEdit] = useState<boolean>(false);

  useEffect(() => {
    setRowData(props.tableData);
  }, [props.tableData]);

  const updateTableData = () => {
    const draft = gridRef?.current?.api?.rowModel?.rowsToDisplay;
    const updatedRows: any[] = [];
    draft.map((node: any) => updatedRows.push(node.data));

    props.setTableData(updatedRows);
  };

  const numberParser = (params: any) => {
    const newInitialData: InitialData = { ...initialData };
    newInitialData[params.node.id as number] = {
      ...newInitialData[params.node.id],
      [params.colDef.field as string]: Number(params.oldValue)
    };

    setInitialData(newInitialData);
    updateTableData();

    return parseFloat(params.newValue).toString();
  };

  const deleteRowComponent = ({ data }: { data: ComponentsTableRow }) => (
    <DeleteButton
      onClick={() => {
        const removeAlsoMin = data.componentType === COMPONENTS_TYPE_IDS.max
          ? rowData.filter((el: ComponentsTableRow) => el.assetID === data.assetID)
          : [];

        gridRef.current.api.applyTransaction({ remove: [data, ...removeAlsoMin] });
        updateTableData();
      }}
    >
      <StyledIcons iconType="closed-filled" size={14} />
    </DeleteButton>
  );

  const timeColumnDefault = {
    minWidth: 90,
    maxWidth: 530,
    cellClass: 'center-row-container',
    valueParser: numberParser,
    cellClassRules: {
      positive: (params: any) => {
        const rowId = params.node.id;
        const field = params.colDef?.field;

        return initialData[rowId] && initialData[rowId][field]
          && Number(params.value) > Number(initialData[rowId][field]);
      },
      negative: (params: any) => {
        const rowId = params.node.id;
        const field = params.colDef?.field;

        return initialData[rowId] && initialData[rowId][field]
          && Number(params.value) < Number(initialData[rowId][field]);
      }
    },
    valueFormatter: (params: any) => params.value === 0 || params.value
      ? Number(params.value).toFixed(2)
      : ''
  };

  const columnDefs: Array<ColGroupDef | ColDef> = [
    {
      field: 'assetName',
      headerName: 'Asset Class',
      flex: 1,
      minWidth: 310,
      maxWidth: 610,
      editable: false,
      filter: 'agTextColumnFilter',
      pinned: 'left',
      suppressMovable: true,
      cellRenderer: textRenderer,
      rowSpan: (params) => params.data.componentType === COMPONENTS_TYPE_IDS.max ? 2 : 1,
      colSpan: (params) => params.data.componentType === COMPONENTS_TYPE_IDS.point ? 2 : 1,
      cellClass: 'asset-class',
      cellClassRules: {
        'cell-span': (params: any) => params.data.componentType === COMPONENTS_TYPE_IDS.max
      }
    },
    {
      field: 'minMaxLabel',
      headerName: '',
      minWidth: 70,
      maxWidth: 70,
      editable: false,
      pinned: 'left',
      suppressMovable: true,
      cellClass: 'min-max-label'
    },
    {
      ...timeColumnDefault,
      field: 'M3',
      headerName: '3M'
    },
    {
      ...timeColumnDefault,
      field: 'M6',
      headerName: '6M'
    },
    {
      ...timeColumnDefault,
      field: 'M12',
      headerName: '12M'
    },
    {
      ...timeColumnDefault,
      field: 'M18',
      headerName: '18M'
    },
    {
      ...timeColumnDefault,
      field: 'M24',
      headerName: '2Y'
    },
    {
      ...timeColumnDefault,
      field: 'M36',
      headerName: '3Y'
    },
    {
      ...timeColumnDefault,
      field: 'M48',
      headerName: '4Y'
    },
    {
      ...timeColumnDefault,
      field: 'M60',
      headerName: '5Y'
    },
    {
      ...timeColumnDefault,
      field: 'M120',
      headerName: '10Y'
    },
    {
      field: 'action',
      hide: false,
      suppressMovable: true,
      cellRenderer: deleteRowComponent,
      editable: false,
      pinned: 'right',
      width: 25,
      maxWidth: 25,
      rowSpan: (params) => params.data.componentType === COMPONENTS_TYPE_IDS.max ? 2 : 1,
      cellClass: 'lock-pinned'
    }
  ];

  const onCellEditingStarted = useCallback((event) => {
    setCellIsEdit(true);
  }, []);

  const onCellEditingStopped = useCallback((event) => {
    setCellIsEdit(false);
  }, []);

  return (
    <Wrapper id={props.id}>
      <Typography withMarginBottom>
        {getLabel(props.staticForecastType)}
        {/* eslint-disable-next-line react/button-has-type */}
      </Typography>
      <Box>
        {
          cellIsEdit ? (
            <TableEditOverlay
              onClick={() => {
                gridRef?.current?.api?.stopEditing();
              }}
            />
          ) : null
        }
        <ScenarioForecastInputsTable
          gridRef={gridRef}
          rowData={rowData}
          columnDefs={columnDefs}
          withActions
          editable
          rowClassRules={{
            'row-span': (params: any) => params.data.componentType === COMPONENTS_TYPE_IDS.max
          }}
          onCellEditingStarted={onCellEditingStarted}
          onCellEditingStopped={onCellEditingStopped}
        />
      </Box>
    </Wrapper>
  );
};
ScenarioForecastInputs.defaultProps = defaultProps;

export default ScenarioForecastInputs;
