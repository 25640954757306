import React from 'react';

import { TabProps } from '@material-ui/core';

import {
  StyledTab
} from './Tab.styles';

const Tab = (props: TabProps): JSX.Element => (
  <StyledTab
    {...props}
  />
);

export default Tab;
