import { AssetCurve, Asset, Components } from 'interfaces';

import { getComponentsFromDataTables } from 'utils/scenario/getComponentsFromDataTables';

export const isDataTableNotInDefaultValues = (
  assetsPayloads: Asset[],
  assetsCurves: AssetCurve[],
  dataTable: Components[]
): boolean => {
  const components = getComponentsFromDataTables(assetsPayloads, assetsCurves, dataTable);

  return components.length > 0;
};
