import * as yup from 'yup';
import { defaultMessages } from 'constant';

export const portfolioValidationSchema = yup.object({
  name: yup.string().required(defaultMessages.required).nullable(),
  currency: yup.string().required(defaultMessages.required).nullable(),
  referenceDate: yup.date()
    .required(defaultMessages.required)
    .typeError(defaultMessages.fieldMustBeDateFormat)
    .nullable()
    .default(undefined),
  description: yup.string().required(defaultMessages.required).nullable(),
  holdings: yup.array()
    .of(
      yup.object().shape({
        description: yup.string().required(defaultMessages.required).nullable(),
        region: yup.string().nullable(),
        priceCurrency: yup.string().nullable(),
        assetClass: yup.string().nullable(),
        asset: yup.string().required(defaultMessages.required).nullable(),
        quantity: yup.number().required(defaultMessages.required).nullable()
      })
    )
});
