import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { Alert } from '@mui/material';

const StyledAlert = styled(Alert)`
  border-left: 3px solid;
  border-radius: 0;
  background: #16161a; /* @TODO: move to theme */

  ${(props) => props.severity === 'success' && css`
    color: #cefdd9; /* @TODO: move to theme */
  `};
  ${(props) => props.severity === 'error' && css`
    color: ${props.theme.color.primary};
  `};
`;

export {
  StyledAlert
};
