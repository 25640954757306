import styled from '@emotion/styled';
import { css } from '@emotion/react';

interface ScrollbarWrapperProps {
  small?: boolean | undefined;
  big?: boolean | undefined;
  noPadding?: boolean | undefined;
  noScrollBar?: boolean | undefined;
}

const Scrollbar = styled.div<ScrollbarWrapperProps>`
  ${(props) => !props.noScrollBar && css`
    --track-background: #f1f1f1;
    --thumb-background: #e1e1e1;
    overflow: hidden auto;
    width: 100%;
    height: 100%;
    padding-right: 6px;
    scrollbar-width: thin;
    scrollbar-color: var(--thumb-background) var(--track-background);

    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }
    &::-webkit-scrollbar-track {
      background: var(--track-background);
      border-radius: 3px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 3px;
      background-color: var(--thumb-background);
    }

    ${props.small && css`
      padding-right: 3px;

      &::-webkit-scrollbar {
        width: 4px;
        height: 4px;
      }
    `};
    ${props.big && css`
      padding-right: 10px;
    `};

    ${props.noPadding && css`
      padding-right: 0;
    `};
  `};
`;

export default Scrollbar;
