import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { Asset, PortfolioViewData } from 'interfaces';

import { fetchPortfolio } from 'api';

const initialState: PortfolioViewData = {
  portfolioDetails: {
    name: null,
    description: null,
    currency: null,
    usageType: null,
    holdingsType: null,
    referenceDate: null,
    holdings: [],
    tagID: null
  },
  loading: false
};

export const getPortfolio = createAsyncThunk(
  'portfolioView/getPortfolio',
  // @ts-ignore
  async ({ portfolioId } : any, { getState }: any) => fetchPortfolio(portfolioId)
    .then((response: any) => {
      const { assetsPayloads } = getState().assets;

      return {
        assetsPayloads,
        portfolio: response
      };
    })
);

const portfolioView = createSlice({
  name: 'portfolioView',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPortfolio.pending, (state) => ({
        ...state,
        loading: true
      }))
      .addCase(getPortfolio.fulfilled, (state, action) => {
        const { assetsPayloads, portfolio } = action.payload;

        const holdings: any[] = [];

        portfolio.holdings.forEach((el: any) => {
          const asset = assetsPayloads.filter((asset: Asset) => asset.id === el.assetID)[0];

          holdings.push({
            ...el,
            description: asset?.name || el.assetID,
            region: asset?.region,
            priceCurrency: asset?.priceCurrency,
            assetClass: asset?.assetClass,
            asset: asset?.asset,
            quantity: Number(el.quantity * 100).toFixed(2)
          });
        });

        return {
          ...state,
          portfolioDetails: {
            ...portfolio,
            holdings
          },
          loading: false
        };
      })
      // @TODO: Handle error
      .addCase(getPortfolio.rejected, (state) => ({
        ...state,
        loading: false
      }));
  }
});

export default portfolioView.reducer;
