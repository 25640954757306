import React from 'react';

import {
  Wrapper,
  InfoBoxes,
  InfoBox,
  Label,
  Number
} from './JobViewDetails.styles';

export interface JobViewDetailsProps {
  scenariosListLength?: number;
  portfolioListLength?: number;
  rulesListLength?: number;
}
const defaultProps = {
  scenariosListLength: 0,
  portfolioListLength: 0,
  rulesListLength: 0
};

const JobViewDetails = (props: JobViewDetailsProps): JSX.Element => (
  <Wrapper>
    <InfoBoxes>
      <InfoBox boxType="portfolios">
        <Label>Portfolio total count</Label>
        <Number>{props.portfolioListLength}</Number>
      </InfoBox>
      <InfoBox boxType="scenarios">
        <Label>Scenario total count</Label>
        <Number>{props.scenariosListLength}</Number>
      </InfoBox>
      <InfoBox boxType="rules">
        <Label>Rules total count</Label>
        <Number>{props.rulesListLength}</Number>
      </InfoBox>
    </InfoBoxes>
  </Wrapper>
);

JobViewDetails.defaultProps = defaultProps;

export default JobViewDetails;
