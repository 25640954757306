import styled from '@emotion/styled';

import TextField from 'components/ui/TextField';

// @ts-ignore
const StyledTextField = styled(TextField)`
  & .MuiInputBase-adornedEnd {
    padding-right: 37px;
  }
`;

export {
  StyledTextField
};
