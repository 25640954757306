import { COMPONENTS_TYPE_IDS, RANGE_TYPE_LABELS } from 'constant';

export const getMinMaxLabel = (componentType: number): string | null => {
  switch (componentType) {
    case COMPONENTS_TYPE_IDS.point: return null;
    case COMPONENTS_TYPE_IDS.min: return RANGE_TYPE_LABELS.min;
    case COMPONENTS_TYPE_IDS.max: return RANGE_TYPE_LABELS.max;

    default: return null;
  }
};
