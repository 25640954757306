import React, { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AppDispatch, RootState } from 'interfaces';

import JobCard from 'components/JobCard';

import { JobManagerDataContext } from 'context/jobManagerDataContext';
import { getCalcSettings, getScenarioSets, getPortfolios } from './jobSlice';

export interface JobProps {
  calcId: number;
  deleteCard?: (id: number) => void;
  editMode?: boolean;
  newCard?: boolean;
  className?: string;
}
const defaultProps = {
  editMode: false,
  newCard: false,
  className: ''
};

const Job = (props: JobProps): JSX.Element => {
  const dispatch = useDispatch<AppDispatch>();

  const { setEditedCardId } = useContext(JobManagerDataContext);

  useEffect(() => {
    if (props.calcId) {
      dispatch(getCalcSettings({ calcId: props.calcId }));
    }
  }, [dispatch, props.calcId]);

  const cardJobName = useSelector((state: RootState) => state.job.entities[props.calcId]?.jobName);
  const scenarioSets = useSelector((state: RootState) => state.job.entities[props.calcId]?.scenarioSets);
  const cardPortfolios = useSelector((state: RootState) => state.job.entities[props.calcId]?.portfolios);

  useEffect(() => {
    if (scenarioSets?.length) {
      dispatch(getScenarioSets({ scenarioSets, calcId: props.calcId }));
    }
  }, [dispatch, scenarioSets, props.calcId]);

  useEffect(() => {
    if (cardPortfolios?.length) {
      dispatch(getPortfolios({ portfolios: cardPortfolios, calcId: props.calcId }));
    }
  }, [dispatch, cardPortfolios, props.calcId]);

  const portfolioList = useSelector((state: RootState) => state.job.entities[props.calcId]?.portfolioList) || [];
  const scenarioList = useSelector((state: RootState) => state.job.entities[props.calcId]?.scenarioList) || [];

  useEffect(() => {
    if (props.editMode) {
      setEditedCardId(props.calcId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.editMode]);

  return (
    <JobCard
      calcId={props.calcId}
      className={props.className}
      editMode={props.editMode}
      newCard={props.newCard}
      jobName={cardJobName}
      portfolioList={portfolioList}
      scenarioList={scenarioList}
      deleteCard={(calcId) => {
        props.deleteCard?.(calcId);
      }}
    />
  );
};

Job.defaultProps = defaultProps;

export default Job;
