import React from 'react';

import Icons from 'components/ui/Icons';

import {
  Wrapper
} from './AuthNotification.styles';

export interface AuthNotificationProps {
  severity?: any | 'success' | 'info' | 'error' | 'warning';
  message?: string;
}
const defaultProps = {
  severity: 'info',
  message: ''
};

const AuthNotification = (props: AuthNotificationProps): JSX.Element | null => {
  const host = process.env.REACT_APP_HOST;

  if (props.message === 'Request failed with status code 401') {
    window.location.href = `${host}`;
  }

  return props.message !== '' ? (
    <Wrapper severity={props.severity}>
      <Icons iconType={props.severity === 'success' ? 'checkmark-filled' : 'warning-filled'} />
      {props.message}
    </Wrapper>
  ) : null;
};

AuthNotification.defaultProps = defaultProps;

export default React.memo(AuthNotification);
