import { METRIC_TYPES_IDS, METRICS_TYPE_LABELS } from 'constant';

export const getMetricsLabel = (metricType: number): string | null => {
  switch (metricType) {
    case METRIC_TYPES_IDS.price: return METRICS_TYPE_LABELS.return;
    case METRIC_TYPES_IDS.volatility: return METRICS_TYPE_LABELS.volatility;

    default: return null;
  }
};
