import styled from '@emotion/styled';
import { css } from '@emotion/react';

interface InfoBoxProps {
  boxType: 'portfolios' | 'scenarios' | 'rules'
}

const Wrapper = styled.div`
  margin-top: 48px;
`;

const InfoBoxes = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 16px;

  @media (min-width: 1200px) {
    flex-direction: row;
  }
`;

const InfoBox = styled.div<InfoBoxProps>`
  flex: 1 1 calc(33.3% - (16px / 2));
  padding: 15px 15px 20px;
  color: ${(props) => props.theme.colorMono.white};

  ${(props) => props.boxType === 'portfolios' && css`
    background: ${props.theme.color.secondary};
  `};
  ${(props) => props.boxType === 'scenarios' && css`
    background: ${props.theme.color.primary};
  `};
  ${(props) => props.boxType === 'rules' && css`
    background: #16161A; // @TODO: theme
  `};
`;

const Label = styled.p`
  margin-bottom: 5px;
  font-size: 12px;
  line-height: 1.5;

  @media (min-width: 1200px) {
    min-height: 36px;
  }

  @media (min-width: 1350px) {
    min-height: 0;
  }
`;

const Number = styled.span`
  font-size: 30px;
  font-weight: 600;
`;

export {
  Wrapper,
  InfoBoxes,
  InfoBox,
  Label,
  Number
};
