import styled from '@emotion/styled';

import FormColumn from 'components/ui/FormColumn';

const InputPlaceholder = styled.div`
  padding: 8px 10px 9px;
  background: ${(props) => props.theme.colorMono.white};
  font-size: 14px;
  color: #9c9c9c;
`;

const Row = styled.div`
  &:not(:last-of-type) {
    margin-bottom: 20px;
  }
`;

const StyledFormColumn = styled(FormColumn)`
  flex: 0 1 125px;
`;

export {
  InputPlaceholder,
  Row,
  StyledFormColumn
};
