import React, { useState, useEffect } from 'react';

import { Droppable } from 'react-beautiful-dnd';

import Tabs from 'components/ui/Tabs';
import Tab from 'components/ui/Tab';
import TabPanel from 'components/ui/TabPanel';
import DraggableWrapper from 'components/ui/DraggableWrapper';
import Scrollbar from 'components/ui/Scrollbar';

import {
  TabWrapper,
  DraggableElementsWrapper,
  StyledJobTile
} from './JobManagerTabs.styles';

export interface JobManagerTabsProps {
  scenarios: any[];
  portfolios: any[];
  className?: string;
}
const defaultProps = {
  className: ''
};

const JobManagerTabs = (props: JobManagerTabsProps): JSX.Element => {
  const [value, setValue] = React.useState(0);

  const getElements = (list: any[]) => {
    const middleIndex = Math.ceil(list.length / 2);

    return {
      primaryList: [...list].splice(0, middleIndex),
      secondaryList: [...list].splice(-middleIndex)
    };
  };

  const [portfolios, setPortfolios] = useState(getElements(props.portfolios));
  const [scenarios, setScenarios] = useState(getElements(props.scenarios));

  useEffect(() => {
    setPortfolios(getElements(props.portfolios));
  }, [props.portfolios]);

  useEffect(() => {
    setScenarios(getElements(props.scenarios));
  }, [props.scenarios]);

  const handleChange = (event: React.FormEvent<HTMLFormElement>, newValue: any) => {
    setValue(newValue);
  };

  return (
    <div>
      <div>
        <Tabs value={value} onChange={handleChange as any}>
          <Tab label="Portfolios" />
          <Tab label="Scenarios" />
          <Tab label="Rules" disabled />
        </Tabs>
      </div>
      <TabPanel noPadding value={value} index={0}>
        <TabWrapper>
          <DraggableElementsWrapper>
            <Scrollbar>
              <Droppable droppableId="portfoliosPrimaryList">
                {(droppableProvided, snapshot) => (
                  <div
                    ref={droppableProvided.innerRef}
                    {...droppableProvided.droppableProps}
                  >
                    {
                      portfolios?.primaryList?.map((portfolio: any, index) => (
                        <DraggableWrapper
                          key={portfolio.id}
                          draggableId={`portfolio_${portfolio.id}`}
                          index={index}
                        >
                          <StyledJobTile
                            draggable
                            tileType="secondary"
                          >
                            {portfolio.name}
                          </StyledJobTile>
                        </DraggableWrapper>
                      ))
                    }
                    {droppableProvided.placeholder}
                  </div>
                )}
              </Droppable>
            </Scrollbar>
          </DraggableElementsWrapper>
          <DraggableElementsWrapper>
            <Scrollbar>
              <Droppable droppableId="portfoliosSecondaryList">
                {(droppableProvided, snapshot) => (
                  <div
                    ref={droppableProvided.innerRef}
                    {...droppableProvided.droppableProps}
                  >
                    {
                      portfolios?.secondaryList?.map((portfolio: any, index) => (
                        <DraggableWrapper
                          key={portfolio.id}
                          draggableId={`portfolio_${portfolio.id}`}
                          index={index}
                        >
                          <StyledJobTile
                            draggable
                            tileType="secondary"
                          >
                            {portfolio.name}
                          </StyledJobTile>
                        </DraggableWrapper>
                      ))
                    }
                    {droppableProvided.placeholder}
                  </div>
                )}
              </Droppable>
            </Scrollbar>
          </DraggableElementsWrapper>
        </TabWrapper>
      </TabPanel>
      <TabPanel noPadding value={value} index={1}>
        <TabWrapper>
          <DraggableElementsWrapper>
            <Scrollbar>
              <Droppable droppableId="scenarioPrimaryList">
                {(droppableProvided, snapshot) => (
                  <div
                    ref={droppableProvided.innerRef}
                    {...droppableProvided.droppableProps}
                  >
                    {
                      scenarios?.primaryList?.map((scenario: any, index) => (
                        <DraggableWrapper
                          key={scenario.id}
                          draggableId={`scenario_${scenario.id}`}
                          index={index}
                        >
                          <StyledJobTile
                            draggable
                            tileType="primary"
                          >
                            {scenario.name}
                          </StyledJobTile>
                        </DraggableWrapper>
                      ))
                    }
                    {droppableProvided.placeholder}
                  </div>
                )}
              </Droppable>
            </Scrollbar>
          </DraggableElementsWrapper>
          <DraggableElementsWrapper>
            <Scrollbar>
              <Droppable droppableId="scenarioSecondaryList">
                {(droppableProvided, snapshot) => (
                  <div
                    ref={droppableProvided.innerRef}
                    {...droppableProvided.droppableProps}
                  >
                    {
                      scenarios?.secondaryList?.map((scenario: any, index) => (
                        <DraggableWrapper
                          key={scenario.id}
                          draggableId={`scenario_${scenario.id}`}
                          index={index}
                        >
                          <StyledJobTile
                            draggable
                            tileType="primary"
                          >
                            {scenario.name}
                          </StyledJobTile>
                        </DraggableWrapper>
                      ))
                    }
                    {droppableProvided.placeholder}
                  </div>
                )}
              </Droppable>
            </Scrollbar>
          </DraggableElementsWrapper>
        </TabWrapper>
      </TabPanel>
      <TabPanel noPadding value={value} index={2}>
        Rules TODO
      </TabPanel>
    </div>
  );
};

JobManagerTabs.defaultProps = defaultProps;

export default JobManagerTabs;
