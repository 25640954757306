import {
  AssetsFiltersWithSelectedOption,
  AssetsFilters,
  Asset
} from 'interfaces';
import { FILTERS_NAMES } from 'constant';

import { findUnique } from 'utils/findUnique';

import { Option } from 'components/ui/Autocomplete';

export const updateFiltersBySelectedOptions = (
  selectedOption: Option | null,
  assetsFieldName: string | null,
  filters: AssetsFiltersWithSelectedOption,
  setFilters: (data: any) => void,
  assetsDictionaries: AssetsFilters
): void => {
  const value = selectedOption?.value || null;
  const newFilters: AssetsFiltersWithSelectedOption = { ...filters };
  let assets: Asset[] = [...assetsDictionaries.name];

  newFilters[assetsFieldName as keyof AssetsFiltersWithSelectedOption].selected = value;

  // First this must complete to second select unique elements from filtered 'assets' table
  Object.keys(FILTERS_NAMES).forEach((key) => {
    if (newFilters[key as keyof AssetsFiltersWithSelectedOption].selected) {
      assets = assets.filter((asset) => (
        asset[key as keyof AssetsFiltersWithSelectedOption] === newFilters[key as keyof AssetsFiltersWithSelectedOption].selected
      ));
    }
  });

  Object.keys(FILTERS_NAMES).forEach((key) => {
    newFilters[key as keyof AssetsFiltersWithSelectedOption] = {
      selected: newFilters[key as keyof AssetsFiltersWithSelectedOption].selected,
      assets: findUnique(assets, key)
    };
  });

  setFilters(newFilters);
};
