import styled from '@emotion/styled';
import { css } from '@emotion/react';

import DroppableInner from 'components/ui/Box';

interface FormBoxProps {
  wider?: boolean | undefined;
}

const Wrapper = styled.div`
  margin-top: 25px;
`;

const StyledBox = styled(DroppableInner)`
  max-width: 868px;
`;

const FormBoxWrapper = styled.div`
  display: flex;
  gap: 25px;
  width: 100%;

  & + & {
    margin-top: 25px;
  }
`;

const FormBox = styled.div<FormBoxProps>`
  ${(props) => props.wider && css`
    max-width: 528px;
    min-width: 60%;
  `};
`;

const ButtonWrapper = styled.div`
  align-self: flex-end;
  margin-left: auto;
`;

export {
  Wrapper,
  StyledBox,
  FormBoxWrapper,
  FormBox,
  ButtonWrapper
};
