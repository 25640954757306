import React, { useState } from 'react';

import {
  DAY_MONTH_YEAR_DATE_FORMAT,
  GENERAL_DATE_PICKER_OPEN_TO,
  GENERAL_DATE_PICKER_VIEWS
} from 'constant';

import FormHeading from 'components/ui/FormHeading';
import DatePicker from 'components/ui/DatePicker';
import StaticDatePicker from 'components/ui/StaticDatePicker';
import Button from 'components/ui/Button';
import TextField from 'components/ui/TextField';

import {
  StyledBox,
  ButtonsWrapper,
  BgLayer
} from './JobScheduler.styles';

export interface JobSchedulerProps {
  setShowScheduler: (data: any) => void;
  onDateSubmit: (date: Date) => void;
}

const JobScheduler = (props: JobSchedulerProps): JSX.Element => {
  const [jobDate, setJobDate] = useState<any>('');

  return (
    <React.Fragment>
      <BgLayer />
      <StyledBox>
        <FormHeading withBorder={false}>
          Select valuation date
        </FormHeading>
        <DatePicker
          id="jobDate"
          name="jobDate"
          placeholder="dd/mm/yyyy"
          inputFormat={DAY_MONTH_YEAR_DATE_FORMAT}
          views={GENERAL_DATE_PICKER_VIEWS}
          openTo={GENERAL_DATE_PICKER_OPEN_TO.day}
          onChange={(value: string) => {
            setJobDate(value);
          }}
          value={jobDate}
          withMarginBottom={false}
        />
        <StaticDatePicker
          openTo="day"
          value={jobDate}
          onChange={(value: any) => {
            setJobDate(value);
          }}
          // @ts-ignore
          renderInput={(params) => <TextField {...params} />}
          disablePast
        />
        <ButtonsWrapper>
          <Button
            variant="outlined"
            color="default"
            onClick={() => props.setShowScheduler(false)}
          >
            Cancel
          </Button>
          <Button
            color="secondary"
            disabled={!jobDate}
            onClick={() => {
              props.onDateSubmit(jobDate);
              props.setShowScheduler(false);
            }}
          >
            Save
          </Button>
        </ButtonsWrapper>
      </StyledBox>
    </React.Fragment>
  );
};

export default JobScheduler;
