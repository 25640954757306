import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { ScenarioManagerData } from 'interfaces';

import { fetchScenarios, removeScenario } from 'api';

const initialState: ScenarioManagerData = {
  notification: {
    open: false,
    message: null,
    type: undefined
  },
  refreshList: false,
  scenarios: []
};

export const getScenarios = createAsyncThunk(
  'scenarioManager/getScenarios',
  // @ts-ignore
  async () => fetchScenarios().then((response: any) => response)
);

export const deleteScenario = createAsyncThunk(
  'scenarioManager/deleteScenario',
  // @ts-ignore
  async ({ scenarioId }) => removeScenario(scenarioId).then((response: any) => response)
);

const scenarioManager = createSlice({
  name: 'scenarioManager',
  initialState,
  reducers: {
    resetRefresh (state) {
      return {
        ...state,
        refreshList: initialState.refreshList
      };
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getScenarios.fulfilled, (state, action) => {
        const scenariosNotSorted = action.payload.map((el: any) => ({
          ...el,
          tags: el.hashTags?.join(', ')
        }));

        const scenarios = [...scenariosNotSorted]
          .sort((a, b) => Number(b.id) - Number(a.id));

        return {
          ...state,
          scenarios
        };
      })
      .addCase(getScenarios.rejected, () => {
        // @TODO: Handle error
      })
      .addCase(deleteScenario.fulfilled, (state) => ({
        ...state,
        refreshList: true,
        notification: {
          open: true,
          message: 'Successfully deleted scenario',
          type: 'success'
        }
      }))
      .addCase(deleteScenario.rejected, (state, action) => ({
        ...state,
        notification: {
          open: true,
          message: 'Failed to delete scenario',
          type: 'error'
        }
      }));
  }
});

export const { resetRefresh } = scenarioManager.actions;
export default scenarioManager.reducer;
