import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { Button } from '@material-ui/core';

import { hexToRgbMixin } from 'styles/mixins';

import { IButtonProps } from 'components/ui/Button';

const StyledButton = styled(({
  componentFunction,
  ...props
} : IButtonProps) => (
  <Button {...props} />
))`
  border: 1px solid;
  border-radius: 0;
  box-shadow: none;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-transform: none;

  &:hover,
  &:active,
  &:focus,
  &:disabled,
  &:disabled:hover,
  &.Mui-disabled,
  &.Mui-disabled:hover {
    box-shadow: none;
  }

  ${(props) => props.variant === 'contained' && props.color === 'primary' && css`
    border-color: ${props.theme.color.primary};
    background: ${props.theme.color.primary};

    &:hover {
      border-color: ${props.theme.color.primaryHover};
      background: ${props.theme.color.primaryHover};
    }
    &:focus {
      border-color: ${props.theme.color.primaryFocus};
    }
    &:active {
      border-color: ${props.theme.color.primaryActive};
      background: ${props.theme.color.primaryActive};
    }
    &:disabled,
    &:disabled:hover,
    &.Mui-disabled,
    &.Mui-disabled:hover {
      border-color: #c6c6c6;
      background: #c6c6c6;
      color: #8d8d8d;
    }
  `};

  ${(props) => props.variant === 'contained' && props.color === 'secondary' && css`
    border-color: ${props.theme.color.secondary};
    background: ${props.theme.color.secondary};

    &:hover {
      border-color: ${props.theme.color.secondaryHover};
      background: ${props.theme.color.secondaryHover};
    }
    &:focus {
      border-color: ${props.theme.color.secondaryFocus};
    }
    &:active {
      border-color: ${props.theme.color.secondaryActive};
      background: ${props.theme.color.secondaryActive};
    }
    &:disabled,
    &:disabled:hover,
    &.Mui-disabled,
    &.Mui-disabled:hover {
      border-color: #c6c6c6;
      background: #c6c6c6;
      color: #8d8d8d;
    }
  `};

  ${(props) => props.variant === 'outlined' && props.color === 'primary' && css`
    border-color: ${props.theme.colorText.primary};
    color: ${props.theme.colorText.primary};

    &:hover {
      border-color: ${props.theme.colorText.primary};
      background: ${hexToRgbMixin(props.theme.colorText.primary, 0.1)};
    }

    &:disabled,
    &:disabled:hover,
    &.Mui-disabled,
    &.Mui-disabled:hover {
      border-color: #c6c6c6;
      color: #adadad;
    }
  `};

  ${(props) => props.variant === 'outlined' && props.color === 'secondary' && css`
    border-color: ${props.theme.color.secondary};
    color: ${props.theme.color.secondary};

    &:hover {
      border-color: ${props.theme.color.secondary};
      background: ${hexToRgbMixin(props.theme.color.secondary, 0.1)};
    }
    &:disabled,
    &:disabled:hover,
    &.Mui-disabled,
    &.Mui-disabled:hover {
      border-color: #c6c6c6;
      color: #adadad;
    }
  `};

  ${(props) => props.variant === 'outlined' && props.color === 'default' && css`
    border-color: #c6c6c6;
    color: ${props.theme.colorText.primary};

    &:hover {
      border-color: #c6c6c6;
      background: ${hexToRgbMixin('#9c9c9c', 0.1)};
    }
    &:disabled,
    &:disabled:hover,
    &.Mui-disabled,
    &.Mui-disabled:hover {
      border-color: #c6c6c6;
      color: #adadad;
    }
  `};

  .MuiButton-endIcon {
    padding-left: 30px;
  }

  ${(props) => props.size === 'small' && css`
    min-height: 32px;
    padding: 5px 12px;
  `};
  ${(props) => props.size === 'medium' && css`
    min-height: 40px;
    padding: 9px 16px;
  `};
  ${(props) => props.size === 'large' && css`
    min-height: 48px;
    padding: 13px 20px;
  `};
`;

export {
  StyledButton
};
