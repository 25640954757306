import React, { useState } from 'react';

import { Slider } from '@mui/material';

import {
  Wrapper
} from './RangeSlider.styles';

export interface RangeSliderProps {
  dateFilter: any;
}

const RangeSlider = (props: RangeSliderProps): JSX.Element => {
  const [value, setValue] = useState<number[]>([20, 37]);

  const valueText = (value: number) => `${value}°C`;

  const handleChange = (event: Event, newValue: number | number[]) => {
    setValue(newValue as number[]);
    props.dateFilter(newValue as number[]);
  };

  return (
    <Wrapper>
      <Slider
        getAriaLabel={() => 'Temperature range'}
        value={value}
        onChange={handleChange}
        getAriaValueText={valueText}
        size="small"
        min={-2}
        max={50}
      />
    </Wrapper>
  );
};

export default RangeSlider;
