import React, { useRef } from 'react';

import { ColDef, ColGroupDef } from 'ag-grid-enterprise';

import { ScenarioDetailsWithTagsStrings } from 'interfaces';

import Button from 'components/ui/Button';
import ManagerTable from 'components/ui/ManagerTable';
import ManagerTableActions from 'components/ui/ManagerTableActions';

export interface ScenarioManagerProps {
  scenarios: ScenarioDetailsWithTagsStrings[];
  setSelectedRow?: (data: any) => void;
  subscribeButton?: boolean;
  withSearch?: boolean;
  onSubscribe?: (id: number) => void;
  onDelete?: (id: number) => void;
  onEdit?: (id: number) => void;
  onView?: (id: number) => void;
}
const defaultProps = {
  subscribeButton: false,
  withSearch: false,
  onSubscribe: () => {},
  onDelete: () => {},
  onEdit: () => {},
  onView: () => {},
  setSelectedRow: () => {}
};

const ScenarioManager = (props: ScenarioManagerProps): JSX.Element => {
  const gridRef = useRef();

  const columnDefs: Array<ColGroupDef | ColDef> = [
    {
      field: 'name',
      headerName: 'Scenario name',
      minWidth: 250,
      maxWidth: 700,
      headerCheckboxSelection: true,
      checkboxSelection: true,
      rowDrag: true,
      flex: 1
    },
    {
      field: 'description',
      headerName: 'Description',
      minWidth: 150,
      maxWidth: 600,
      flex: 1
    },
    {
      field: 'author',
      headerName: 'Author',
      minWidth: 100,
      maxWidth: 150,
      flex: 1
    },
    {
      field: 'tags',
      headerName: 'Tags',
      minWidth: 100,
      maxWidth: 350,
      flex: 1
    }
  ];

  return (
    <ManagerTable
      gridRef={gridRef}
      rowData={props.scenarios}
      columnDefs={columnDefs}
      actionsComponent={
        !props.subscribeButton
          ? (actionsComponentProps: any) => ManagerTableActions({
            onDelete: props.onDelete,
            onEdit: props.onEdit,
            onView: props.onView,
            ...actionsComponentProps
          })
          : () => (
            <Button
              fullWidth
              onClick={(data: any) => props.onSubscribe?.(data.id)}
              size="medium"
            >
              Subscribe now
            </Button>
          )
      }
      setSelectedRowForPanel={props.setSelectedRow}
      onActionRemove={props.onDelete} // change to remove or subscribe
      withSearch={props.withSearch}
    />
  );
};

ScenarioManager.defaultProps = defaultProps;

export default ScenarioManager;
