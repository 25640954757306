import React, { useRef } from 'react';

import { ColDef, ColGroupDef } from 'ag-grid-enterprise';

import { PortfolioDetails } from 'interfaces';

import ManagerTable from 'components/ui/ManagerTable';
import ManagerTableActions from 'components/ui/ManagerTableActions';

export interface PortfolioManagerProps {
  portfolios: PortfolioDetails[];
  onDelete: (id: number) => void;
  onEdit: (id: number) => void;
  onView: (id: number) => void;
  setSelectedRow: (data: any) => void;
}

const PortfolioManager = (props: PortfolioManagerProps) => {
  const gridRef = useRef();

  const columnDefs: Array<ColGroupDef | ColDef> = [
    {
      field: 'name',
      headerName: 'Portfolio name',
      minWidth: 250,
      maxWidth: 1000,
      headerCheckboxSelection: true,
      checkboxSelection: true,
      rowDrag: true,
      flex: 1
    },
    {
      field: 'referenceDate',
      headerName: 'Date',
      minWidth: 120,
      maxWidth: 300,
      flex: 1
    },
    {
      field: 'description',
      headerName: 'Description',
      minWidth: 150,
      maxWidth: 1000,
      flex: 1
    },
    {
      field: 'currency',
      headerName: 'Currency',
      minWidth: 130,
      maxWidth: 300,
      flex: 1
    }
  ];

  return (
    <ManagerTable
      gridRef={gridRef}
      rowData={props.portfolios}
      columnDefs={columnDefs}
      actionsComponent={(actionsComponentProps: any) => ManagerTableActions({
        onDelete: props.onDelete,
        onEdit: props.onEdit,
        onView: props.onView,
        ...actionsComponentProps
      })}
      setSelectedRowForPanel={props.setSelectedRow}
      onActionRemove={props.onDelete}
    />
  );
};

export default React.memo(PortfolioManager);
