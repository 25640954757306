import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import { useAuth0 } from '@auth0/auth0-react';

import { appBaseUrl } from 'config/environment';

import AuthPage from 'auth/AuthPage';

import Typography from 'components/ui/Typography';
import Button from 'components/ui/Button';

const Logout = (): JSX.Element => {
  const history = useHistory();

  const redirectUrl = appBaseUrl;

  const { logout } = useAuth0();

  useMemo(async () => {
    logout({ logoutParams: { returnTo: redirectUrl } });
  }, [logout, redirectUrl]);

  return (
    <AuthPage
      title="Logout"
    >
      <Typography withMarginBottom>
        You have successfully logged out of your account.
      </Typography>
      <Button
        variant="outlined"
        color="primary"
        onClick={() => history.push('/')}
        fullWidth
      >
        Log in again
      </Button>
    </AuthPage>
  );
};

export default Logout;
