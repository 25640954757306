import React from 'react';

import {
  StyledBox
} from './TabPanel.styles';

export interface TabPanelProps {
  index: number;
  value: number;
  children?: React.ReactNode;
  noPadding?: boolean;
}
const defaultProps = {
  children: null,
  noPadding: false
};

const TabPanel = (props: TabPanelProps): JSX.Element => (
  <div
    role="tabpanel"
    hidden={props.value !== props.index}
    id={`tabpanel-${props.index}`}
    aria-labelledby={`tab-${props.index}`}
  >
    {
      props.value === props.index ? (
        <StyledBox noPadding={props.noPadding}>
          {props.children}
        </StyledBox>
      ) : null
    }
  </div>
);

TabPanel.defaultProps = defaultProps;

export default TabPanel;
