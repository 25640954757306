import styled from '@emotion/styled';
import { css } from '@emotion/react';

interface EditModeProps {
  editMode?: boolean | undefined;
}

const Wrapper = styled.div<EditModeProps>`
  border: 1px solid #e3e3e3;
  background: ${(props) => props.theme.colorMono.white};

  ${(props) => props.editMode && css`
    border-color: ${props.theme.color.secondary};
  `};
`;

const NameWrapper = styled.div<EditModeProps>`
  position: relative;
  height: 45px;
  padding: 0 16px;

  &::after {
    content: '';
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    border-bottom: 1px solid #e3e3e3;

    ${(props) => props.editMode && css`
      right: 16px;
      left: 16px;
    `};
  }
`;

const NameInner = styled.div<EditModeProps>`
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
  height: 100%;
  font-size: 14px;

  ${(props) => props.editMode && css`
    font-size: 18px;
    color: ${props.theme.color.secondary};
  `};
`;

const JobNameInput = styled.input<EditModeProps>`
  display: block;
  flex: 1 1 auto;
  min-width: cacl(100% - 40px);
  height: 41px;
  border: 0;
  font-size: 18px;
  color: ${(props) => props.theme.color.secondary};

  ${(props) => !props.editMode && css`
    font-size: 14px;
    pointer-events: none;
  `};
`;

const JobNameButton = styled.button`
  display: flex;
  align-items: center;
  min-width: 20px;
  color: ${(props) => props.theme.colorText.primary};
`;

const NameTextWrapper = styled.span`
  overflow: hidden;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const ElementsWrapper = styled.div<EditModeProps>`
  height: calc(255px - (2 * 5px));
  margin: 5px 0;
  padding: 0 3px 0 6px;

  ${(props) => props.editMode && css`
    height: calc(255px - (2 * 12px));
    margin: 12px 0;
    padding: 0 10px 0 16px;
  `};
`;

const DragAndDropText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: #9c9c9c;
`;

const DroppableInner = styled.div`
  width: 100%;
  height: 100%;
`;

export {
  Wrapper,
  NameWrapper,
  NameInner,
  JobNameInput,
  JobNameButton,
  NameTextWrapper,
  ElementsWrapper,
  DragAndDropText,
  DroppableInner
};
