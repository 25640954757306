import styled from '@emotion/styled';
import { css } from '@emotion/react';

interface ContentWrapperProps {
  contentWithBorder?: boolean | undefined;
  footerWithBorder?: boolean | undefined;
}
interface FooterWrapperProps {
  footerWithBorder?: boolean | undefined;
}

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  min-height: 90vh;
`;

const LogoWrapper = styled.div`
  width: 100%;
  text-align: center;
`;

const ContentWrapper = styled.div<ContentWrapperProps>`
  width: 100%;
  margin: 20px 0;

  ${(props) => props.contentWithBorder && css`
    border-top: 1px solid #c8c8c8;
  `};
`;

const ContentBottomWrapper = styled.div<FooterWrapperProps>`
  width: 100%;
  text-align: center;

  ${(props) => props.footerWithBorder && css`
    border-top: 1px solid #c8c8c8;
    padding-top: 20px;
  `};
`;

export {
  Wrapper,
  LogoWrapper,
  ContentWrapper,
  ContentBottomWrapper
};
