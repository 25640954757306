import React from 'react';

import { ScenarioDetails } from 'interfaces';
import { SCENARIO_TYPES_OPTIONS } from 'constant';

import { formatDate } from 'utils/formatDate';

import Chip from 'components/ui/Chip';
import ExternalLabel from 'components/ui/ExternalLabel';
import Icons from 'components/ui/Icons';
import Tile from 'components/ui/Tile';
import TileWrapper from 'components/ui/TileWrapper';
import Typography from 'components/ui/Typography';

import {
  UnSubscribeWrapper,
  IconWrapper,
  StyledButton,
  TagsWrapper
} from './ScenarioViewDetails.styles';

export interface ScenarioViewDetailsProps {
  scenarioDetails: ScenarioDetails;
  unSubscribeOnClick: () => void;
}

const ScenarioViewDetails = (props: ScenarioViewDetailsProps): JSX.Element => {
  const getScenarioType = (type: number | null) => SCENARIO_TYPES_OPTIONS
    .filter((el) => Number(el.value) === type)[0]?.label;

  return (
    <TileWrapper>
      <Tile size="medium">
        <UnSubscribeWrapper>
          <IconWrapper>
            <Icons iconType="logo" size={46} />
          </IconWrapper>
          <Typography size="big">Center for Economics</Typography>
          <StyledButton
            variant="outlined"
            size="small"
            disabled
            onClick={props.unSubscribeOnClick}
          >
            Unsubscribe
          </StyledButton>
        </UnSubscribeWrapper>
      </Tile>
      <Tile size="none">
        <ExternalLabel>Scenario Name</ExternalLabel>
        <Typography size="superBig">{props.scenarioDetails.name}</Typography>
      </Tile>
      <Tile size="medium" noStyle>
        <TileWrapper>
          <Tile>
            <ExternalLabel>Scenario Creation Date</ExternalLabel>
            <Typography color="secondary" size="big">
              {formatDate(props.scenarioDetails.creationDate)}
            </Typography>
          </Tile>
          <Tile>
            <ExternalLabel>Scenario Publish Date</ExternalLabel>
            <Typography color="secondary" size="big">
              {formatDate(props.scenarioDetails.publishDate)}
            </Typography>
          </Tile>
          <Tile size="none">
            <ExternalLabel>Scenario Type</ExternalLabel>
            <Typography color="secondary" size="big">
              {getScenarioType(props.scenarioDetails.scenarioType)}
            </Typography>
          </Tile>
        </TileWrapper>
      </Tile>
      <Tile size={props.scenarioDetails.tags?.length ? 'medium' : null}>
        <ExternalLabel>Scenario Description</ExternalLabel>
        <Typography size="medium">{props.scenarioDetails.description}</Typography>
      </Tile>
      {
        props.scenarioDetails.tags?.length ? (
          <Tile>
            <ExternalLabel>Scenario Tags</ExternalLabel>
            <TagsWrapper>
              {
                props.scenarioDetails.tags.map((tag) => (
                  <Chip
                    key={tag}
                    label={tag}
                    clickable={false}
                  />
                ))
              }
            </TagsWrapper>
          </Tile>
        ) : null
      }
    </TileWrapper>
  );
};

export default ScenarioViewDetails;
