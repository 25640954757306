import styled from '@emotion/styled';
import { css } from '@emotion/react';

type WrapperProps = {
  withBorder: boolean;
  withPadding: boolean;
};

const Wrapper = styled.h2<WrapperProps>`
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
  font-size: 18px;
  line-height: 1.35;

  ${
  (props) => props.withBorder && css`
      margin-bottom: 25px;
      padding-bottom: 10px;
      border-bottom: 1px solid ${props.theme.layout.borderColor};

      ${!props.withPadding && css`
        padding-bottom: 0;
      `};
  `};
`;

const AdditionalTextWrapper = styled.span`
  display: inline-block;
  margin-left: 12px;
  font-size: 11px;
  color: #858585;
  vertical-align: middle;
`;

const AdditionalComponentWrapper = styled.div`
  margin-left: 12px;
`;

export {
  Wrapper,
  AdditionalTextWrapper,
  AdditionalComponentWrapper
};
