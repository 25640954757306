import React from 'react';

import { AssetForForm } from 'interfaces';

import SimpleTable from 'components/ui/SimpleTable';
import {
  AssetsWrapper
} from './PortfolioViewAssetsTable.styles';

export interface PortfolioViewAssetsTableProps {
  holdings: AssetForForm[];
}

const PortfolioViewAssetsTable = (props: PortfolioViewAssetsTableProps): JSX.Element => {
  const tableColumnDefs = [
    {
      field: 'description',
      headerName: 'Asset name'
    },
    {
      field: 'priceCurrency',
      headerName: 'Currency',
      maxWidth: 160
    },
    {
      field: 'quantity',
      headerName: 'Weight (%)',
      maxWidth: 160,
      comparator: (a: AssetForForm, b: AssetForForm) => {
        if (Number(a) === Number(b)) return 0;
        return Number(a) < Number(b) ? -1 : 1;
      }
    }
  ];

  return (
    <AssetsWrapper>
      {
        props.holdings.length ? (
          <SimpleTable
            tableColumnDefs={tableColumnDefs}
            tableData={props.holdings}
            colorPrimary={false}
          />
        ) : 'No holdings'
      }
    </AssetsWrapper>
  );
};

export default PortfolioViewAssetsTable;
