import { Asset, AssetsFilters } from 'interfaces';
import { FILTERS_NAMES } from 'constant';

import { findUnique } from 'utils/findUnique';

export const getAssetsDictionariesFilters = (assets: Asset[]): AssetsFilters => ({
  [FILTERS_NAMES.name]: assets,
  [FILTERS_NAMES.assetClass]: findUnique(assets, FILTERS_NAMES.assetClass),
  [FILTERS_NAMES.region]: findUnique(assets, FILTERS_NAMES.region),
  [FILTERS_NAMES.priceCurrency]: findUnique(assets, FILTERS_NAMES.priceCurrency)
});
