import styled from '@emotion/styled';

import { PAGE_BUTTONS_WRAPPER_HEIGHT } from 'constant';

import Autocomplete from 'components/ui/Autocomplete';
import Box from 'components/ui/Box';
import TextField from 'components/ui/TextField';
import Select from 'components/ui/Select';

const StyledBox = styled(Box)`
  position: fixed;
  right: 287px;
  bottom: calc(${PAGE_BUTTONS_WRAPPER_HEIGHT}px + 20px);
  z-index: 4;
  width: 320px;
  border: 0;
  box-shadow: 0 5px 7px -5px rgba(0 0 0 / .2);
`;

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: right;
  gap: 10px;
  margin-top: 20px;
`;

const BgLayer = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 3;
  background-color: rgba(0 0 0 / .2);
`;

// @ts-ignore
const StyledTextField = styled(TextField)`
  && {
    margin-bottom: 10px;
    .MuiTextField-root {
      min-width: 125px;
      max-width: 125px;
    }
  }
`;

// @ts-ignore
const StyledSelect = styled(Select)`
  && {
    margin-bottom: 10px;
    .MuiTextField-root {
      min-width: 125px;
      max-width: 125px;
    }
  }
`;

// @ts-ignore
const StyledAutocomplete = styled(Autocomplete)`
  && {
    margin-bottom: 10px;
    .MuiTextField-root {
      min-width: 125px;
      max-width: 125px;
    }
  }
`;

export {
  StyledBox,
  ButtonsWrapper,
  BgLayer,
  StyledTextField,
  StyledSelect,
  StyledAutocomplete
};
