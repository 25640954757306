import * as yup from 'yup';
import { defaultMessages } from 'constant';

export const jobSettingsValidationSchema = yup.object({
  numberOfSamples: yup.string().required(defaultMessages.required).nullable(),
  modelHorizon: yup.string().required(defaultMessages.required).nullable(),
  samplingInterval: yup.object({
    label: yup.string(),
    value: yup.string()
  }).required(defaultMessages.required).nullable(),
  forecastHorizon: yup.object({
    label: yup.string(),
    value: yup.string()
  }).required(defaultMessages.required).nullable(),
  reportingCurrency: yup.string().required(defaultMessages.required).nullable()
});
