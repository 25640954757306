import React, { Suspense } from 'react';
import { Provider } from 'react-redux';

import { StylesProvider, ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import { ThemeProvider as NewMuiThemeProvider } from '@mui/material';
import { Global, ThemeProvider } from '@emotion/react';

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import { LicenseManager } from 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

import 'swiper/swiper.min.css';

import AuthWithHistoryProvider from 'auth/AuthWithHistoryProvider';
import AxiosWithAuthProvider from 'auth/AxiosWithAuthProvider';

import { theme, muiTheme, newMuiTheme } from 'styles/theme';
import { GlobalStyles } from 'styles/global.styles';
import { IconsGlobalStyles } from 'components/ui/Icons/Icons.styles';

import Loader from 'components/ui/Loader';

import store from './features/store';

import RoutesConfig from './routes';

LicenseManager.setLicenseKey(process.env.REACT_APP_AG_GRID_LICENSE_KEY as string);

const App = (): JSX.Element => (
  // @TODO: have only one mui theme - newer version of material ui
  <NewMuiThemeProvider theme={newMuiTheme}>
    <MuiThemeProvider theme={muiTheme}>
      <StylesProvider injectFirst>
        <ThemeProvider theme={theme}>
          <Suspense fallback={<Loader />}>
            <AuthWithHistoryProvider>
              <AxiosWithAuthProvider />
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Provider store={store}>
                  <Global styles={GlobalStyles} />
                  <Global styles={IconsGlobalStyles} />
                  <RoutesConfig />
                </Provider>
              </LocalizationProvider>
            </AuthWithHistoryProvider>
          </Suspense>
        </ThemeProvider>
      </StylesProvider>
    </MuiThemeProvider>
  </NewMuiThemeProvider>
);

export default App;
