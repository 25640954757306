export const hasRecord = (data: any = []): boolean => Boolean(data[0]);

export const filterMapRecords = (
  fieldValue = '',
  data: any = [],
  searchValue = '',
  searchType = 'filter',
  inputType = 'string'
): any[] => {
  const lowercaseFilter = (inputType === 'int') ? parseInt(fieldValue, 10) : fieldValue.toLowerCase();
  let filteredData;

  if (searchType === 'exact') {
    filteredData = data?.filter((item: any) => {
      const term = (inputType === 'int') ? item?.[searchValue] : item?.[searchValue]?.toLowerCase();
      return term === lowercaseFilter;
    });
  } else {
    filteredData = data?.filter((item: any) => {
      const term = (inputType === 'int') ? item?.[searchValue] : item?.[searchValue]?.toLowerCase();
      return term.indexOf(lowercaseFilter) !== -1;
    });
  }

  return filteredData;
};

export const replaceSpaces = (value: string): string => value?.replace(/\s+/g, '-');

export const trimCodeByLength = (value: string, digits = 4): string => value.substring(0, digits);

export const randomCodeByLength = (value: string, digits = 4, code = 'RAND'): string => {
  const newValue = replaceSpaces(value);
  return (code + trimCodeByLength(newValue, digits)).toUpperCase();
};
