import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { AgGridReact } from 'ag-grid-react';
import { ColDef, ColGroupDef, GridOptions } from 'ag-grid-enterprise';

import TextField from 'components/ui/TextField';
import ManagerTableCustomHeader from 'components/ui/ManagerTableCustomHeader';
import ManagerTableHeaderButtons from 'components/ui/ManagerTableHeaderButtons';

import {
  TableWrapper,
  SelectedRowsInfo,
  SearchWrapper,
  StyledSearchIcons,
  SelectedRowsButtonsList,
  SelectedRowsButtonsItem
} from './ManagerTable.styles';

export interface ManagerTableProps {
  gridRef: any;
  rowData: any;
  columnDefs: any;
  withActions?: boolean;
  withSearch?: boolean;
  showHeaderButtons?: boolean;
  actionsComponent?: React.ReactNode;
  setSelectedRowForPanel?: (data: any) => void;
  onActionRemove?: (id: any) => void;
  defaultColDef?: any | null;
  additionalValueToHeightSubtraction?: number | null;
  onGridReady?: (params: any) => void | null;
}
const defaultProps = {
  withActions: true,
  withSearch: false,
  showHeaderButtons: true,
  actionsComponent: null,
  defaultColDef: null,
  additionalValueToHeightSubtraction: null,
  onGridReady: null
};

const ManagerTable = (props: ManagerTableProps): JSX.Element => {
  const [searchValue, setSearchValue] = useState('');
  const [selectedRow, setSelectedRow] = useState(
    props.gridRef?.current?.api?.getSelectedRows() || []
  );
  const [fullColumnDefs, setFullColumnDefs] = useState(
    props.gridRef?.current?.api?.getColumnDefs() || []
  );

  const defaultColDef = useMemo<ColDef>(() => ({
    resizable: true,
    editable: false,
    sortable: true,
    flex: 1,
    minWidth: 150,
    defaultWidth: 480,
    suppressMovable: false,
    enableRowGroup: true,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    checkbox: true,
    menuTabs: ['generalMenuTab'],
    cellRenderer: (params: any) => (
      <span className="ag-cell-value" title={params?.value}>
        {params?.value}
      </span>
    )
  }), []);

  const columnDefs: Array<ColGroupDef | ColDef> = [
    ...props.columnDefs,
    {
      field: 'actions',
      headerName: 'Actions',
      minWidth: 155,
      maxWidth: 155,
      cellRenderer: props.actionsComponent,
      suppressMenu: true,
      cellClass: 'action-cell',
      headerClass: 'action-cell',
      editable: false,
      filter: null
    }
  ];

  const gridOptions: GridOptions = {
    rowHeight: 64,
    animateRows: true,
    rowGroupPanelShow: 'always'
  };

  useEffect(() => {
    if (props.gridRef && props.gridRef.current && props.gridRef.current.api) {
      setFullColumnDefs(props.gridRef.current.api.getColumnDefs());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.gridRef, props.gridRef?.current, props.gridRef?.current?.api]);

  const handleSelectedRow = () => {
    setSelectedRow(props.gridRef?.current?.api?.getSelectedRows());
  };

  const rowGroupChangeDetector = () => {
    props.gridRef.current.api.sizeColumnsToFit();
  };

  const components = useMemo(() => ({
    agColumnHeader: ManagerTableCustomHeader
  }), []);

  const onRemove = () => {
    const selectedRowData = props.gridRef.current.api.getSelectedRows();

    selectedRowData.forEach((el: any) => props.onActionRemove?.(el.id));
  };
  const onCancel = () => {
    props.gridRef.current.api.deselectAll();
  };

  const onFilterTextBoxChanged = useCallback(() => {
    props.gridRef.current.api.setQuickFilter(
      (document.getElementById('filter-text-box') as HTMLInputElement)?.value
    );
  }, [props.gridRef]);

  return (
    <TableWrapper
      className="ag-theme-alpine"
      withSearch={props.withSearch}
      selectedRow={!!selectedRow && selectedRow.length > 0}
      additionalValueToHeightSubtraction={props.additionalValueToHeightSubtraction || 0}
    >
      {
        props.withSearch ? (
          <SearchWrapper>
            <TextField
              withMarginBottom={false}
              size="small"
              id="filter-text-box"
              name="filter-text-box"
              placeholder="Search for Scenarios"
              InputProps={{
                startAdornment: (<StyledSearchIcons iconType="search" size={20} />)
              }}
              value={searchValue}
              onChange={(event) => {
                setSearchValue(event.target.value);
                onFilterTextBoxChanged();
              }}
            />
          </SearchWrapper>
        ) : null
      }
      {
        props.showHeaderButtons && !props.withSearch ? (
          <ManagerTableHeaderButtons
            fullColumnDefs={fullColumnDefs}
            setFullColumnDefs={setFullColumnDefs}
            gridRef={props.gridRef}
          />
        ) : null
      }
      {
        selectedRow?.length > 0 ? (
          <SelectedRowsInfo>
            <div>
              {selectedRow?.length} item{selectedRow?.length > 1 && 's'} selected
            </div>
            <SelectedRowsButtonsList>
              <SelectedRowsButtonsItem>
                <button
                  type="button"
                  onClick={onRemove}
                >
                  Remove
                </button>
              </SelectedRowsButtonsItem>
              <SelectedRowsButtonsItem>
                <button
                  type="button"
                  onClick={onCancel}
                >
                  Cancel
                </button>
              </SelectedRowsButtonsItem>
            </SelectedRowsButtonsList>
          </SelectedRowsInfo>
        ) : null
      }
      <AgGridReact
        ref={props.gridRef}
        rowSelection="multiple"
        suppressRowClickSelection
        animateRows
        rowData={props.rowData}
        defaultColDef={props.defaultColDef || defaultColDef}
        columnDefs={props.withActions && props.actionsComponent ? columnDefs : props.columnDefs}
        onRowSelected={handleSelectedRow}
        onCellClicked={({ data }) => props.setSelectedRowForPanel?.(data)}
        rowDragEntireRow
        rowDragManaged
        onColumnRowGroupChanged={rowGroupChangeDetector}
        onDisplayedColumnsChanged={rowGroupChangeDetector}
        onGridReady={props.onGridReady}
        components={components}
        overlayNoRowsTemplate="<span> </span>"
        gridOptions={gridOptions}
        loadingCellRenderer="...Loading"
      />
    </TableWrapper>
  );
};

ManagerTable.defaultProps = defaultProps;

export default ManagerTable;
