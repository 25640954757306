import api from 'config/api';

export const fetchCalcSettings = () => (
  api.get('/api/calcsettings')
);

export const fetchCalcSetting = (calcId: number) => (
  api.get(`/api/calcsettings/${calcId}`)
);

export const sendCalcSetting = (data: any) => (
  api.post('/api/calcsettings/', data)
);

export const removeCalcSetting = (id: number | null) => (
  api.delete(`/api/calcsettings/${id}`)
);
