import React from 'react';
import styled from '@emotion/styled';

import { Tab } from '@material-ui/core';

import { hexToRgbMixin } from 'styles/mixins';

const StyledTab = styled(({
  ...props
}) => (
  <Tab {...props} />
))`
  min-width: 160px;
  padding-right: 16px;
  padding-left: 16px;
  border: 1px solid ${(props) => props.theme.layout.borderColor};
  border-bottom: 0;
  background: ${(props) => hexToRgbMixin(props.theme.colorMono.white, 0.1)};
  color: ${(props) => props.theme.color.secondary};
  text-transform: none;
  opacity: 1;

  & .MuiTab-wrapper {
    align-items: flex-start;
    font-weight: 400;
  }
  &.Mui-selected {
    background: ${(props) => props.theme.colorMono.white};
    color: ${(props) => props.theme.colorText.primary};
    .MuiTab-wrapper {
      font-weight: 600;
    }
  }
  &.Mui-disabled {
    background: ${(props) => hexToRgbMixin(props.theme.layout.borderColor, 0.1)};
    opacity: 1;
  }
  & + & {
    margin-left: -1px;
  }
`;

export {
  StyledTab
};
