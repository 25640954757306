import React from 'react';

import { DatePicker as MuiDatePicker, DesktopDatePickerProps } from '@mui/x-date-pickers';

import {
  GENERAL_DATE_PICKER_OPEN_TO,
  MONTH_YEAR_DATE_FORMAT,
  MONTH_YEAR_DATE_PICKER_VIEWS
} from 'constant';

import { isValidDate } from 'utils/isValidDate';

import { ITextFieldProps } from 'components/ui/TextField';
import Icons from 'components/ui/Icons';

import {
  StyledTextField
} from './DatePicker.styles';

export interface DatePickerProps extends Omit<ITextFieldProps, 'onChange'> {
  inputFormat?: any;
  disableFuture?: boolean;
  disablePast?: boolean;
  openTo?: any;
  views?: any;
  setFieldTouched?: (field: string, isTouched?: boolean | undefined, shouldValidate?: boolean | undefined) => void;
  onChange?: any;
}
const defaultProps = {
  inputFormat: MONTH_YEAR_DATE_FORMAT,
  disableFuture: false,
  disablePast: false,
  openTo: GENERAL_DATE_PICKER_OPEN_TO.year,
  views: MONTH_YEAR_DATE_PICKER_VIEWS
};

const DatePicker = (props: DatePickerProps): JSX.Element => {
  const getDate = () => {
    if (
      !isValidDate(props.value)
      || (new Date(props.value as string).getTime() <= new Date('1000-01-01').getTime())
    ) {
      return props.value;
    }

    const date = new Date(props.value as any);
    const value = props.inputFormat === MONTH_YEAR_DATE_FORMAT
      ? new Date(date?.getFullYear(), date?.getMonth() + 1, 0)
      : date;

    return value;
  };

  const datePickerProps: DesktopDatePickerProps<any, any> = {
    showDaysOutsideCurrentMonth: false,
    inputFormat: props.inputFormat,
    openTo: props.openTo,
    views: props.views,
    disabled: props.disabled,
    disableFuture: props.disableFuture,
    disablePast: props.disablePast,
    disableHighlightToday: true,
    value: props.value ? getDate() : null,
    components: {
      OpenPickerIcon: (iconProps) => <Icons iconType="calendar" size={16} {...iconProps} />,
      LeftArrowIcon: (iconProps) => <Icons iconType="chevron-left-light" size={12} {...iconProps} />,
      RightArrowIcon: (iconProps) => <Icons iconType="chevron-right-light" size={12} {...iconProps} />,
      SwitchViewIcon: (iconProps) => <Icons iconType="chevron-down" size={7} {...iconProps} />
    },
    onChange: (date: any) => {
      props.setFieldTouched?.(props.name as string);
      props.onChange?.(date);
    },
    renderInput: (inputProps: any) => (
      <StyledTextField
        {...inputProps}
        inputProps={{
          ...inputProps.inputProps
        }}
        label={props.label}
        labelInfo={props.labelInfo}
        withExternalLabel={props.withExternalLabel}
        withMarginBottom={props.withMarginBottom}
        id={props.id}
        name={props.name}
        error={props.error}
        required={props.required}
        placeholder={props.placeholder}
        disabled={props.disabled}
        helperText={props.helperText}
        onBlur={props.onBlur}
        ref={inputProps.ref}
      />
    )
  };

  return (
    <MuiDatePicker
      {...datePickerProps}
    />
  );
};

DatePicker.defaultProps = defaultProps;

export default DatePicker;
