import React from 'react';

import { ModalProps } from '@material-ui/core';

import Icons from 'components/ui/Icons';

import {
  StyledModal,
  Wrapper,
  Header,
  Title,
  Content,
  Footer,
  CloseButton
} from './Modal.styles';

export interface IModalProps extends ModalProps {
  children: any;
  title: string;
  footerElement?: React.ReactNode;
  onClose?: () => any
}
const defaultProps = {
  footerElement: null,
  onClose: () => {}
};

const Modal = (props: IModalProps): JSX.Element => (
  <StyledModal
    disableScrollLock
    {...props}
  >
    <Wrapper className="modal-wrapper">
      <Header className="modal-header">
        <Title className="modal-title">{props.title}</Title>
        <CloseButton className="modal-close-button" onClick={props.onClose}>
          <Icons iconType="delete" size={10} />
        </CloseButton>
      </Header>
      <Content className="modal-content">
        {props.children}
      </Content>
      <Footer className="modal-footer">
        {props.footerElement}
      </Footer>
    </Wrapper>
  </StyledModal>
);

Modal.defaultProps = defaultProps;

export default Modal;
