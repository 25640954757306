import React, {
  useState, useEffect, useCallback, SyntheticEvent
} from 'react';
import _orderBy from 'lodash/orderBy';

import {
  AutocompleteProps,
  AutocompleteChangeReason,
  AutocompleteChangeDetails,
  AutocompleteRenderInputParams
} from '@mui/material';

import TextField, { ITextFieldProps } from 'components/ui/TextField';
import Icons from 'components/ui/Icons';

import {
  Wrapper,
  StyledAutocomplete
} from './Autocomplete.styles';

export interface Option {
  label: string | null;
  value: string | null;
}

export interface IAutocompleteProps extends Omit<AutocompleteProps<any, any, any, any>, 'renderInput'> {
  id: string;
  name: string;
  options: Option[];
  handleChange: (selectedOption: Option | null) => void;
  className?: string;
  label?: string;
  optionsSorted?: boolean;
  remove?: boolean;
}
const defaultProps = {
  className: '',
  label: null,
  optionsSorted: true,
  remove: false
};

const Autocomplete = (props: IAutocompleteProps & ITextFieldProps): JSX.Element => {
  const getOptions = useCallback(() => {
    if (props.optionsSorted) {
      return _orderBy(props.options, ['label']);
    }

    return props.options;
  }, [props.optionsSorted, props.options]);

  const setLabelFromValue = useCallback((value: string | null) => {
    const selectedValue = props.options.filter((el) => el.value === value)[0];
    return selectedValue?.label || value;
  }, [props.options]);

  const [inputValue, setInputValue] = useState('');
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState<string | null>(setLabelFromValue(props.value));
  const [options, setOptions] = useState<Option[]>(getOptions());

  useEffect(() => {
    setValue(setLabelFromValue(props.value));
  }, [setLabelFromValue, props.value]);

  useEffect(() => {
    setOptions(getOptions());
  }, [props.options, getOptions]);

  useEffect(() => {
    if (props.remove) {
      setValue(null);
    }
  }, [props.remove]);

  const clearValue = (event: any) => {
    event.stopPropagation();

    props.handleChange({ value: null, label: null });
    setValue(null);
  };

  return (
    <Wrapper>
      <StyledAutocomplete
        open={open}
        onOpen={(event: any) => {
          event.preventDefault();
          setOpen(!open);
        }}
        onClose={(event: any) => {
          event.preventDefault();
          setOpen(false);
        }}
        clearIcon={(
          <Icons iconType="delete" size={10} />
        )}
        inputValue={inputValue}
        value={value}
        onInputChange={(event, value, reason) => {
          setInputValue(value);

          if (!value) {
            setOpen(false);
          }
          if (reason === 'clear') {
            clearValue(event);
          }
        }}
        id={props.id}
        size={props.size}
        disablePortal
        options={options}
        isOptionEqualToValue={(option: any, value: any) => option?.id === value?.id}
        onChange={(event: SyntheticEvent<Element, Event>, value: any, reason: AutocompleteChangeReason, details?: AutocompleteChangeDetails<unknown> | undefined): void => {
          setValue(value?.label);

          if (value !== null) {
            props.handleChange(value as Option);
          } else {
            props.handleChange({ value: null, label: null });
          }
        }}
        popupIcon={(
          <Icons iconType="chevron-down" size={7} />
        )}
        renderInput={(params: AutocompleteRenderInputParams) => (
          <TextField
            {...params}
            className={props.className}
            label={props.label}
            labelVariant={props.labelVariant}
            labelPosition={props.labelPosition}
            labelInfo={props.labelInfo}
            required={props.required}
            helperText={props.helperText}
            error={props.error}
            withExternalLabel={props.withExternalLabel}
            withMarginBottom={props.withMarginBottom}
            size={props.size}
            value={inputValue || ''}
            placeholder={props.placeholder}
            InputProps={{
              ...params.InputProps,
              endAdornment: params.InputProps.endAdornment
            }}
            onBlur={props.onBlur}
          />
        )}
      />
    </Wrapper>
  );
};

Autocomplete.defaultProps = defaultProps;

export default Autocomplete;
