import React from 'react';

import { ModalProps } from '@material-ui/core';
import { IModalProps } from 'components/ui/Modal';

import Button from 'components/ui/Button';

import {
  StyledModal,
  StyledButton
} from './ModalConfirm.styles';

export interface ModalConfirmProps extends ModalProps {
  children: any;
  onConfirm: () => void;
  onCancel: () => void;
}

const ModalConfirm = (props: ModalConfirmProps & IModalProps): JSX.Element => (
  <StyledModal
    {...props}
    footerElement={(
      <React.Fragment>
        <StyledButton
          size="medium"
          variant="outlined"
          color="default"
          onClick={() => props.onCancel()}
        >
          Cancel
        </StyledButton>
        <Button
          size="medium"
          type="submit"
          color="primary"
          onClick={() => props.onConfirm()}
        >
          Confirm
        </Button>
      </React.Fragment>
    )}
  >
    {props.children}
  </StyledModal>
);

export default ModalConfirm;
