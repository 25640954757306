import styled from '@emotion/styled';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const ActionButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border: 1px solid #d7d7d7;
  border-radius: 50%;
  background: ${(props) => props.theme.colorMono.white};
`;

export {
  Wrapper,
  ActionButton
};
